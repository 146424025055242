import React, { useState, useEffect } from 'react';

import { Button, Card, Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap';

import { IMaskInput } from 'react-imask';

import HTTP from '../../api/HTTP';
import useNavigate from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

async function getMobilePhoneNumber(email) {
  try {
    const response = await HTTP.get(`/api/client/mobile-phone/${email}`);
    const data = await response.data;
    return data.mobilePhone;
  } catch (error) {
    console.log('getMobilePhoneNumber error', error);
  }
}

async function sendMessageToNumber(toNumber, message) {
  try {
    const response = await HTTP.post(
      process.env.TWILIO_URL + '/sendMessageToNumber',
      JSON.stringify({
        toNumber: toNumber,
        message: message,
      })
    );
    const data = await response.data;
    console.log(data);
  } catch (error) {}
}

export default function VerifyMobile(props) {
  const { nextSignupStep, signupCompletion } = props;
  const { signOut, user } = useAuth();
  const [expectedEMailCode, setExpectedEMailCode] = useState('');
  const [enteredEMailCode, setEnteredEMailCode] = useState('');
  const [displayCode, setDisplayCode] = useState('');
  const [showCodeToast, setShowCodeToast] = useState(false);
  const [showInvalidCodeToast, setShowInvalidCodeToast] = useState(false);
  const [codeLength, setCodeLength] = useState(false);

  function logoutHandler() {
    signOut();
  }

  const initiateEMailVerification = async () => {
    setShowCodeToast(false);
    setShowInvalidCodeToast(false);
    console.log('Requested SMS Code');
    let ecode = '';
    let displayCode = '';

    for (let i = 0; i < 6; i++) {
      let anumber = '';
      anumber = '' + Math.floor(Math.random() * 10);
      ecode = ecode + anumber;
      if (i > 0) {
        displayCode = displayCode + '-' + anumber;
      } else {
        displayCode = displayCode + anumber;
      }
    }
    console.log(ecode);
    console.log(displayCode);
    setExpectedEMailCode(ecode);
    setDisplayCode(displayCode);
    setShowCodeToast(true);

    // Send SMS
    // const mobilePhone = await getMobilePhoneNumber(user.email)
    // console.log(mobilePhone)
    // await sendMessageToNumber(mobilePhone, 'Code: ' + displayCode)
  };

  const validateEnteredCode = async () => {
    if (enteredEMailCode.length === 6 && enteredEMailCode === expectedEMailCode) {
      console.log('SMS Code identical');
      setShowInvalidCodeToast(false);

      const clientData = {
        signupstage: 40,
        signupid: user.signupid,
        phone: true,
      };

      const updated = await signupCompletion(clientData);
      if (updated.updated) {
        nextSignupStep(updated.signupstage); // 40
      }
    } else {
      console.log('E-Mail Code different');
      setShowInvalidCodeToast(true);
    }
  };

  useEffect(() => {
    console.log('E-Mail Verification');
  }, []);

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={showCodeToast} bg='warning'>
          <Toast.Body>Valid SMS Code: {displayCode}</Toast.Body>
        </Toast>
      </ToastContainer>
      <ToastContainer className='p-3' position={'bottom-end'}>
        <Toast show={showInvalidCodeToast} bg='danger'>
          <Toast.Body>Invalid Code</Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Mobile Verification</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Row className='g-2 mt-0 mb-3'>
          <Col md>
            {/* <h3 className="mb-4">Login</h3> */}
            <p className='text-muted'>
              Clicking on the button Initiate Mobile Number verification will send a textmessage with a code to your
              Mobile Phone. This code will be valid for 5 minutes and has to be entered into the field below
            </p>
          </Col>
        </Row>

        <Form id='verifyForm'>
          <Row className='g-2'>
            <Col md={3}>
              <Button variant='outline-primary' size='lg' onClick={initiateEMailVerification}>
                Initiate Mobile Number Verification
              </Button>
            </Col>
          </Row>
          <Row className='g-2 mt-3'>
            <Col md={5}>
              {' '}
              <Form.Label className='text-muted' htmlFor='recipient'>
                Please enter the received Code below
              </Form.Label>{' '}
            </Col>
          </Row>
          <Row className='g-2 mt-1'>
            <Col md={2}>
              <div className='form-floating mb-3'>
                <IMaskInput
                  mask={'0-0-0-0-0-0'}
                  id='emailCode'
                  name='emailCode'
                  type='emailCode'
                  placeholder='E-Mail Code'
                  unmask={true}
                  onAccept={(value) => {
                    setEnteredEMailCode(value);
                    setShowInvalidCodeToast(false);
                    if (value.length === 6) {
                      setCodeLength(true);
                    } else {
                      setCodeLength(false);
                    }
                  }}
                  className='form-control'
                />
                <Form.Label htmlFor='emailCode'>SMS Code</Form.Label>
              </div>
            </Col>
          </Row>

          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button
                  variant='primary'
                  size='lg'
                  // type="submit"
                  disabled={!codeLength}
                  onClick={validateEnteredCode}
                >
                  Continue
                </Button>{' '}
                <Button
                  variant='outline-primary'
                  size='lg'
                  // type="submit"
                  // disabled={isSubmitting}
                  onClick={logoutHandler}
                >
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </Form>
      </Card.Body>
    </Card>
  );
}
