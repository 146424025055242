import { Col, Container, Row } from "react-bootstrap";
import CustomButton from "../../components/ui/CustomButton";

const BankingSection = (props) => {
  return (
    <div
      {...props}
      className="banking-section"
    >
      <Container>
        <div
          className="main-text light"
        >
          Seamless Banking Experience
        </div>
        <div
          className="secondary-text light"
          style={{
            marginTop: '40px'
          }}
        >
          Experience Cutting-edge Digital Banking.
        </div>

        <Row className="banking-section-info">
          <Col xs={12} xl={6}>
            <Row className="gx-3 gy-3" >
              <Col xs={12}>
                <div
                  className="banking-section-card banking-section-main-card"
                  style={{
                    background: 'linear-gradient(270deg, #0CBAFF 0%, #0111FF 100%)'
                  }}
                >
                  <img
                    src="/img/phone.png"
                  />
                  <h4 className="main-text">
                    Dedicated IBANs
                  </h4>
                  <p className="secondary-text">
                    Set up and utilize your dedicated IBAN within seconds for ultra fast global transactions.
                  </p>
                  <CustomButton
                    variant="main-dark"
                    size="normal"
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    Join Now
                  </CustomButton>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div
                  className="banking-section-card banking-section-secondary-card"
                  style={{
                    background: 'linear-gradient(157.54deg, #FFFFFF 14.62%, #DBDBDB 103.28%)',
                  }}
                >
                  <div className="separator"></div>
                  <div>
                    <h4 className="main-text">
                      Virtual and Physical Debit Cards
                    </h4>
                    <p className="secondary-text">
                      Convert and utilize all your funds and spend them via your dedicated debit card.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div
                  className="banking-section-card banking-section-secondary-card"
                  style={{
                    background: 'linear-gradient(152.47deg, #1DD95E 17.13%, #0A3F1D 114.9%)',
                  }}
                >
                  <div className="separator"></div>
                  <div>
                    <h4 className="main-text">
                      Extensive Analytics
                    </h4>
                    <p className="secondary-text">
                      Analyze and manage your spending instantly with our sophisticated, real-time analytics capabilities.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={6}>
            <div className="d-flex">
              <div className="features-block mx-auto">
                <h3 className="features-title">
                  Uncompromised Features
                </h3>
                <p className="features-subtitle">
                  Capitavio brings to you a dedicated bank account and exclusive debit cards, ensuring secure, seamless transactions in a futuristic digital environment.
                </p>
                <div className="features-list">
                  <div className="features-item">
                    <div className="list-dot"></div>
                    <p className="list-text">
                      Access funds anytime, anywhere with our easy-to-use online and mobile banking services!
                    </p>
                  </div>
                  <div className="features-item">
                    <div className="list-dot"></div>
                    <p className="list-text">
                      Withdraw cash at our partner network of world wide ATM maschines
                    </p>
                  </div>
                  <div className="features-item">
                    <div className="list-dot"></div>
                    <p className="list-text">
                      Experience 24/7 customer service for any questions or concerns!
                    </p>
                  </div>
                  <div className="features-item">
                    <div className="list-dot"></div>
                    <p className="list-text">
                      Direct Deposit: Enjoy the convenience of having your paycheck, government benefits, or other regular income directly deposited into your account."
                    </p>
                  </div>
                  <div className="features-item">
                    <div className="list-dot"></div>
                    <p className="list-text">
                      Seamless Money Transfers: Instantly send and receive money with a few taps, simplifying your personal and business transactions.
                    </p>
                  </div>
                  <img src="/img/features-line.svg" />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div
          className="main-text light"
          style={{
            marginTop: '100px',
          }}
        >
          We offer you Debit Cards
        </div>
        <div className="banking-cards">
          <img src="/img/cards.png" />
        </div>
        <div
          className="secondary-text light"
          style={{
            marginTop: '40px'
          }}
        >
          With our service offering both physical and virtual debit cards, effortlessly convert any asset into spendable currency for global transactions in seconds. Experience seamless banking, providing unparalleled convenience and speed for your financial needs.
        </div>
        <div className="d-flex justify-content-center">
          <CustomButton
            variant="main-dark"
            size="normal"
            style={{
              marginTop: '40px'
            }}
          >
            Join Wishlist
          </CustomButton>
        </div>
      </Container>
    </div>
  );
}

export default BankingSection;