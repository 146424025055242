import React, { useEffect, useState } from 'react';
import ContactInquirytList from './ContactInquirytList';
import { Card, Col, Row } from 'react-bootstrap';

const ContactInquiries = (props) => {
  const { clientId } = props;
  const [requestId, setRequestId] = useState(null);
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    {
      /* 
    console.log(2)
    const fetchDocumentRequests = async () => {
      const response = await fetch(`/api/inquiry/dash/${clientId}`)
      const data = await response.json()
      setRequestList(data)
    }
    fetchDocumentRequests()
    */
    }
  }, [clientId]);

  const setSelectedRequestId = (anRequestid) => {
    setRequestId(anRequestid);
  };

  if (requestList && requestList.length > 0) {
    return (
      <section className='mb-3 mb-lg-5'>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                      <div className='fw-bold'>Documents</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ContactInquirytList
                      requestList={requestList}
                      setSelectedRequestId={setSelectedRequestId}
                      clientId={clientId}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    );
  }

  return <></>;
};

export default ContactInquiries;
