import React, { useContext } from 'react';

import { Dropdown, NavItem, NavLink, Row, Col } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailbox, faEnvelopesBulk, faFileUser, faXmark, faEnvelope } from '@fortawesome/pro-thin-svg-icons';

import Avatar from '../Avatar';

import { merchantPartner } from '../../utils/global-const';

export default function UserMenu() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const openAccounts = () => {
    navigate('/accountdetails');
  };

  const openInquiries = () => {
    navigate('/document-requests');
  };

  const openChat = () => {
    navigate('/chat');
  };

  const openCommunication = () => {
    navigate('/comms');
  };

  return (
    <Dropdown as={NavItem} className='ms-auto' align='end'>
      <Dropdown.Toggle as={NavLink} className='pe-0' id='userInfo'>
        <Avatar image='/img/user.jpg' alt='User' border priority={1} size='sm' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdown-menu-animated' aria-labelledby='userInfo' data-bs-popper='none'>
        <Dropdown.Header className='text-gray-700'>
          <h6 className='text-uppercase font-weight-bold'>{merchantPartner}</h6>
        </Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item
          //  href="/account-details"
          disabled={(user && !user.rightAdmin) || (user && user.limitedaccounts)}
          onClick={openAccounts}
        >
          <Row>
            <Col xs={2} md={3}>
              <FontAwesomeIcon icon={faFileUser} color='blue' />
            </Col>
            <Col>Account</Col>
          </Row>
        </Dropdown.Item>
        {/* <Dropdown.Item onClick={openChat}>
          <Row>
            <Col xs={2} md={3}>
              <FontAwesomeIcon icon={faMessage} color='black' />
            </Col>
            <Col>Chat</Col>
          </Row>
        </Dropdown.Item>
        <Dropdown.Item onClick={openInquiries}>
          <Row>
            <Col xs={2} md={3}>
              <FontAwesomeIcon icon={faEnvelopesBulk} color='black' />
            </Col>
            <Col>Documents</Col>
          </Row>
        </Dropdown.Item> */}
        <Dropdown.Item onClick={openCommunication}>
          <Row>
            <Col xs={2} md={3}>
              <FontAwesomeIcon icon={faEnvelopesBulk} color='blue' />
            </Col>
            <Col>Contact Us</Col>
          </Row>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={signOut}>
          <Row>
            <Col xs={2} md={3}>
              <FontAwesomeIcon icon={faXmark} color='blue' />
            </Col>
            <Col>Logout</Col>
          </Row>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
