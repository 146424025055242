import { useContext, useState } from 'react';
import ClientContext from '../../store/client-context';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';

export default function AlmostThere(props) {
  const navigate = useNavigate();
  const { signOut, user } = useAuth();

  function forwardWallet() {
    signOut({
      redirect: false,
    });
    navigate('/');
  }

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Comment: Customer can now login into the Customer Wallet. The service requests will get visible to the
            service providers waiting for Aproval
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Registration Complete</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Form id='NoProductAvailable'>
          <Row className='mb-3'>
            {' '}
            <Col>
              <p>Congratulations!</p>
              <p>
                Your digital registration is complete. Now you may log into your digital wallet. All required details
                have been forwarded to the service providers. They will review these and grant access or we will get
                back to you in case they have some additional questions. The usage of the services require that you
                agree to the terms of use and data privacy conditions of each service. You will be requested to comply
                with these conditions on first usage.
              </p>
            </Col>
          </Row>
          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button variant='primary' size='lg' onClick={forwardWallet}>
                  Login
                </Button>{' '}
                <Button variant='outline-primary' size='lg' onClick={logoutHandler}>
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
