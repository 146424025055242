import { useEffect, useRef, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../api/HTTP';

import { Form, Container, Row, Col, Card, Button } from 'react-bootstrap';

// import Breadcrumbs from "../components/Breadcrumbs"
import { useAuth } from '../../contexts/auth';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Selection, Export } from 'devextreme-react/data-grid';
import Chart, { Series, CommonSeriesSettings } from 'devextreme-react/chart';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';

const AccountStatements = () => {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [clientAccounts, setClientAccounts] = useState([]);
  const [clientActivitySummary, setClientActivitySummary] = useState([]);
  const navigate = useNavigate();

  const StartDatePickerRef = useRef(false);
  const EndDatePickerRef = useRef(false);
  const AccountSelectRef = useRef(false);

  const exportFormats = ['pdf'];

  const closeAccount = () => {
    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user]);

  useEffect(() => {
    const fetchTransactions = async () => {
      const startdate = StartDatePickerRef.current.value;
      const enddate = EndDatePickerRef.current.value;

      await HTTP.get(
        `/client/client-transactions/${user.clientid}/${startdate}/${enddate}/0/${user.loggedinclientid}/${user.limitedaccounts}`
      )
        .then((response) => {
          setTransactions(response.data);
        })
        .catch((e) => {
          console.log('fetchTransactions occurred', e.message);
        });
    };
    if (user && user.clientid) {
      fetchTransactions();
    }
  }, [user]);

  useEffect(() => {
    const fetchClientAccountList = async () => {
      await HTTP.get(`/client/client-accounts/${user.clientid}/${user.loggedinclientid}/${user.limitedaccounts}`)
        .then((response) => {
          setClientAccounts(response.data);
        })
        .catch((e) => {
          console.log('fetchClientAccountList occurred', e.message);
        });
    };

    if (user && user.clientid) {
      fetchClientAccountList();
    }
  }, [user]);

  useEffect(() => {
    const fetchchartclientsummarySummary = async () => {
      const startdate = StartDatePickerRef.current.value;
      const enddate = EndDatePickerRef.current.value;

      await HTTP.get(
        `/client/chart-client-summary/${user.clientid}/${startdate}/${enddate}/${user.loggedinclientid}/${user.limitedaccounts}`
      )
        .then((response) => {
          setClientActivitySummary(response.data);
        })
        .catch((e) => {
          console.log('fetchClientAccountList occurred', e.message);
        });
    };
    if (user && user.clientid) {
      fetchchartclientsummarySummary();
    }
  }, [user]);

  async function getTransactions() {
    const transactiontype = AccountSelectRef.current.value;
    const startdate = StartDatePickerRef.current.value;
    const enddate = EndDatePickerRef.current.value;

    await HTTP.get(
      `/client/client-transactions/${user.clientid}/${startdate}/${enddate}/${transactiontype}/${user.loggedinclientid}/${user.limitedaccounts}`
    )
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((e) => {
        console.log('fetch Transactions occurred', e.message);
      });

    await HTTP.get(
      `/client/chart-client-summary/${user.clientid}/${startdate}/${enddate}/${user.loggedinclientid}/${user.limitedaccounts}`
    )
      .then((response1) => {
        setClientActivitySummary(response1.data);
      })
      .catch((e) => {
        console.log('fetch setClientActivitySummary occurred', e.message);
      });
  }

  const stTransactions = new ArrayStore({
    key: 'id',
    data: transactions,
  });

  const dcTransactions = new DataSource({
    store: stTransactions,
  });

  const stClientActivitySummary = new ArrayStore({
    key: 'rowid',
    data: clientActivitySummary,
  });

  const dcClientActivitySummary = new DataSource({
    store: stClientActivitySummary,
  });

  const get_Date = (an_Date) => {
    const ansiDate = new Date(an_Date.getTime() - an_Date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    return ansiDate;
  };

  const past_Date = (anDate, anDays) => {
    let dt = new Date(anDate);
    dt.setDate(dt.getDate() + anDays);
    return dt;
  };

  const anPastDate = past_Date(new Date(), -30);

  const [selStartDate, setSelStartDate] = useState(get_Date(new Date(anPastDate)));
  const [selEndDate, setSelEndDate] = useState(get_Date(new Date()));

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format;

  const FormatDateString = (anDateString) => {
    const anDate = anDateString
      .slice(0, 16)
      // .replace(/-/g, "/")
      .replace('T', ' ');
    return anDate;
  };

  const data = transactions;

  const saveReportData = () => {
    const transactiontype = AccountSelectRef.current.value;

    user.Report = {
      userid: user.clientid,
      transactiontype: transactiontype,
      transactionTypeService: transactiontype.split('-')[0],
      transactionTypeAsset: transactiontype.split('-')[1],
      startdate: StartDatePickerRef.current.value,
      enddate: EndDatePickerRef.current.value,
    };

    navigate('transaction-print');
  };

  const onExporting = useCallback((e) => {
    const doc = new jsPDF();

    exportDataGrid({
      jsPDFDocument: doc,
      component: e.component,
      indent: 5,
    }).then(() => {
      doc.save('statement.pdf');
    });
  });

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/*
      <Breadcrumbs title='Statement' />
       */}
      <section className='mb-4 mb-lg-5'>
        <Row>
          <Col lg={6} className='mb-4 mb-lg-0'>
            <Card className='h-100'>
              <Card.Header>
                <h4 className='card-heading'>Account Statement</h4>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                      <div className='fw-bold'>Account</div>
                      <Form.Select aria-label='Accounts' ref={AccountSelectRef}>
                        <option value='0'>All</option>
                        {clientAccounts.map((item, index) => (
                          <option value={item.assetaccountid} key={index}>
                            {item.srvceassets}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                      <div className='fw-bold'>From</div>
                      <Form.Control type='date' ref={StartDatePickerRef} defaultValue={selStartDate} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                      <div className='fw-bold'>Until</div>
                      <Form.Control type='date' ref={EndDatePickerRef} defaultValue={selEndDate} />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col md={12} xl={12} className='mb-1'>
                    <div className='float-end'>
                      <Button variant='primary' onClick={getTransactions}>
                        Open
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg={6} className='mb-4 mb-lg-0'>
            <Card className='h-100'>
              <Card.Header>
                <h4 className='card-heading'>Transactions in Period</h4>
              </Card.Header>
              <Card.Body>
                <Chart height={450} id='chart' dataSource={dcClientActivitySummary}>
                  <Series
                    barWidth={50}
                    valueField='transactions'
                    argumentField='svassetname'
                    name='Transactions'
                    type='bar'
                    color='#ffaa66'
                  />
                </Chart>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      {transactions && (
        <section className='mb-4 mb-lg-5'>
          <Card className='h-100'>
            <Card.Header>
              <h4 className='card-heading'>Transactions</h4>
            </Card.Header>
            <Card.Body>
              <DataGrid height={600} dataSource={dcTransactions} onExporting={onExporting}>
                <Selection mode='single' />
                <Export enabled={true} formats={exportFormats} allowExportSelectedData={true} />
                <Column dataField='transferdate' caption='Date' dataType='datetime' width={150} />
                <Column dataField='servicename' caption='Service' width={200} />
                <Column dataField='transfertext' caption='Transfer Text' width={300} />
                <Column dataField='operation' caption='Operation' width={80} />
                <Column dataField='assetname' caption='Asset' width={90} />
                <Column
                  dataField='assetamount'
                  caption='Amount'
                  format={{ type: 'fixedPoint', precision: 4 }}
                  width={120}
                />
                <Column dataField='assetcalcunit' caption='Unit' width={100} />
              </DataGrid>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col md={12} xl={12} className='mb-1'>
                  <div className='float-end'>
                    <Button variant='outline-info' onClick={saveReportData}>
                      Print
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </section>
      )}
      <Row className='mb-5'>
        <Col>
          <div className='float-end'>
            <Button variant='outline-secondary' onClick={closeAccount}>
              Close
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountStatements;
