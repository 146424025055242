import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../api/HTTP';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCcMastercard,
  faBitcoin,
  faCreativeCommonsShare,
  faCreativeCommonsRemix,
  faTradeFederation,
} from '@fortawesome/free-brands-svg-icons';
import { Card, Col, Row, Button, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import ClientContext from '../../store/client-context';

import { useAuth } from '../../contexts/auth';

const AssetCard = (props) => {
  const { card, gradient } = props;
  const { user } = useAuth();

  const navigate = useNavigate();
  const printRef = useRef(null);
  const userCtx = useContext(ClientContext);
  const [cardIdent, setCardIdent] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const saveAssetIdent = () => {
      const identvalue = card.assetaccountid + '-' + card.svassetid;
      setCardIdent(identvalue);
    };

    saveAssetIdent();
  }, [card.assetaccountid, card.svassetid]);

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format;

  const userSaveCard = (anCard) => {
    user.selCard = anCard;
  };

  function showDepositPage() {
    const serviceCategory = card.category_ident;

    userSaveCard(card);
    switch (serviceCategory) {
      case 'bnk':
        navigate(`/asset/bankdeposit`);
        break;
      case 'cyo':
        navigate(`/asset/assetbuy`);
        break;
      case 'mts':
        navigate(`/asset/assetbuy`);
        break;
      case 'ccd':
        navigate(`/asset/ccarddeposit`);
        break;
      case 'frx':
        navigate(`/asset/tradeview/`);
        break;
    }
  }

  function showTransferPage() {
    const serviceCategory = card.category_ident;

    userSaveCard(card);

    switch (serviceCategory) {
      case 'bnk':
        navigate(`/asset/banktransfer`);
        break;
      case 'cyo':
        navigate(`/asset/assetsell`);
        break;
      case 'mts':
        navigate(`/asset/assetsell`);
        break;
      case 'ccd':
        navigate(`/asset/ccardwithdraw`);
        break;
    }
  }

  function showAccountPage() {
    const serviceCategory = card.category_ident;

    userSaveCard(card);

    console.log(3333, card);

    switch (serviceCategory) {
      case 'bnk':
        console.log(4444);
        navigate('/account/bank');
        break;
      case 'cyo':
        navigate('/account/crypto');
        break;
      case 'mts':
        navigate('/account/commodities');
        break;
      case 'ccd':
        navigate('/account/ccard');
        break;
      case 'frx':
        navigate('/account/forex');
        break;
    }
  }

  async function acceptTermsOfUse() {
    try {
      const response = await HTTP.post(
        '/tou/tou-accept',
        JSON.stringify({
          clientid: card.clientid,
          serviceid: card.serviceid,
          accepted: true,
        })
      );
      const data = await response.data;
      // console.log(data)
      if (data.success) window.location.reload();
    } catch (error) {
      console.log('terms-of-use accept error', error);
    }
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'TermsOfUse',
  });

  return (
    <Card className={`credit-card bg-hover-gradient-${gradient}`}>
      <div className='credict-card-content'>
        <div className='h1 mb-3 mb-lg-1'>
          {card.category_name === 'Banking' && <FontAwesomeIcon icon={faCreativeCommonsShare} />}
          {card.category_name === 'Cards' && <FontAwesomeIcon icon={faCcMastercard} />}
          {card.category_name === 'Crypto' && <FontAwesomeIcon icon={faBitcoin} />}
          {card.category_name === 'Metals' && <FontAwesomeIcon icon={faCreativeCommonsRemix} />}
          {card.category_name === 'Forex' && <FontAwesomeIcon icon={faTradeFederation} />}
        </div>
        <div className='credit-card-bottom'>
          <div className='text-uppercase flex-shrink-0 me-1 mb-1'>
            <div className='fw-bold'>{card.servicename + ' - ' + card.assetname}</div>
            <small className='text-gray-500'>{card.accountvalue}</small>
          </div>
          <div className='fw-bold'>
            {formatCurrency(parseFloat(card.asset_amount).toFixed(2)).replace('EUR', '')} {card.assetcalcunit}
          </div>
          <Row>
            <Col md={12} xl={12} className='mb-1'>
              <div className='float-end'>
                {!card.touAccepted && (
                  <>
                    <Button variant='warning' size='sm' onClick={handleShow}>
                      Terms Of Use
                    </Button>{' '}
                  </>
                )}
                {card.category_name !== 'Forex' && (
                  <>
                    <Button variant='primary' size='sm' onClick={showDepositPage} disabled={!card.provider_approved}>
                      {card.in_ops}
                    </Button>{' '}
                    <Button variant='primary' size='sm' onClick={showTransferPage} disabled={!card.provider_approved}>
                      {card.out_ops}
                    </Button>{' '}
                  </>
                )}
                {card.category_name === 'Forex' && (
                  <>
                    <Button variant='primary' size='sm' onClick={showDepositPage} disabled={!card.provider_approved}>
                      {card.in_ops}
                    </Button>{' '}
                  </>
                )}
                <Button variant='secondary' size='sm' onClick={showAccountPage} disabled={!card.provider_approved}>
                  Account
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {!card.touAccepted && (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{card.tou?.doc_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body ref={printRef}>{card.tou?.doc_content}</Modal.Body>
            <Modal.Footer>
              <Button variant='primary' onClick={acceptTermsOfUse}>
                Accept Terms Of Use
              </Button>
              <Button variant='primary' onClick={handlePrint}>
                Print
              </Button>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {/*  <a className="stretched-link" href={card.svassetid} /> */}
    </Card>
  );
};

export default AssetCard;
