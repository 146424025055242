import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceAwesome } from '@fortawesome/pro-thin-svg-icons';
import { Button, FileUploader, Popup, TextArea } from "devextreme-react";
import EmojiPicker from "emoji-picker-react";
import { sanitize } from "dompurify";
import { marked } from "marked";
import { useEffect, useState } from "react";
import notify from "devextreme/ui/notify";
import MessagingHelper from './MessagingHelper';

const ChatFooter = (props) => {
  const {
    selectedChatData,
    user,
    messageToReply,
    messageIdToReply,
    handleRemoveMessageToReply,
    setMessageToReply,
    setMessageIdToReply,
    newMessageReseivedHandler,
  } = props;

  let ALLOWED_DOCUMENT_TYPES;

  if (process.env.REACT_APP_ALLOWED_DOCUMENT_TYPES) {
    ALLOWED_DOCUMENT_TYPES = process.env.REACT_APP_ALLOWED_DOCUMENT_TYPES.split(',');
  } else {
    ALLOWED_DOCUMENT_TYPES = ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.jfif', '.bmp', '.png'];
  }

  let MAX_DOCUMENT_SIZE;
  if (process.env.REACT_APP_MAX_DOCUMENT_SIZE) {
    MAX_DOCUMENT_SIZE = Number(process.env.REACT_APP_MAX_DOCUMENT_SIZE) * 1024 * 1024;
  } else {
    MAX_DOCUMENT_SIZE = 1024 * 1024;
  }

  const [inputMessage, setInputMessage] = useState('');
  const [attaches, setAttaches] = useState([]);
  const [attachesPreview, setAttachesPreview] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showPopupEmojiPicker, setShowPopupEmojiPicker] = useState(false);

  useEffect(() => {
    setInputMessage('');
    setAttaches([]);
    setShowEmojiPicker(false);
    setShowPopupEmojiPicker(false);
  }, [selectedChatData]);

  const pickEmojiHandler = (emojiData) => {
    setInputMessage((message) => message + emojiData.emoji);
  };

  const handleEnterKeyPress = (e) => {
    e.event.preventDefault();
    sendMessage();
  }

  const sendMessage = async () => {
    if (inputMessage !== '' || attaches.length > 0) {
      const formData = new FormData();
      formData.append('threadId', String(selectedChatData?.threadId));
      formData.append('message', !inputMessage ? '' : inputMessage);
      formData.append('sender', 'user');
      formData.append('openedBy', user.portalid);
      formData.append('repliedMessageId', messageIdToReply);

      if (attaches.length > 0) {
        attaches.forEach((file) => {
          formData.append('files', file, file.name);
        });
      }

      MessagingHelper.addThreadMessage(formData)
        .then((res) => {
          newMessageReseivedHandler(res);
        })
        .catch((error) => {
          if (error.response) {
            notify(error.response.data.message, 'error', 2000);
          } else {
            notify('An error occurred while sending the message.', 'error', 2000);
          }
        });

      setShowEmojiPicker(false);
      setShowPopupEmojiPicker(false);
      setInputMessage('');
      setMessageToReply(null);
      setMessageIdToReply(-1);
      setAttaches([]);
      setAttachesPreview([]);
    }
  }

  const uploadFiles = (e) => {
    setAttachesPreview([]);
    e.value.forEach(file => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const fileType = '.' + file.type.split('/')[1];

        if (ALLOWED_DOCUMENT_TYPES.includes(fileType)) {
          setAttachesPreview(prevState => [...prevState, fileReader.result]);
        }
      }
      fileReader.readAsDataURL(file);
    });

    setAttaches(e.value.filter(file => {
      const fileType = '.' + file.type.split('/')[1];

      if (!ALLOWED_DOCUMENT_TYPES.includes(fileType)) return;

      return file;
    }));
  }

  return (
    <>
      <Popup
        title={'Send File'}
        width={500}
        height='auto'
        maxHeight={600}
        showCloseButton={true}
        visible={attaches.length > 0}
        resizeEnabled={false}
        onHiding={() => {
          setAttaches([]);
          setAttachesPreview([]);
        }}
        onShown={(args) => {
          args.component.content().parentElement.classList.add('custom-files-popup-style');
        }}
      >
        <div className="files-list">
          {attachesPreview.map((file, index) => (
            <div
              key={index}
              className="files-list-item"
            >
              <img src={file} />

              <div className="files-list-item-info">
                <span className="files-list-item-name">
                  {attaches[index]?.name}
                </span>
                <span className="files-list-item-size">
                  {attaches[index]?.size / 1000} KB
                </span>
              </div>

              <Button
                onClick={() => {
                  setAttaches(prevState => prevState.filter((item, idx) => idx !== index))
                  setAttachesPreview(prevState => prevState.filter((item, idx) => idx !== index))
                }}
                width={25}
                height={25}
                stylingMode='text'
                type='default'
                icon='close'
              />
            </div>
          ))}
        </div>
        <div className="input-container">
          <div
            className="message-input-wrapper"
          >
            <div
              className="message-emoji-picker"
              style={{
                position: 'absolute',
                display: showPopupEmojiPicker ? 'block' : 'none',
                scale: showPopupEmojiPicker ? '1' : '0',
              }}
            >
              {showPopupEmojiPicker && (
                <EmojiPicker
                  onEmojiClick={pickEmojiHandler}
                  lazyLoadEmojis={true}
                  skinTonesDisabled={true}
                  searchDisabled={true}
                  width='100%'
                  height={300}
                  previewConfig={{
                    defaultEmoji: '',
                    defaultCaption: '',
                    showPreview: false,
                  }}
                  categories={[
                    {
                      category: 'smileys_people',
                      name: false,
                    },
                  ]}
                />
              )}
            </div>
            <div
              className="symbol-menu-button"
            >
              <FontAwesomeIcon
                className='message-emoji-button'
                width={50}
                height={50}
                icon={faFaceAwesome}
                size='xl'
                cursor='pointer'
                onClick={() => setShowPopupEmojiPicker(prevState => !prevState)}
              />
            </div>
            <TextArea
              valueChangeEvent='keyup'
              onValueChanged={(e) => {
                if (typeof e.value === 'string') {
                  setInputMessage(e.value)
                }
              }}
              value={inputMessage}
              className={'message-imput-text'}
              minHeight={50}
              maxHeight={100}
              placeholder={'Message'}
              onEnterKey={handleEnterKeyPress}
            />
          </div>

          <Button
            className='chat-footer-send-button'
            onClick={sendMessage}
            width={50}
            height={50}
            stylingMode='text'
            type='default'
            icon='arrowright'
          />
        </div>
      </Popup>
      <div
        className="chat-footer"
      >
        {messageToReply && (
          <div className='message-to-reply'>
            <div className="message-to-reply-content">
              <p className="message-to-reply-user">
                {messageToReply.customerName}
              </p>
              <div
                className='message-to-reply-text'
                dangerouslySetInnerHTML={{ __html: sanitize(marked.parse(messageToReply.message)) }}
              />
            </div>
            <Button
              className='message-to-reply-button'
              onClick={handleRemoveMessageToReply}
              width={36}
              height={36}
              stylingMode='text'
              type='default'
              icon='close'
            />
          </div>
        )}
        <div className="input-container">
          <div
            className="message-input-wrapper"
          >
            <div
              className="message-emoji-picker"
              style={{
                display: showEmojiPicker ? 'block' : 'none',
                scale: showEmojiPicker ? '1' : '0',
              }}
            >
              {showEmojiPicker && (
                <EmojiPicker
                  onEmojiClick={pickEmojiHandler}
                  lazyLoadEmojis={true}
                  skinTonesDisabled={true}
                  searchDisabled={true}
                  width='100%'
                  height={300}
                  previewConfig={{
                    defaultEmoji: '',
                    defaultCaption: '',
                    showPreview: false,
                  }}
                  categories={[
                    {
                      category: 'smileys_people',
                      name: false,
                    },
                  ]}
                />
              )}
            </div>
            <div
              className="symbol-menu-button"
            >
              <FontAwesomeIcon
                className='message-emoji-button'
                width={50}
                height={50}
                icon={faFaceAwesome}
                size='xl'
                cursor='pointer'
                onClick={() => setShowEmojiPicker(prevState => !prevState)}
              />
            </div>
            <TextArea
              valueChangeEvent='keyup'
              onValueChanged={(e) => {
                if (typeof e.value === 'string') {
                  setInputMessage(e.value)
                }
              }}
              value={attaches.length === 0 && inputMessage}
              className={'message-imput-text'}
              minHeight={50}
              maxHeight={100}
              placeholder={'Message'}
              onEnterKey={handleEnterKeyPress}
            />

            <FileUploader
              visible={false}
              // value={attaches}
              dialogTrigger='#file-uploading-button'
              onValueChanged={uploadFiles}
              uploadMode='useForm'
              showFileList={false}
              multiple={true}
              allowedFileExtensions={ALLOWED_DOCUMENT_TYPES}
              maxFileSize={MAX_DOCUMENT_SIZE}
            />
            <Button
              className='attaches-button'
              id='file-uploading-button'
              width={50}
              height={50}
              stylingMode='text'
              type='default'
              icon='pin'
            />
          </div>

          <Button
            className='chat-footer-send-button'
            onClick={sendMessage}
            width={50}
            height={50}
            stylingMode='text'
            type='default'
            icon='arrowright'
          />
        </div>
      </div>
    </>
  )
}

export default ChatFooter;