import React, { useEffect, useState } from 'react';

import './Chat.css';

import { useAuth } from '../../../contexts/auth';

import ChatBody from './ChatBody';
import { useMessaging } from '../../../contexts/messaging';

const Chat = () => {
  const [selectedChatData, setSelectedChatData] = useState(null);
  const {
    handleReadMessages,
  } = useMessaging();

  const { user } = useAuth();

  useEffect(() => {
    const clientChatid = user && parseInt(user.clientchatid);
    const portalName = user && user.portalname;

    setSelectedChatData({
      threadId: clientChatid,
      chatTitle: portalName,
    });
  }, [user]);

  const readMessagesHandler = () => {
    handleReadMessages(user);
  }

  return (
    <ChatBody
      user={user}
      selectedChatData={selectedChatData}
      readMessagesHandler={readMessagesHandler}
    />
  );
};

export default Chat;
