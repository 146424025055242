import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Row, Col, InputGroup, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../../api/HTTP';
import * as Yup from 'yup';
import Execute from './ModalForms/AssetSell/Execute';

export default function BankDepositForm(props) {
  const { user, selCard, acctid, assetid } = props;

  const navigate = useNavigate();
  const [lpExecutionType, setLpExecutionType] = useState([]);
  const [clientBankAccounts, setClientBankAccounts] = useState([]);
  const [counter, setCounter] = useState(0);
  const [showCounter, setShowCounter] = useState(false);

  const formik = useFormik({
    initialValues: {
      sellAsset: selCard.assetname,
      sellAmount: '',
      sellValue: '',
      sellTo: selCard.mb_serviceid,
      sellExecution: 1,
    },
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 500));
      // alert(JSON.stringify(values, null, 2))
      // navigate('/', { replace: true })
    },
    validationSchema: Yup.object().shape({
      sellAsset: Yup.string().required('Required'),
      sellAmount: Yup.number().required('Required').positive(),
      sellValue: Yup.number().required('Required').positive(),
      sellTo: Yup.string().required('Required'),
    }),
  });

  useEffect(() => {
    const fetcExecutionType = async () => {
      await HTTP.get(`/base/get-lookup-values/19`)
        .then((response) => {
          setLpExecutionType(response.data);
        })
        .catch((e) => {
          console.log('fetcExecutionType error occurred', e.message);
        });
    };

    const fetchClientBankAccounts = async () => {
      await HTTP.get(`/client/client-bank-accounts/${user.clientid}/${user.loggedinclientid}/${user.limitedaccounts}`)
        .then((response) => {
          formik.values.sellTo = response.data[0].assetaccountid;
          setClientBankAccounts(response.data);
        })
        .catch((e) => {
          console.log('fetchClientBankAccounts error occurred', e.message);
        });
    };

    if (user && user.clientid) {
      fetcExecutionType();
      fetchClientBankAccounts();
    }
  }, []);

  const cancelExecution = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setShowCounter(false);
    }
  }, [counter]);

  const offerRequest = async (offertype) => {
    try {
      const response = await HTTP.post(
        '/ops/asset-offer-request',
        JSON.stringify({
          assetofferrequest: 2,
          buysellop: 2,
          offertype: offertype,
          clientid: user.clientid,
          serviceid: selCard.serviceid,
          providerid: selCard.providerid,
          partnerid: user.partnerid,
          productid: user.productid,
          routingid: selCard.routingid,
          assetid: selCard.svassetid,
          assetamount: formik.values.sellAmount,
          currencyamount: formik.values.sellValue,
          currency: selCard.currency,
          categoryident: selCard.category_ident,
        })
      );

      const data = response.data;
      console.log(data);
      if (offertype === 1) {
        formik.setFieldValue('sellValue', data.offer_currencyamount);
      } else {
        formik.setFieldValue('sellAmount', data.offer_assetamount);
      }

      setCounter(data.offer_valid_count);
      setShowCounter(true);
    } catch (error) {
      console.log('asset-offer-error', error);
    }
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={showCounter}>
          <Toast.Body>Offer valid for {counter} seconds</Toast.Body>
        </Toast>
      </ToastContainer>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='sellAsset'>
            Sell
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            id='sellAsset'
            name='sellAsset'
            type='sellAsset'
            placeholder='Asset'
            value={formik.values.sellAsset}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.sellAsset && formik.touched.sellAsset && 'error'}`}
          />
          {formik.errors.sellAsset && formik.touched.sellAsset && (
            <div className='input-feedback'>{formik.errors.sellAsset}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='sellAmount'>
            Amount
          </Form.Label>
        </Col>
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text>{selCard.assetcalcunit}</InputGroup.Text>
            <Form.Control
              id='sellAmount'
              name='sellAmount'
              type='sellAmount'
              // placeholder="Asset"
              aria-label='Amount (with dot and two decimal places)'
              value={formik.values.sellAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`form-control ${formik.errors.sellAmount && formik.touched.sellAmount && 'error'}`}
            />
          </InputGroup>
        </Col>
        <Col md={3} className='d-grid'>
          <Button
            variant='secondary'
            size='sm'
            onClick={() => {
              offerRequest(1);
            }}
          >
            Request Offer
          </Button>
        </Col>
        <Col md={3} />
        <Col md={7}>
          {formik.errors.sellAmount && formik.touched.sellAmount && (
            <div className='input-feedback'>{formik.errors.sellAmount}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='sellValue'>
            For
          </Form.Label>
        </Col>
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text>{selCard.currency}</InputGroup.Text>
            <Form.Control
              id='sellValue'
              name='sellValue'
              type='sellValue'
              // placeholder="For"
              aria-label='Amount (with dot and two decimal places)'
              value={formik.values.sellValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`form-control ${formik.errors.sellValue && formik.touched.sellValue && 'error'}`}
            />
          </InputGroup>
        </Col>
        <Col md={3} className='d-grid'>
          <Button
            variant='secondary'
            size='sm'
            onClick={() => {
              offerRequest(2);
            }}
          >
            Request Offer
          </Button>
        </Col>
        <Col md={3} />
        <Col md={7}>
          {formik.errors.sellValue && formik.touched.sellValue && (
            <div className='input-feedback'>{formik.errors.sellValue}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='sellTo'>
            to
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Select
            id='sellTo'
            name='sellTo'
            as='select'
            placeholder='Bank Account'
            className='form-select'
            value={formik.values.sellTo}
            onChange={formik.handleChange}
          >
            {clientBankAccounts.map((item, rowindex) => (
              <option value={item.assetaccountid} key={rowindex}>
                {item.bank}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='sellExecution'>
            Execution
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Select
            id='sellExecution'
            name='sellExecution'
            type='sellExecution'
            as='select'
            placeholder='Execution'
            className='form-select'
            value={formik.values.sellExecution}
            onChange={formik.handleChange}
          >
            {lpExecutionType.map((item, rowindex) => (
              <option value={item.lp_id} key={rowindex}>
                {item.lp_value}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <div className='mb-3'>
        <div className='float-end'>
          <Execute
            sellAsset={formik.values.sellAsset}
            sellAssetError={formik.errors.sellAsset}
            sellAmount={formik.values.sellAmount}
            sellAmountError={formik.errors.sellAmount}
            sellValue={formik.values.sellValue}
            sellValueError={formik.errors.sellValue}
            sellTo={formik.values.sellTo}
            sellToError={formik.errors.sellTo}
            user={user}
            selCard={selCard}
            accountid={acctid}
            assetid={assetid}
          />{' '}
          <Button variant='secondary' onClick={cancelExecution}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
