import HTTP from '../../../api/HTTP';
import HTTPFileUpload from '../../../api/HTTPFileUpload';

class MessagingHelper {
  getThreads(clientId, queryStringData) {
    return HTTP.get(`/internal_messaging/threads?clientId=${clientId}`, {
      params: queryStringData,
    }).then((response) => response.data);
  }

  getThreadDetails(theadId) {
    return HTTP.get(`/internal_messaging/threads/${theadId}`).then((response) => response.data);
  }

  createThread(threadData) {
    return HTTP.post('/internal_messaging/threads', threadData).then((response) => {
      return response.data;
    });
  }

  addThreadMessage(threadData) {
    return HTTPFileUpload.post('/internal_messaging/messages', threadData).then((response) => response.data);
  }

  modReplyMessageWithEmoji(threadData) {
    return HTTP.post('/internal_messaging/emoji', threadData).then((response) => response.data);
  }

  getThreadMessages(threadId, queryStringData) {
    return HTTP.get(`/internal_messaging/messages/${threadId}`, {
      params: queryStringData,
    }).then((response) => response.data);
  }

  getNewThreadMessages(threadId, queryStringData) {
    return HTTP.get(`/internal_messaging/new-messages/${threadId}`, {
      params: queryStringData,
    }).then((response) => response.data);
  }

  getFileContent = (documentId) => {
    return HTTP.get(`/internal_messaging/files/${documentId}`).then((response) => response.data);
  };

  deleteGroup = (threadData) => {
    return HTTP.post('/internal_messaging/modgroupchat', threadData).then((response) => response.data);
  };

  leaveGroup = (threadData) => {
    return HTTP.post('/internal_messaging/leave-group', threadData).then((response) => response.data);
  };

  getMessageById = (messageId) => {
    return HTTP.get(`/internal_messaging/message/${messageId}`).then((response) => response.data);
  }
}

export default new MessagingHelper();
