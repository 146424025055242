import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Card, Form, Modal } from 'react-bootstrap';
import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';
import { useHttpClient } from '../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import CreateInquiryPopUp from './CreateInquiryPopUp';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/date-helper';
import { querystring } from '../../utils/functions';
import { merchantPartner } from '../../utils/global-const';

export default function InquiriesList(props) {
  const { user } = props;
  const [inquiries, setInquiries] = useState([]);
  const [selInquiryId, setSelInquiryId] = useState(-1);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(1)
    const getClientInquiries = async () => {
      HTTP.get(`/inquiry/inquiry-list/${user.clientid}`)
        .then((response) => {
          setInquiries(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (user) {
      getClientInquiries();
    }
  }, [user]);

  const stInquiries = new ArrayStore({
    key: 'inquiryid',
    data: inquiries,
  });

  const dcInquiries = new DataSource({
    store: stInquiries,
  });

  const toggleModal = () => {
    setShow(!show);
  };

  const openInquiry = (anInquiryId) => {
    user.selInquiryId = anInquiryId;
    navigate('/inquiry');
  };

  return (
    <React.Fragment>
      <Modal show={show} onHide={toggleModal} size={'lg'}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Contact {merchantPartner}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateInquiryPopUp cancelCreateInquiry={toggleModal} user={user} />
        </Modal.Body>
      </Modal>

      <Card className='overflow-hidden mb-4'>
        <section className='mb-4 mb-lg-5'>
          <Card.Header>
          <h4 className='card-heading'>{'Document Requests'}</h4>
          </Card.Header>
          <Card.Body>
            <DataGrid
              height={600}
              dataSource={dcInquiries}
              allowColumnReordering={true}
              allowColumnResizing={true}
              onSelectionChanged={(e) => {
                if (e.selectedRowKeys[0]) {
                  openInquiry(e.selectedRowKeys[0]);
                  setSelInquiryId(e.selectedRowKeys[0]);
                }
              }}
            >
              <Selection mode='single' />
              <Column dataField='subject' caption='Subject' width={250} />
              <Column dataField='statetext' caption='Status' width={90} />
              <Column dataField='inquirytype' caption='Type' width={200} />
              <Column dataField='inquirydate' dataType='datetime' caption='From' width={180} />
              <Column dataField='lastupdated' caption='Last Modified' ataType='datetime' width={200} />
            </DataGrid>
          </Card.Body>
        </section>
      </Card>
    </React.Fragment>
  );
}
