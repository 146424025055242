import { useEffect, useContext, useState } from 'react';
import ClientContext from '../../store/client-context';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';
import HTTP from '../../api/HTTP';
import { faHippo } from '@fortawesome/free-solid-svg-icons';
// import snsWebSdk from '@sumsub/websdk';

async function clientScreeningResults(userData) {
  const response = await fetch('/api/comply-advantage/screening-results', {
    method: 'PATCH',
    body: JSON.stringify({ userData: userData }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  return data;
}

async function saveGeoDetails(clientid) {
  const response = await fetch('/api/geoip/save-details', {
    method: 'PATCH',
    body: JSON.stringify({ clientid: clientid }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  return data;
}

async function getUserData(email) {
  const response = await HTTP.get(`/signup/client-data/${email}`);
  return response.data[0];
}

async function downloadApplicantData(applicantId, signupId) {
  const response = await fetch('/api/sumsub/download-applicant-data', {
    method: 'POST',
    body: JSON.stringify({ applicantId: applicantId, signupId: signupId }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  console.log(data);
  if (data.status === 'success') return 'success';
  return false;
}

async function getKYCId(email) {
  try {
    const response = await HTTP.get(`/signup/client-kyc-id/${email}`);
    return response.data[0].kycId;
  } catch (error) {
    console.log('getKYCId error', error);
  }
}

async function getKYCExternalUserId(email) {
  const response = await fetch('/api/client/kyc-external-user-id', {
    method: 'PATCH',
    body: JSON.stringify({ email: email }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  console.log(data);
  return data.kycExternalUserId;
}

async function getApplicantStatus(email) {
  const applicantId = await getKYCId(email);
  const response = await fetch('/api/sumsub/applicant-status', {
    method: 'POST',
    body: JSON.stringify({
      applicantId: applicantId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  // console.log(data)
  return data;
}

async function createAccessToken(email) {
  const levelName = 'kyc';
  const ttlInSecs = 600;
  const externalUserId = await getKYCExternalUserId(email);
  const response = await fetch('/api/sumsub/create-access-token', {
    method: 'POST',
    body: JSON.stringify({
      externalUserId: externalUserId,
      levelName: levelName,
      ttlInSecs: ttlInSecs,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  console.log(data);
  return data.token;
}

async function getNewAccessToken(email) {
  return await createAccessToken(email);
}

{
  /* v
async function launchWebSdk(email) {
  const accessToken = await createAccessToken(email);
  console.log(accessToken);
  let snsWebSdkInstance = snsWebSdk
    .init(accessToken, () => getNewAccessToken(email))
    .withConf({
      lang: 'en',
    })
    .on('onError', (error) => {
      console.log('onError', payload);
    })
    .onMessage((type, payload) => {
      console.log('onMessage', type, payload);
    })
    .build();

  snsWebSdkInstance.launch('#sumsub-websdk-container');
}
*/
}

export default function KYCCheckSumSub(props) {
  const { nextSignupStep, signupCompletion, userEmail, signupId, getRiskLevels, getFailedReasons, doReloadUser } =
    props;
  const { signOut, user } = useAuth();

  const [riskLevels, setRiskLevels] = useState(null);
  const [failedReasons, setFailedReasons] = useState(null);
  const [checkPassed, setCheckPassed] = useState(true);
  const [checkRiskLevelId, setCheckRiskLevelId] = useState(user && user.risk_result_one);
  const [checkFailed, setCheckFailed] = useState(false);
  const [checkFailedReasonid, setCheckFailedReasonid] = useState('');
  const [showStatus, setStatusToast] = useState(false);

  useEffect(() => {
    async function getAllRiskLevels() {
      const allRiskLevels = await getRiskLevels();
      setRiskLevels(allRiskLevels);
      console.log('---');
      console.log(allRiskLevels);
      console.log('---');
    }

    async function getAllFailedReasons() {
      const allFailedReasons = await getFailedReasons();
      setFailedReasons(allFailedReasons);
      console.log('---');
      console.log(allFailedReasons);
      console.log('---');
    }

    async function getLaunchWebSdk() {
      //   await launchWebSdk(userEmail);
    }

    getAllRiskLevels();
    getAllFailedReasons();
    // getLaunchWebSdk()
  }, []);

  // Get SumSub applicant status
  const validateApplicantStatus = async () => {
    // const applicantStatus = await getApplicantStatus(userEmail);
    //  console.log(applicantStatus);

    // Entwicklungssetup
    setCheckPassed(true);
    setCheckFailed(false);
    continueToNextDevSetup();

   // const userD = await getUserData(userEmail);
   //  await clientScreeningResults(userD);

    {
      /* 

    // Check for reviewResult undefined
    if (typeof applicantStatus.reviewResult !== "undefined") {
      // Check if reviewResult is green and status is completed
      if (
        applicantStatus.reviewResult.reviewAnswer === "GREEN" &&
        applicantStatus.reviewStatus === "completed"
      ) {
        // Download kyc data of client from sumsub
        const downloadedApplicantData = await downloadApplicantData(
          await getKYCId(userEmail),
          await getSignupId(userEmail)
        )
        if (downloadedApplicantData === "success") {
          // If completed
          continueToNext()
        }
      }
    } else {
      // Entwicklungssetup
      // setCheckPassed(true)
      // setCheckFailed(false)
      // continueToNextDevSetup()

      //  Live Setup
       console.log("Status has to be completed!")
       setStatusToast(true)

      const signupId = await getSignupId(userEmail)
      await saveGeoDetails(signupId)
      await clientScreeningResults(userData)

      // For Testing purpose comment out
      // const userD = await getUserData(userEmail)
      // await clientScreeningResults(userD)
    }
    */
    }
  };

  const continueToNext = async () => {
    setCheckPassed(true);

    // Save geolocation details and start background screening of client
    const signupId = user.signupid;
    const userData = await getUserData(userEmail);

    await saveGeoDetails(signupId);
    await clientScreeningResults(userData);

    const clientData = {
      signupstage: 90,
      signupid: signupId,
      gp_started: true,
      gp_passed: checkPassed,
      gp_failed: checkFailed,
      gp_failed_reason: -1,
    };

    const updated = await signupCompletion(clientData);
    if (updated.updated) {
      await doReloadUser();
      nextSignupStep(updated.signupstage);
    }
  };

  const continueToNextDevSetup = async () => {
    setCheckPassed(true);

    // Save geolocation details and start background screening of client
    const signupId = user.signupid;
    const userData = await getUserData(userEmail);

    // await saveGeoDetails(signupId)
    // await clientScreeningResults(userData)

    const clientData = {
      signupstage: 90,
      signupid: signupId,
      gp_started: true,
      gp_passed: checkPassed,
      gp_failed: checkFailed,
      gp_failed_reason: -1,
      // client_risklevelid: 1,
    };

    const updated = await signupCompletion(clientData);
    if (updated.updated) {
      await doReloadUser();
      nextSignupStep(updated.signupstage);
    }
  };

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={showStatus} bg='warning'>
          <Toast.Body>KYC process has to be completed with a positive result!</Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Biometric Personal Verification</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Form id='kycCheckForm'>
          <Row>
            <Col>
              KYC Provider Check to happen now. Once completed successfully the Applicant can proceed.
              <div id='sumsub-websdk-container'></div>
            </Col>
          </Row>

          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button
                  variant='primary'
                  size='lg'
                  // type="submit"
                  disabled={!checkPassed}
                  onClick={validateApplicantStatus}
                >
                  Continue
                </Button>{' '}
                <Button variant='outline-primary' size='lg' onClick={logoutHandler}>
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
