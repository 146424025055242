import HTTP from '../api/HTTP';

class AuthService {
  login = (email, password) => {
    const data = {
      username: email,
      password: password,
    };

    console.log(88888, data);

    return HTTP.post('/auth/login', data, {
      headers: {
        'Client-type': 'service-provider-portal',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  };

  getAuthUser = () => {
    return HTTP.get('/auth/me').then((response) => response.data);
  };

  getInviteInfo = (inviteToken) => {
    return HTTP.get('/invite/' + inviteToken);
  };

  confirmInvite = async (inviteToken, password, email) => {
    return HTTP.post('/invite/register', {
      hash: inviteToken,
      password: password,
      email: email,
    });
  };

  activateGoogle2auth = async (pin, auth2hash) => {
    return HTTP.post('/invite/2f-auth', {
      hash: auth2hash,
      pin: pin,
    });
  };

  resetPasswordRequest = (email) => {
    return HTTP.post('/auth/reset-password/email', {
      email: email,
    });
  };

  setNewPassword = (token, password, confirmPassword) => {
    return HTTP.post('/auth/reset-password/change', {
      token: token,
      newPassword: password,
      newPasswordRetry: password,
    });
  };

  sendGoogleAuthPin(pin, rememberMe, hash) {
    return HTTP.post('/auth/2f-auth', {
      pin: pin,
      hash: hash,
      rememberMe: rememberMe,
    });
  }
}

export default new AuthService();
