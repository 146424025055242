import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import HTTP from '../../../../../api/HTTP';

export default function Execute(props) {
  const { currencyError, currency, amountError, amount, toBank, toBankError, user, selCard, accountid, assetid } =
    props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const submitButtonRef = useRef(false);
  const [execTransactionid, setEexecTransactionid] = useState();
  const [clientFee, setClientFee] = useState();

  const toggleModal = () => {
    if (!currencyError && currency !== '' && !amountError && amount !== '' && !toBankError && toBank !== '') {
      setShow(!show);
    }
  };

  const executeSellAsset = async (offertype) => {
    console.log('-');
    console.log(user);
    console.log('-');
    console.log('---');
    console.log(selCard);
    console.log('---');

    try {
      const response = await HTTP.post(
        '/ops/sell-asset',
        JSON.stringify({
          transactionid: execTransactionid,
          assetaccountid: selCard.assetaccountid,
          provider_accountid: selCard.provider_accountid,
          sellto_assetaccountid: toBank,
          buysellop: 6,
          offertype: offertype,
          stage: 5,
          clientid: user.clientid,
          serviceid: selCard.serviceid,
          providerid: selCard.providerid,
          partnerid: user.partnerid,
          productid: user.productid,
          routingid: selCard.routingid,
          bankaccountid: selCard.bankaccountid,
          categoryid: selCard.categoryid,
          categoryident: selCard.category_ident,
          assetid: selCard.assetid,
          assetcalcunit: selCard.assetcalcunit,
          assetamount: parseFloat(amount),
          currencyamount: parseFloat(amount),
          currency: selCard.currency,
          reqfunds: amount,
          mb_serviceid: selCard.mb_serviceid,
          svassetid: selCard.svassetid,
          assetname: selCard.assetname,
        })
      );
      const data = await response.data;
      console.log(data);

      if (data.status === 'success') return true;
    } catch (error) {
      console.log('trade-withdraw-error', error);
    }
  };

  const DetermineFees = async () => {
    try {
      const response = await HTTP.post(
        '/ops/calc-transaction-fees',
        JSON.stringify({
          clientid: user.clientid,
          client_userid: user.loggedinclientid,
          riskid: user.client_risklevelid,
          buysellop: 6,
          assetaccountid: selCard.assetaccountid,
          currency: selCard.currency,
          assetamount: amount,
          currencyamount: amount,
          svassetid: selCard.svassetid,
          assetid: selCard.assetid,
        })
      );
      const data = await response.data;

      await setEexecTransactionid(data[0].transactionid);
      await setClientFee(data[1].calc_transaction_fees);

      toggleModal();
    } catch (error) {
      console.log('calc-transaction-fees-error', error);
    }
  };

  const updateBalanceFunds = async () => {
    const response = await HTTP.get(`/forex/balance-funds/${user.loggedinclientid}`);
    console.log(response.data);
  };

  const handleSubmit = async (values) => {
    // executeSellAsset(2)
    // navigate('/', { replace: true })

    const sellAsset = await executeSellAsset(2);

    if (sellAsset) {
      updateBalanceFunds();
      navigate(-1);
    } else {
      console.log('Withdraw of funds was not executed!');
    }
  };

  return (
    <React.Fragment>
      <Button variant='primary' type='submit' onClick={DetermineFees}>
        Execute
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Review Trading accountid Withdraw
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure to make the withdraw with following values?</p>
          <Form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <br />
              <p>Currency: {currency}</p>
              <p>
                Amount: {selCard.currency} {amount}
              </p>
              <p>
                Fees: {selCard.currency} {clientFee && clientFee}
              </p>{' '}
            </div>
            <Button variant='primary' className='d-none' type='submit' ref={submitButtonRef}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitButtonRef.current.click()}>
            Apply
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
