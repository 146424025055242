import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import HTTP from '../../../api/HTTP';

import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';

import Chart, {
  CommonSeriesSettings,
  Series,
  Reduction,
  ArgumentAxis,
  Label as DevLabel,
  Format,
  ValueAxis,
  Title,
  Legend,
  Export,
  ZoomAndPan,
  Tooltip,
  Crosshair,
  Font,
  Point,
} from 'devextreme-react/chart';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';

// import Breadcrumbs from '../../../components/Breadcrumbs';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

export default function Index() {
  const { user } = useAuth();

  const [transactions, setTransactions] = useState([]);

  const [chartClientAccounts, setChartClientAccounts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user]);

  const closeAccount = () => {
    navigate('/', { replace: true });
  };

  const get_Date = (an_Date) => {
    const ansiDate = new Date(an_Date.getTime() - an_Date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    return ansiDate;
  };

  const past_Date = (anDate, anDays) => {
    let dt = new Date(anDate);
    dt.setDate(dt.getDate() + anDays);
    return dt;
  };

  const anPastDate = past_Date(new Date(), -30);

  const [selStartDate, setSelStartDate] = useState(get_Date(new Date(anPastDate)));
  const [selEndDate, setSelEndDate] = useState(get_Date(new Date()));

  useEffect(() => {
    const fetchTransactions = async () => {
      await HTTP.get(
        `/client/client-transactions/${user.clientid}/${selStartDate}/${selEndDate}/${user.selCard.assetaccountid}/${user.loggedinclientid}/${user.limitedaccounts}`
      )
        .then((response) => {
          setTransactions(response.data);
        })
        .catch((e) => {
          console.log('fetchTransactions', e.message, 2000);
        });

      await HTTP.get(
        `/client/chart-client-account/${user.clientid}/${user.selCard.assetaccountid}/${selStartDate}/${selEndDate}/${user.selCard.svassetid}`
      )
        .then((response1) => {
          setChartClientAccounts(response1.data);
        })
        .catch((e) => {
          console.log('fetchChart', e.message, 2000);
        });
    };

    if (user && user.clientid) {
      fetchTransactions();
    }
  }, [user]);
  const stChartClientAccounts = new ArrayStore({
    key: 'rowid',
    data: chartClientAccounts,
  });

  const dcChartClientAccounts = new DataSource({
    store: stChartClientAccounts,
  });

  const stTransactions = new ArrayStore({
    key: 'id',
    data: transactions,
  });

  const dcTransactions = new DataSource({
    store: stTransactions,
  });

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format;

  const FormatDateString = (anDateString) => {
    const anDate = anDateString
      .slice(0, 16)
      // .replace(/-/g, "/")
      .replace('T', ' ');
    return anDate;
  };

  function showDepositPage() {
    navigate(`/asset/assetbuy`);
  }

  function showTransferPage() {
    navigate(`/asset/assetsell`);
  }

  const saveReportData = () => {
    user.report = {
      userid: user.clientid,
      transactionTypeService: user.selCard.assetaccountid,
      transactionTypeAsset: user.selCard.svassetid,
      startdate: selStartDate,
      enddate: selEndDate,
    };

    navigate('../transaction-print');
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/**
      <Breadcrumbs title='Forex' />
 */}
      <section className='mb-4 mb-lg-5'>
        <Row>
          <Col lg={6} className='mb-4 mb-lg-0'>
            <Card className='h-100'>
              {user && user.selCard && (
                <Card.Header>
                  <h4 className='card-heading'>{user.selCard.servicename}</h4>
                </Card.Header>
              )}
              <Card.Body>
                {user && user.selCard && (
                  <Row>
                    <Col>
                      <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                        <div className='fw-bold'>Account Holder</div>
                        <small className='text-gray-500'>
                          {user.cl_salutation} {user.cl_firstname} {user.cl_lastname}
                        </small>
                      </div>{' '}
                      <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                        <div className='fw-bold'>Wallet</div>
                        <small className='text-gray-500'>{user.selCard.accountvalue}</small>
                      </div>{' '}
                      <div className='text-uppercase flex-shrink-0 me-1 mb-4'>
                        <div className='fw-bold'>Coin</div>
                        <small className='text-gray-500'>{user.selCard.assetname}</small>
                      </div>{' '}
                      <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                        <div className='fw-bold'>In Stock</div>
                        <p className='text-gray-500'>
                          {user.selCard.assetcalcunit}{' '}
                          {formatCurrency(parseFloat(user.selCard.asset_amount).toFixed(2)).replace('EUR', '')}
                        </p>
                      </div>
                    </Col>
                  </Row>
                )}
              </Card.Body>
              <Card.Footer>
                {user && user.selCard && (
                  <Row>
                    <Col md={12} xl={12} className='mb-1'>
                      <div className='float-end'>
                        <Button variant='primary' onClick={showDepositPage}>
                          Buy
                        </Button>{' '}
                        <Button variant='primary' onClick={showTransferPage}>
                          Sell
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Card.Footer>
            </Card>
          </Col>
          <Col lg={6} className='mb-4 mb-lg-0'>
            <Card className='h-100'>
              <Card.Header>
                <h4 className='card-heading'>Activity</h4>
              </Card.Header>
              <Card.Body>
                <Chart height={450} id='chart' dataSource={dcChartClientAccounts}>
                  <Series
                    barWidth={50}
                    valueField='runassets'
                    argumentField='rowid'
                    name='Asset'
                    type='line'
                    color='#ffaa66'
                  />
                </Chart>{' '}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
      {transactions && (
        <section className='mb-4 mb-lg-5'>
          <Card className='h-100'>
            <Card.Header>
              <h4 className='card-heading'>Transactions</h4>
            </Card.Header>
            <Card.Body>
              <DataGrid height={600} dataSource={dcTransactions}>
                <Selection mode='single' />
                <Column dataField='transferdate' caption='Date' dataType='datetime' width={150} />
                <Column dataField='servicename' caption='Service' width={200} />
                <Column dataField='transfertext' caption='Transfer Text' width={300} />
                <Column dataField='operation' caption='Operation' width={80} />
                <Column dataField='assetname' caption='Asset' width={90} />
                <Column
                  dataField='assetamount'
                  caption='Amount'
                  format={{ type: 'fixedPoint', precision: 4 }}
                  width={120}
                />
                <Column dataField='assetcalcunit' caption='Unit' width={100} />
              </DataGrid>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col md={12} xl={12} className='mb-1'>
                  <div className='float-end'>
                    <Button variant='outline-info' onClick={saveReportData}>
                      Print
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </section>
      )}

      <Row className='mb-5'>
        <Col>
          <div className='float-end'>
            <Button variant='outline-secondary' onClick={closeAccount}>
              Close
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
