import React, { useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Modal } from 'react-bootstrap';
import HTTP from '../../../../../api/HTTP';

export default function Execute(props) {
  const { street, streetError, cityname, citynameError, cityplc, cityplcError, country, countryError, user } = props;
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const submitButtonRef = useRef(false);

  const toggleModal = () => {
    if (
      !streetError &&
      street !== '' &&
      !citynameError &&
      cityname !== '' &&
      !cityplcError &&
      cityplc !== '' &&
      !countryError &&
      country !== ''
    ) {
      setShow(!show);
    }
  };

  const changeClientAddress = async () => {
    try {
      const response = await HTTP.post(
        '/client/change-address',
        JSON.stringify({
          mod_data: 10,
          clientid: user.clientid,
          cl_street: street,
          cl_city: cityname,
          cl_plc: cityplc,
          cl_countryofresidence: country,
        })
      );
      const operationresult = response.data[0].mod_client_data;
      if (operationresult === 10) {
        navigate('/home', { replace: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (values) => {
    await changeClientAddress();
    navigate('/account-details');
  };

  return (
    <React.Fragment>
      <Button variant='primary' type='submit' onClick={toggleModal}>
        Save
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Please Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to change the address to</p>
          <Form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <br />
              <p>Street: {street}</p>
              <p>City: {cityname}</p>
              <p>PLC: {cityplc}</p>
              <p>Country: {country}</p>
            </div>
            <Button variant='primary' className='d-none' type='submit' ref={submitButtonRef}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitButtonRef.current.click()}>
            Save
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
