import axios from 'axios';
import tokenService from '../services/TokenService';
import { sleep } from '../utils/sleep';

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const refreshAccessToken = async () => {
  const refreshToken = tokenService.getRefreshToken();

  if (!refreshToken) {
    return;
  }

  try {
    const tokenResponse = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/refresh`,
      {
        refresh_token: refreshToken,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    tokenService.setAccessAndRefreshToken(tokenResponse.data.accessToken, tokenResponse.data.refreshToken);

    return tokenResponse.data.accessToken;
  } catch (err) {
    if (err.response.status === 400) {
      tokenService.removeAll();
      return null;
    }
  }
};

HTTP.interceptors.request.use(
  async (config) => {
    const token = tokenService.getAccessToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (tokenService.getRefreshStatus()) {
        await sleep(3000);

        const accessToken = tokenService.getAccessToken();

        originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;

        return HTTP(originalRequest);
      }

      tokenService.setRefreshStatus();
      const accessToken = await refreshAccessToken();

      originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;

      return HTTP(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default HTTP;
