import React from 'react';
import HTTP from '../../api/HTTP';
import LoadPanel from '../../components/load-panel/LoadPanel';
import { downloadFileFromBlob, downloadFileFromBuffer, getFileExtension } from '../../utils/attach-helper';
import { Button } from 'react-bootstrap';
import { formatDate } from '../../utils/date-helper';
import {
  faFile,
  faFileArchive,
  faFileAudio,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { querystring } from '../../utils/functions';

const MessagesList = React.forwardRef(
  ({ threadInfo, isLoading = false, height = 300, messageslistData, onLoadMore, className }, forwardRef) => {
    const handleDownloadFile = async (fileId, fileName) => {
      HTTP.get(`/inquiry/file-by-id/${fileId}`)
        .then((response) => {
          downloadFileFromBuffer(response.data.fileContent.data, response.data.fileName);
        })
        .catch((e) => {
          console.log('An error occurred while downloading the file', 'error', 2000);
        });
    };

    const getNameById = (id) => {
      const result = [];
      if (threadInfo) {
        const foundPerson = threadInfo.persons?.find((person) => {
          return person.customerId === id;
        });

        if (foundPerson && typeof foundPerson.firstName === 'string') {
          result.push(foundPerson.firstName);
        }

        if (foundPerson && typeof foundPerson.lastName === 'string') {
          result.push(foundPerson.lastName);
        }
      }
      return result.join(' ');
    };

    const getFileIcon = (fileName) => {
      const ext = getFileExtension(fileName);
      return getFontAwesomeIconByExtension(ext);
    };

    const getFontAwesomeIconByExtension = (extension = 'other') => {
      const FILE_EXTENSIONS = {
        pdf: faFilePdf,
        doc: faFileWord,
        docx: faFileWord,
        jpg: faFileImage,
        avi: faFileVideo,
        mp3: faFileAudio,
        zip: faFileArchive,
        rar: faFileArchive,
        other: faFile,
      };
      const foundExtension = FILE_EXTENSIONS[extension];
      if (!foundExtension) {
        return FILE_EXTENSIONS.other;
      }
      return FILE_EXTENSIONS[extension];
    };

    return (
      <>
        {isLoading && <LoadPanel visible={true} />}
        <div ref={forwardRef} style={{ height }} className={`message-list ${className}`}>
          {messageslistData.map((messageData) => (
            <div
              key={messageData.id}
              className={`message-item-wrapper mb-4 ${messageData.customerType === 'SPP_USER' ? 'spp-user' : 'client'}`}
            >
              <div className={'message-date'}>
                {formatDate(messageData.createdAt) + ' ' + getNameById(messageData.customerId)}
              </div>
              <div className={`message-wrapper`}>
                <div>{messageData.message}</div>
                <div className='message-files'>
                  {messageData.files.map((file, index) => (
                    <div key={index} className='d-flex align-items-baseline'>
                      <i>
                        <FontAwesomeIcon color={'#666666'} icon={getFileIcon(file.fileName)} size={'1x'} />
                      </i>
                      <Button
                        onClick={() => handleDownloadFile(file.id, file.fileName)}
                        className='file-item'
                        variant={'link'}
                      >
                        {file.fileName}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
);

export default MessagesList;
