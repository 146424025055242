import React, { useState, useContext, useEffect, useCallback } from "react";
import { useAuth } from "../../../contexts/auth";
import HTTP from "../../../api/HTTP";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Nav,
  InputGroup,
  Card,
  Col,
  Container,
  Row,
  Button,
  Toast,
  ToastContainer,
} from "react-bootstrap";

// import Breadcrumbs from '../../../components/Breadcrumbs';

import LightweightChart from "../../../own-components/LightweightChart/Chart";

import Chart, {
  CommonSeriesSettings,
  Series,
  Reduction,
  ArgumentAxis,
  Label as DevLabel,
  Format,
  ValueAxis,
  Title,
  Legend,
  Export,
  ZoomAndPan,
  Tooltip,
  Crosshair,
  Font,
  Point,
} from "devextreme-react/chart";

import RangeSelector, {
  Size,
  Scale,
  Chart as RsChart,
  ValueAxis as RsValueAxis,
  Series as RsSeries,
  Aggregation as RsAggregation,
  Behavior,
} from "devextreme-react/range-selector";

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import DataGrid, { Column, Selection } from "devextreme-react/data-grid";

// import FXPositionPop from '../../../own-components/fxtrading/FXPositionPop';

import TradingViewState from "../../../own-components/TradingView/TradingViewState";

import Alert from "react-bootstrap/Alert";

const get_Date = (an_Date) => {
  const ansiDate = new Date(
    an_Date.getTime() - an_Date.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];
  return ansiDate;
};

const past_Date = (anDate, anDays) => {
  let dt = new Date(anDate);
  dt.setDate(dt.getDate() + anDays);
  return dt;
};

const TradeViewDialog = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [cardIdent, setCardIdent] = useState();

  const [tradeAccountState, setTradeAccuntState] = useState([]);

  const [tradeOrdersData, setTradeOrdersData] = useState([]);
  const [tradeOrdersPendingData, setTradeOrdersPendingData] = useState([]);
  const [tradeOrdersClosedData, setTradeOrdersClosedData] = useState([]);
  const [tradeOrdersRecordsData, setTradeOrdersRecordsData] = useState([]);

  const [selOrderRowKey, setSelOrderRowKey] = useState({});
  const [currencyPairsData, setCurrencyPairsData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);

  const [posFrxDialogVisible, setPosFrxDialogVisible] = useState(false);
  const [posFrxWindowVisible, setPosFrxWindowVisible] = useState(false);

  const [selSymbol, setSelSymbol] = useState("EUR%2FUSD");

  const [visualRange, setVisualRange] = useState();
  const [pointData, setPointData] = useState({});

  const [showFXPositionPop, setShowFXPositionPop] = useState(false);
  const [showFXClosePop, setShowFXClosePop] = useState(false);

  const [currencySymbols, setCurrencySymbols] = useState([]);
  const [orderRowData, setOrderRowData] = useState({});

  const [lpLongShort, setLpSongShort] = useState([]);
  const [serviceLeverage, setServiceLeverage] = useState([]);

  const [currentSymbol, setCurrentSymbol] = useState({});
  const [chartRangeSeriesData, setChartRangeSeriesData] = useState([]);

  const [showToast, setShowToast] = useState(false);

  const [forexBaseData, setForexBaseData] = useState({});
  const [tradingOrderPositionData, setTradingOrderPositionData] = useState({});

  const [chartResolution, setChartResolution] = useState(1);

  const [chartPeriod, setChartPeriod] = useState(1);

  const [chartType, setChartType] = useState("candlestick");

  const [orderTypes, setOrderTypes] = useState([]);

  const [orderForm, setOrderForm] = useState(1);
  const [pendOrderType, setPendOrderType] = useState(1);
  const [lotUnits, setLotUnits] = useState();
  const [margLeverage, setMargLeverage] = useState(20);
  const [calcMargin, setCalcMargin] = useState();
  const [openAtValue, setOpenAtValue] = useState();
  const [closeAtValue, setCloseAtValue] = useState();

  const [activeNav, setActiveNav] = useState("active");

  const [tradingFXOrdersData, setTradingFXOrdersData] = useState([]);

  const lotAmount = 100000;

  const anTestArray = [{ symbols: "EUR/USD", price: "0.00001" }];

  const [viewTradingChart, setViewTradingChart] = useState("original");
  const [doReload, setDoReload] = useState(false);

  const [alertShow, setAlertShow] = useState(false);

  const [orderType, setOrderType] = useState("Market Order");

  // Fetch Data Functions
  //

  // Use Effects
  //

  useEffect(() => {
    if (!user) {
      navigate("/", { replace: true });
    }
  }, [user]);

  useEffect(() => {
    const saveAssetIdent = () => {
      const identvalue =
        user.selCard.assetaccountid + "-" + user.selCard.svassetid;
      setCardIdent(identvalue);
    };
    if (!user.selCard) {
      navigate("/", { replace: true });
    } else {
      saveAssetIdent();
    }
  }, [user.selCard]);

  useEffect(() => {
    if (user && user.clientid && !showFXPositionPop && !showFXClosePop) {
      fetchTradeAccountState();
      // fetchCurrencySymbols()
      fetchCurrencyPairs();
      fetchTradeOrders();
      fetchTradeOrdersPending();
      fetchTradeOrdersClosed();
      fetchTradeOrdersRecords();
      fetchForexBase();
      fetchOrderTypes();

      const interval = setInterval(() => {
        if (!showFXPositionPop && !showFXClosePop) {
          fetchCurrencyPairsUpdate();
          fetchTradeOrdersUpdate();
          fetchTradeOrdersPendingUpdate();
          fetchTradeOrdersClosedUpdate();
          fetchTradeOrdersRecordsUpdate();
          fetchTradeAccountStateUpdate();
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [user, showFXPositionPop, showFXClosePop, doReload]);

  useEffect(() => {
    if (user && user.clientid) {
      fetchChartRangeSeriesData();
      fetchMChartData();

      const interval = setInterval(() => {
        if (!showFXPositionPop && !showFXClosePop) {
          fetchMChartData();
        }
      }, 1000);

      return () => clearInterval(interval);
    }

    console.log(selSymbol);
  }, [user, selSymbol, chartPeriod, chartResolution]);

  const fetchForexBase = async () => {
    try {
      const response = await HTTP.get(
        `/forex/forex-base/${user.clientid}/${user.selCard.assetaccountid}`
      );
      setForexBaseData(response.data[0]);

      const lvResponse = await HTTP.get(
        `/forex/leverage-list/${response.data[0].extaccountid}`
      );
      setServiceLeverage(lvResponse.data);
    } catch (err) {
      console.log(err);
    }

    fetchLpLongShort();
  };

  const fetchCurrencyPairs = async () => {
    try {
      const response = await HTTP.get(`/forex/fx-grid-pairs`);

      response.data.map((item) =>
        stCurrencyPairsData.push([
          {
            type: "insert",
            data: { symbols: item.symbols, price: item.price },
          },
        ])
      );
    } catch (err) {
      console.log("fetchCurrencyPairs - error", err);
    }
  };

  const fetchCurrencyPairsUpdate = async () => {
    try {
      const response = await HTTP.get(`/forex/fx-grid-pairs`);

      response.data.map((item) => {
        stCurrencyPairsData.push([
          {
            type: "update",
            data: { symbols: item.symbols, price: item.price },
            key: item.symbols,
          },
        ]);
      });
    } catch (err) {
      console.log("fetchCurrencyPairsUpdate-error", err);
    }
  };

  const stCurrencyPairsData = new ArrayStore({
    key: "symbols",
    data: currencyPairsData,
  });

  const dcCurrencyPairsData = new DataSource({
    store: stCurrencyPairsData,
    reshapeOnPush: true,
  });

  const fetchTradeOrdersPending = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-fx-position/${user.clientid}/${
          user.selCard.assetaccountid
        }/${3}`
      );

      response.data.map((item) => {
        stTradeOrdersPendingData.push([
          {
            type: "insert",
            data: item,
          },
        ]);
      });
    } catch (error) {
      console.log("fetchTradeOrdersPending-error", error);
    }
  };

  const fetchTradeOrdersPendingUpdate = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-fx-position/${user.clientid}/${
          user.selCard.assetaccountid
        }/${3}`
      );
      response.data.map((item) => {
        stTradeOrdersPendingData.push([
          {
            type: "update",
            data: {
              cpair_price: item.cpair_price,
              profit_loss: item.profit_loss,
            },
            key: item.orderid,
          },
        ]);
      });
    } catch (error) {
      console.log("fetchTradeOrdersPendingUpdate-error", error);
    }
  };

  const stTradeOrdersPendingData = new ArrayStore({
    key: "orderid",
    data: tradeOrdersPendingData,
  });

  const dcTradeOrdersPendingData = new DataSource({
    store: stTradeOrdersPendingData,
    reshapeOnPush: true,
  });

  const fetchTradeOrdersClosed = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-fx-position/${user.clientid}/${
          user.selCard.assetaccountid
        }/${2}`
      );

      response.data.map((item) => {
        stTradeOrdersClosedData.push([
          {
            type: "insert",
            data: item,
          },
        ]);
      });
    } catch (error) {
      console.log("fetchTradeOrdersClosed-error", error);
    }
  };

  const fetchTradeOrdersClosedUpdate = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-fx-position/${user.clientid}/${
          user.selCard.assetaccountid
        }/${2}`
      );
      response.data.map((item) => {
        stTradeOrdersClosedData.push([
          {
            type: "update",
            data: {
              cpair_price: item.cpair_price,
              profit_loss: item.profit_loss,
            },
            key: item.orderid,
          },
        ]);
      });
    } catch (error) {
      console.log("fetchTradeOrdersClosedUpdate-error", error);
    }
  };

  const stTradeOrdersClosedData = new ArrayStore({
    key: "orderid",
    data: tradeOrdersClosedData,
  });

  const dcTradeOrdersClosedData = new DataSource({
    store: stTradeOrdersClosedData,
    reshapeOnPush: true,
  });

  const fetchTradeOrdersRecords = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-fx-records/${user.clientid}/${user.selCard.assetaccountid}`
      );

      response.data.map((item) => {
        stTradeOrdersRecordsData.push([
          {
            type: "insert",
            data: item,
          },
        ]);
      });
    } catch (error) {
      console.log("fetchTradeOrdersRecords-error", error);
    }
  };

  const fetchTradeOrdersRecordsUpdate = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-fx-records/${user.clientid}/${user.selCard.assetaccountid}`
      );
      response.data.map((item) => {
        stTradeOrdersRecordsData.push([
          {
            type: "update",
            data: {
              cpair_price: item.cpair_price,
              profit_loss: item.profit_loss,
            },
            key: item.orderid,
          },
        ]);
      });
    } catch (error) {
      console.log("fetchTradeOrdersRecordsUpdate-error", error);
    }
  };

  const stTradeOrdersRecordsData = new ArrayStore({
    key: "orderid",
    data: tradeOrdersRecordsData,
  });

  const dcTradeOrdersRecordsData = new DataSource({
    store: stTradeOrdersRecordsData,
    reshapeOnPush: true,
  });

  const fetchTradeOrders = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-positions/${user.clientid}/${user.selCard.assetaccountid}`
      );

      // stTradeOrdersData.clear();

      response.data.map((item) => {
        stTradeOrdersData.push([
          {
            type: "insert",
            data: item,
          },
        ]);
      });
    } catch (error) {
      console.log("fetchTradeOrders-error", error);
    }
  };

  const fetchTradeOrdersUpdate = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-positions/${user.clientid}/${user.selCard.assetaccountid}`
      );
      response.data.map((item) => {
        stTradeOrdersData.push([
          {
            type: "update",
            data: {
              cpair_price: item.cpair_price,
              profit_loss: item.profit_loss,
            },
            key: item.orderid,
          },
        ]);
      });
    } catch (error) {
      console.log("fetchTradeOrdersUpdate-error", error);
    }
  };

  const stTradeOrdersData = new ArrayStore({
    key: "orderid",
    data: tradeOrdersData,
  });

  const dcTradeOrdersData = new DataSource({
    store: stTradeOrdersData,
    reshapeOnPush: true,
  });

  const deleteOrderinStore = (anOrderid) => {
    console.log(1111, anOrderid);
    stTradeOrdersData.push([
      {
        type: "remove",
        key: anOrderid,
      },
    ]);
  };

  const fetchTradeAccountState = async () => {
    try {
      const response = await HTTP.get(
        `/forex/account-state/${user.clientid}/${user.selCard.assetaccountid}`
      );

      stTradeAccountState.push([
        {
          type: "insert",
          data: response.data[0],
        },
      ]);
    } catch (error) {
      console.log("fetchTradeAccountState-error", error);
    }
  };

  const fetchTradeAccountStateUpdate = async () => {
    try {
      const response = await HTTP.get(
        `/forex/account-state/${user.clientid}/${user.selCard.assetaccountid}`
      );

      stTradeAccountState.push([
        {
          type: "update",
          data: response.data[0],
          key: response.data[0].trade_accountid,
        },
      ]);
    } catch (error) {
      console.log("fetchTradeAccountStateUpdate-error", error);
    }
  };

  const stTradeAccountState = new ArrayStore({
    key: "trade_accountid",
    data: tradeAccountState,
  });

  const adDate = (anData) =>
    anData.map((p) => {
      p.date = new Date(p.bucket);
      return p;
    });

  const fetchMChartData = async () => {
    try {
      const response = await HTTP.get(
        `/forex/fx-m-chart-data/${selSymbol}/${chartPeriod}/${chartResolution}`
      );
      const addedArray = adDate(response.data);

      setSeriesData(addedArray);
    } catch (error) {
      console.log("fetchMChartData-error", error);
    }
  };

  const stChartData = new ArrayStore({
    key: ["date"],
    data: seriesData,
  });

  const dcChartData = new DataSource({
    store: stChartData,
    paginate: false,
    sort: "date",
    reshapeOnPush: true,
  });

  const fetchChartRangeSeriesData = async () => {
    try {
      const response = await HTTP.get(
        `/forex/fx-m-chart-data/${selSymbol}/${chartPeriod}/${chartResolution}`
      );
      const addedArray = adDate(response.data);
      setChartRangeSeriesData(addedArray);
    } catch (error) {
      console.log("fetchChartRangeSeriesData-error", error);
    }
  };

  const fetchLpLongShort = async () => {
    try {
      const response = await HTTP.get("/base/get-lookup-values/51");
      setLpSongShort(response.data);
    } catch (error) {
      console.log("fetchLpLongShort-error", error);
    }
  };

  const fetchTradingOrderPosition = async () => {
    try {
      const response = await HTTP.get(
        `/forex/trading-order-position/${user.clientid}/${user.selCard.assetaccountid}/${selOrderRowKey}`
      );
      setTradingOrderPositionData(response.data[0]);
    } catch (error) {
      console.log("fetchTradingOrderPosition-error", error);
    }
  };

  const fetchOrderTypes = async () => {
    try {
      const response = await HTTP.get("/base/get-lookup-values/54");
      setOrderTypes(response.data);
    } catch (err) {}
  };

  const formatCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "code",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format;

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatterDig = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });

  //  Workflow Functions
  //
  const cancelExecution = () => {
    navigate("/", { replace: true });
  };

  function showTradeDepositPage() {
    navigate(`/asset/tradedeposit`);
  }

  function showTradeWithdrawPage() {
    navigate(`/asset/tradewithdraw`);
  }

  const openFXClosePop = async () => {
    if (Object.keys(orderRowData).length === 0) {
      setShowToast(true);
      console.log("Please select a Position first");
      return;
    }

    //  await fetchTradingOrderPosition();

    await close_fx_position();
    setDoReload(!doReload);
  };

  const hideFXPostitionPop = () => {
    setShowFXPositionPop(false);
  };

  const hideFXClosePop = (visibleValue, closeValue) => {
    setShowFXClosePop(false);
    if (closeValue === 5) {
      setOrderRowData({});
    }
    setDoReload(!doReload);
  };

  const getCurrentPrice = (aSymbol) => {
    var result = currencyPairsData.filter((obj) => {
      return obj.symbols === aSymbol;
    });
    return result;
  };

  const calculateMargin = (aLotUnits, aMargLeverage) => {
    const anMargin =
      (parseFloat(aLotUnits) * lotAmount) / parseInt(aMargLeverage);
    setCalcMargin(anMargin);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    // console.log(1234, "Submit order")
    if (orderForm === 1) {
      createMarketOrder();
    } else {
      createPendingOrder();
    }
    // setDoReload(!doReload);
  };

  async function createMarketOrder() {
    const pair_symbol = selSymbol.replace("%2F", "/");
    const pair_price = getCurrentPrice(selSymbol.replace("%2F", "/"));
    //  console.log(pair_price[0].price)

    console.log(1);
    const response = await HTTP.post(
      "/forex/create-fx-position",
      JSON.stringify({
        clientid: user.clientid,
        trade_accountid: user.selCard.assetaccountid,
        cpair: pair_symbol,
        cpair_ask: pair_price[0].price,
        long_short: pendOrderType,
        closeat: closeAtValue ? closeAtValue : -1,
        cpair_units: lotUnits,
        margin: calcMargin,
        margin_leverage: margLeverage,
      })
    );
    console.log(response.data);
    setDoReload(!doReload);
  }

  const createPendingOrder = async () => {
    const response = await HTTP.post(
      "/forex/create_fx_pending_position",
      JSON.stringify({
        clientid: user.clientid,
        trade_accountid: user.selCard.assetaccountid,
        cpair: selSymbol.replace("%2F", "/"),
        ordertype: pendOrderType,
        lots: lotUnits,
        margin_leverage: margLeverage,
        margin: calcMargin,
        openat: openAtValue,
        closeat: closeAtValue ? closeAtValue : -1,
      })
    );
    console.log(response.data);
    setDoReload(!doReload);
  };

  const close_fx_position = async () => {
    // console.log(1111555, orderRowData);
    // console.log(222222, forexBaseData);
    // console.log(33333, tradingOrderPositionData)

    try {
      const response = await HTTP.post(
        "/forex/close_fx_position",
        JSON.stringify({
          clientid: user.clientid,
          trade_accountid: user.selCard.assetaccountid,
          orderid: orderRowData.orderid,
          close_price: orderRowData.cpair_price,
          ext_accountid: forexBaseData.extaccountid,
        })
      );
      // console.log(response.data);
      // console.log(1234);
      deleteOrderinStore(orderRowData.orderid);
      // console.log(5678);
    } catch (error) {
      console.log("close_fx_position Error", error);
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="px-lg-4 px-xl-5">
        {/* <Breadcrumbs title='Forex Trading' /> */}

        <section>
          <TradingViewState
            user={user}
            tradeAccountState={tradeAccountState[0]}
            showTradeDepositPage={showTradeDepositPage}
            showTradeWithdrawPage={showTradeWithdrawPage}
            formatter={formatter}
            formatCurrency={formatCurrency}
          />
        </section>
        <section>
          <Row>
            <Col lg={3} className="mb-4">
              <Card className="h-100">
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="text-uppercase flex-shrink-0 me-1 mb-3">
                        <div className="fw-bold">Currency Pairs</div>
                      </div>{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DataGrid
                        height={500}
                        dataSource={dcCurrencyPairsData}
                        repaintChangesOnly={true}
                        onSelectionChanged={(e) => {
                          setSelSymbol(
                            e.selectedRowsData[0].symbols.replace("/", "%2F")
                          );
                        }}
                      >
                        <Selection mode="single" />
                        <Column dataField="symbols" caption="Symbol" />
                        <Column
                          dataField="price"
                          format={{ type: "fixedPoint", precision: 4 }}
                        />
                      </DataGrid>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={9} className="mb-4">
              <Card className="h-100">
                <Card.Body>
                  <Nav
                    className="mb-3"
                    variant="tabs"
                    defaultActiveKey="original"
                    onSelect={(e) => {
                      // console.log(e)
                      setViewTradingChart(e);
                    }}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="original">Original</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="lightweight">Lightweight</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {viewTradingChart === "lightweight" && (
                    <section>
                      <Row className="mb-3">
                        <Col md={2}>
                          <div className="text-uppercase flex-shrink-0 me-1 mb-3">
                            <Form.Select
                              defaultValue={1}
                              onChange={(e) => {
                                setChartPeriod(e.target.value);
                              }}
                            >
                              <option value="1">Last 1 Day</option>
                              <option value="2">Last 2 Days</option>
                              <option value="3">Last 3 Days</option>
                              <option value="4">Last 5 Days</option>
                              <option value="6">Last 6 Days</option>
                              <option value="7">Last 7 Days</option>
                              <option value="14">Last 14 Days</option>
                              <option value="21">Last 21 Days</option>
                              <option value="30">Last 30 Days</option>
                              <option value="60">Last 60 Days</option>
                            </Form.Select>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="text-uppercase flex-shrink-0 me-1 mb-3">
                            <Form.Select
                              defaultValue={1}
                              onChange={(e) => {
                                setChartResolution(e.target.value);
                              }}
                            >
                              <option value="1">1 Min</option>
                              <option value="3">3 Min</option>
                              <option value="5">5 Min</option>
                              <option value="15">15 Min</option>
                              <option value="45">45 Min</option>
                              <option value="60">1 hour</option>
                              <option value="120">2 hour</option>
                              <option value="180">3 hour</option>
                              <option value="240">4 hour</option>
                            </Form.Select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <LightweightChart
                          selSymbol={selSymbol}
                          chartPeriod={chartPeriod}
                          chartResolution={chartResolution}
                        />
                      </Row>
                    </section>
                  )}
                  {viewTradingChart === "original" && (
                    <section>
                      <>
                        <Row className="mb-3">
                          <Col md={2}>
                            <div className="text-uppercase flex-shrink-0 me-1 mb-3">
                              <Form.Select
                                defaultValue={"candlestick"}
                                onChange={(e) => {
                                  setChartType(e.target.value);
                                }}
                              >
                                <option value="candlestick">Candle</option>
                                <option value="stock">Stock</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="text-uppercase flex-shrink-0 me-1 mb-3">
                              <Form.Select
                                defaultValue={1}
                                onChange={(e) => {
                                  setChartPeriod(e.target.value);
                                }}
                              >
                                <option value="1">Last 1 Day</option>
                                <option value="2">Last 2 Days</option>
                                <option value="3">Last 3 Days</option>
                                <option value="4">Last 5 Days</option>
                                <option value="6">Last 6 Days</option>
                                <option value="7">Last 7 Days</option>
                                <option value="14">Last 14 Days</option>
                                <option value="21">Last 21 Days</option>
                                <option value="30">Last 30 Days</option>
                                <option value="60">Last 60 Days</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="text-uppercase flex-shrink-0 me-1 mb-3">
                              <Form.Select
                                defaultValue={1}
                                onChange={(e) => {
                                  setChartResolution(e.target.value);
                                }}
                              >
                                <option value="1">1 Min</option>
                                <option value="3">3 Min</option>
                                <option value="5">5 Min</option>
                                <option value="15">15 Min</option>
                                <option value="45">45 Min</option>
                                <option value="60">1 hour</option>
                                <option value="120">2 hour</option>
                                <option value="180">3 hour</option>
                                <option value="240">4 hour</option>
                              </Form.Select>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col lg={2} />
                          <Col lg={8}>
                            <Row>
                              <Col lg={3}>
                                {pointData.bucket && (
                                  <div className="text-gray-500">
                                    Time:{" "}
                                    {
                                      new Date(pointData.bucket)
                                        .toISOString()
                                        .split("T")[1]
                                    }
                                  </div>
                                )}
                              </Col>
                              <Col lg={2}>
                                {pointData.open && (
                                  <div className="text-gray-500">
                                    O: {formatterDig.format(pointData.open)}
                                  </div>
                                )}
                              </Col>
                              <Col lg={2}>
                                {pointData.high && (
                                  <div className="text-gray-500">
                                    H: {formatterDig.format(pointData.high)}
                                  </div>
                                )}
                              </Col>
                              <Col lg={2}>
                                {pointData.high && (
                                  <div className="text-gray-500">
                                    L: {formatterDig.format(pointData.low)}
                                  </div>
                                )}
                              </Col>
                              <Col lg={2}>
                                {pointData.open && (
                                  <div className="text-gray-500">
                                    C: {formatterDig.format(pointData.close)}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Chart
                              dataSource={dcChartData}
                              onPointHoverChanged={(e) => {
                                //  console.log(e.target.data)
                                setPointData(e.target.data);
                                // e.target.showTooltip();
                              }}
                            >
                              <CommonSeriesSettings
                                argumentField="date"
                                type={chartType}
                              >
                                {" "}
                                <Point hoverMode="allArgumentPoints" />{" "}
                              </CommonSeriesSettings>
                              <Series
                                name={selSymbol.replace("%2F", "/")}
                                openValueField="open"
                                highValueField="high"
                                lowValueField="low"
                                closeValueField="close"
                                color="green"
                                innerColor="green"
                              >
                                <Reduction color="red" />
                              </Series>
                              <ArgumentAxis
                                visualRange={visualRange}
                                valueMarginsEnabled={false}
                                argumentType="datetime"
                              >
                                <DevLabel format="shortDateShortTime" />
                              </ArgumentAxis>
                              <ValueAxis tickInterval={1}>
                                <DevLabel>
                                  <Format precision={4} type="fixedPoint" />
                                </DevLabel>
                              </ValueAxis>
                              <ZoomAndPan
                                valueAxis="both"
                                argumentAxis="both"
                                dragToZoom={true}
                                allowMouseWheel={true}
                                panKey="shift"
                              />
                              <Crosshair
                                enabled={true}
                                color="#949494"
                                width={1}
                                dashStyle="dot"
                              >
                                <DevLabel
                                  visible={true}
                                  backgroundColor="#949494"
                                >
                                  <Font color="#fff" size={12} />
                                </DevLabel>
                              </Crosshair>
                              <Tooltip enabled={false} />
                            </Chart>
                            <RangeSelector
                              dataSource={chartRangeSeriesData}
                              onValueChanged={(e) => {
                                setVisualRange(e.value);
                              }}
                            >
                              <Size height={100} />
                              <RsChart>
                                <RsValueAxis valueType="numeric" />
                                <RsSeries
                                  type="line"
                                  valueField="open"
                                  argumentField="date"
                                >
                                  <RsAggregation enabled="true" />
                                </RsSeries>
                              </RsChart>
                              <Scale
                                placeholderHeight={20}
                                minorTickInterval="minute"
                                tickInterval="minute"
                                valueType="datetime"
                                aggregationInterval="hour"
                              />
                              <Behavior
                                snapToTicks={false}
                                callValueChanged="onMoving"
                              />
                            </RangeSelector>
                          </Col>{" "}
                        </Row>
                      </>
                    </section>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
        <section>
          <Row>
            <Col lg={12} className="mb-4">
              <Card className="h-100">
                <Card.Body>
                  <Form
                    onSubmit={formSubmitHandler}
                    onInput={(e) => {
                      switch (e.target.id) {
                        case "orderform":
                          setOrderForm(e.target.value);
                          break;
                        case "ordertype":
                          setPendOrderType(e.target.value);
                          break;
                        case "Lot":
                          setLotUnits(e.target.value);
                          calculateMargin(e.target.value, margLeverage);
                          break;
                        case "Leverage":
                          setMargLeverage(e.target.value);
                          calculateMargin(lotUnits, e.target.value);
                          break;
                        case "openat":
                          setOpenAtValue(e.target.value);
                          break;
                        case "closeat":
                          setCloseAtValue(e.target.value);
                          break;
                      }
                    }}
                  >
                    <Row className="mb-3">
                      <Col md={3}>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={12} controlId="orderform">
                            <InputGroup>
                              <InputGroup.Text id="inputGroupPrepend">
                                {selSymbol.replace("%2F", "/")}
                              </InputGroup.Text>
                              <Form.Select
                                defaultValue={1}
                                onChange={(e) => {
                                  setOrderType(
                                    e.target.selectedOptions[0].text
                                  );
                                  // console.log(e.target.selectedOptions[0].text)
                                }}
                              >
                                <option value={1}>Market Order</option>
                                <option value={2}>Pending Order</option>
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col md={9}>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={3} controlId="ordertype">
                            <InputGroup>
                              <InputGroup.Text id="inputGroupPrepend">
                                Type
                              </InputGroup.Text>
                              <Form.Select
                                defaultValue={1}
                                onChange={(e) => {
                                  //  setChartType(e.target.value)
                                }}
                              >
                                <option value={1}>
                                  {orderForm == 1
                                    ? "Buy/Long"
                                    : "Buy/Long Limit"}
                                </option>
                                <option value={2}>
                                  {orderForm == 1
                                    ? "Sell/Short"
                                    : "Sell/Short Limit"}
                                </option>
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>

                          <Form.Group as={Col} md={2} controlId="Lot">
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Lot
                              </InputGroup.Text>
                              <Form.Control
                                required
                                as="input"
                                placeholder="_._"
                                defaultValue=""
                              />
                            </InputGroup>
                          </Form.Group>

                          <Form.Group as={Col} md={3} controlId="Leverage">
                            <InputGroup>
                              <InputGroup.Text id="inputGroupPrepend">
                                Leverage
                              </InputGroup.Text>
                              <Form.Select
                                defaultValue={20}
                                onChange={(e) => {
                                  //  setChartType(e.target.value)
                                }}
                              >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>

                          <Col md={3}>
                            <div className="mt-1">Margin: {calcMargin}</div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={3} />
                      {orderType === "Market Order" && <Col md={3} />}
                      {orderType === "Pending Order" && (
                        <>
                          <Form.Group as={Col} md="3" controlId="openat">
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                open at
                              </InputGroup.Text>
                              <Form.Control
                                required={orderForm == 1 ? false : true}
                                as="input"
                                type="text"
                                placeholder="_.____"
                                defaultValue=""
                              />
                            </InputGroup>
                          </Form.Group>
                        </>
                      )}

                      <Form.Group as={Col} md="3" controlId="closeat">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            close at
                          </InputGroup.Text>
                          <Form.Control
                            as="input"
                            type="text"
                            placeholder="_.____"
                            aria-describedby="inputGroupPrepend"
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group as={Col} md="2" className="d-grid">
                        <Button type="submit">Create</Button>
                      </Form.Group>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
        <section>
          <Row>
            <Col lg={12} className="mb-4">
              <Card className="h-100">
                <Card.Body>
                  <Nav
                    variant="tabs"
                    defaultActiveKey="active"
                    onSelect={(e) => {
                      //  console.log(e)
                      setActiveNav(e);
                    }}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="active">Active Positions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="pending">Pending Positions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="closed">Closed Positions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="traderecords">Trade Records</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {activeNav === "active" && (
                    <section>
                      <Row className="mt-4">
                        <Col lg={1}>
                          <Row className="mb-1">
                            <Col className="d-grid">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={openFXClosePop}
                              >
                                Close
                              </Button>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-grid"></Col>
                          </Row>
                        </Col>
                        <Col lg={11}>
                          <DataGrid
                            height={400}
                            dataSource={dcTradeOrdersData}
                            repaintChangesOnly={true}
                            onSelectionChanged={(e) => {
                              setSelOrderRowKey(e.selectedRowKeys[0]);
                              setOrderRowData(e.selectedRowsData[0]);
                              setShowToast(false);
                            }}
                          >
                            <Selection mode="single" selectAllMode="page" />
                            <Column
                              dataField="orderid"
                              caption="ID"
                              width={50}
                            />
                            <Column
                              dataField="cpair"
                              caption="Currency Pair"
                              width={150}
                            />
                            <Column
                              dataField="cpair_entered"
                              caption="Start Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="long_short_txt"
                              caption="Long/Short"
                              width={100}
                            />
                            <Column
                              dataField="cpair_units"
                              caption="Lots"
                              width={50}
                            />
                            <Column
                              dataField="margin_leverage"
                              caption="Leverage"
                              width={100}
                            />
                            <Column
                              dataField="margin"
                              caption="Margin"
                              width={100}
                            />
                            <Column
                              dataField="long_short_target"
                              caption="Target Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="cpair_price"
                              caption="Current Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="profit_loss"
                              caption="Profit/Loss"
                              dataType="number"
                              format={{ type: "fixedPoint", precision: 2 }}
                              width={120}
                            />
                          </DataGrid>
                        </Col>
                      </Row>
                      <Row>
                        <Alert
                          show={alertShow}
                          variant="success"
                          onClose={() => setAlertShow(false)}
                          dismissible
                        >
                          <Alert.Heading>Order Closed</Alert.Heading>
                          <p>
                            It can take a moment until the recod is removed from
                            the active ones
                          </p>
                        </Alert>
                      </Row>
                    </section>
                  )}
                  {activeNav === "pending" && (
                    <section>
                      <Row className="mt-4">
                        <Col lg={1}>
                          <Row className="mb-1">
                            <Col className="d-grid">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={openFXClosePop}
                              >
                                Open
                              </Button>{" "}
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col className="d-grid">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={openFXClosePop}
                              >
                                Remove
                              </Button>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-grid"></Col>
                          </Row>
                        </Col>
                        <Col lg={11}>
                          <DataGrid
                            dataSource={dcTradeOrdersPendingData}
                            repaintChangesOnly={true}
                            onSelectionChanged={(e) => {
                              setSelOrderRowKey(e.selectedRowKeys[0]);
                              setOrderRowData(e.selectedRowsData[0]);
                              setShowToast(false);
                            }}
                          >
                            <Selection mode="single" selectAllMode="page" />
                            <Column
                              dataField="orderid"
                              caption="ID"
                              width={50}
                            />
                            <Column
                              dataField="cpair"
                              caption="Currency Pair"
                              width={150}
                            />
                            <Column
                              dataField="cpair_entered"
                              caption="Start Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="long_short_txt"
                              caption="Long/Short"
                              width={100}
                            />
                            <Column
                              dataField="cpair_units"
                              caption="Lots"
                              width={50}
                            />
                            <Column
                              dataField="margin_leverage"
                              caption="Leverage"
                              width={100}
                            />
                            <Column
                              dataField="margin"
                              caption="Margin"
                              width={100}
                            />
                            <Column
                              dataField="long_short_target"
                              caption="Target Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="cpair_price"
                              caption="Current Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="profit_loss"
                              caption="Profit/Loss"
                              dataType="number"
                              format={{ type: "fixedPoint", precision: 2 }}
                              width={120}
                            />
                          </DataGrid>
                        </Col>
                      </Row>
                    </section>
                  )}
                  {activeNav === "closed" && (
                    <section>
                      <Row className="mt-4">
                        <Col lg={1}>
                          {/* <Row className="mb-1">
                            <Col className="d-grid">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={openFXClosePop}
                              >
                                Open
                              </Button>{" "}
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col className="d-grid">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={openFXClosePop}
                              >
                                Archive
                              </Button>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-grid"></Col>
                          </Row> */}
                        </Col>
                        <Col lg={11}>
                          <DataGrid
                            dataSource={dcTradeOrdersClosedData}
                            repaintChangesOnly={true}
                            onSelectionChanged={(e) => {
                              setSelOrderRowKey(e.selectedRowKeys[0]);
                              setOrderRowData(e.selectedRowsData[0]);
                              setShowToast(false);
                            }}
                          >
                            <Selection mode="single" selectAllMode="page" />
                            <Column
                              dataField="orderid"
                              caption="ID"
                              width={50}
                            />
                            <Column
                              dataField="cpair"
                              caption="Currency Pair"
                              width={150}
                            />
                            <Column
                              dataField="cpair_entered"
                              caption="Start Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="long_short_txt"
                              caption="Long/Short"
                              width={100}
                            />
                            <Column
                              dataField="cpair_units"
                              caption="Lots"
                              width={50}
                            />
                            <Column
                              dataField="margin_leverage"
                              caption="Leverage"
                              width={100}
                            />
                            <Column
                              dataField="margin"
                              caption="Margin"
                              width={100}
                            />
                            <Column
                              dataField="long_short_target"
                              caption="Target Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="cpair_price"
                              caption="Current Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="profit_loss"
                              caption="Profit/Loss"
                              dataType="number"
                              format={{ type: "fixedPoint", precision: 2 }}
                              width={120}
                            />
                          </DataGrid>
                        </Col>
                      </Row>
                    </section>
                  )}
                  {activeNav === "traderecords" && (
                    <section>
                      <Row className="mt-4">
                        <Col lg={1}>
                          {/* <Row className="mb-1">
                            <Col className="d-grid">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={openFXClosePop}
                              >
                                Open
                              </Button>{" "}
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col className="d-grid">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={openFXClosePop}
                              >
                                Remove
                              </Button>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-grid"></Col>
                          </Row> */}
                        </Col>
                        <Col lg={11}>
                          <DataGrid
                            dataSource={dcTradeOrdersRecordsData}
                            repaintChangesOnly={true}
                            onSelectionChanged={(e) => {
                              setSelOrderRowKey(e.selectedRowKeys[0]);
                              setOrderRowData(e.selectedRowsData[0]);
                              setShowToast(false);
                            }}
                          >
                            <Selection mode="single" selectAllMode="page" />
                            <Column
                              dataField="orderid"
                              caption="ID"
                              width={50}
                            />
                            <Column
                              dataField="cpair"
                              caption="Currency Pair"
                              width={150}
                            />
                            <Column
                              dataField="cpair_entered"
                              caption="Start Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="long_short"
                              caption="Long/Short"
                              width={100}
                            />
                            <Column
                              dataField="order_units"
                              caption="Lots"
                              width={50}
                            />
                            <Column
                              dataField="leverage"
                              caption="Leverage"
                              width={100}
                            />
                            {/* <Column
                              dataField="cpair_ask"
                              caption="Ask Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="cpair_target"
                              caption="Target Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            /> */}
                            <Column
                              dataField="cpair_close_ask"
                              caption="Close Ask Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="cpair_close_done"
                              caption="Close Done Price"
                              format={{ type: "fixedPoint", precision: 4 }}
                              width={100}
                            />
                            <Column
                              dataField="trade_profit_loss"
                              caption="Profit/Loss"
                              dataType="number"
                              format={{ type: "fixedPoint", precision: 2 }}
                              width={120}
                            />
                          </DataGrid>
                        </Col>
                      </Row>
                    </section>
                  )}

                  <ToastContainer>
                    <Toast show={showToast} bg="warning">
                      <Toast.Body>Please select a Position</Toast.Body>
                    </Toast>
                  </ToastContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
        <section>
          <Row>
            <div className="mb-3">
              <div className="float-end">
                <Button variant="secondary" onClick={cancelExecution}>
                  Close
                </Button>{" "}
              </div>
            </div>
          </Row>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default TradeViewDialog;
