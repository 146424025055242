import { Container, NavLink } from "react-bootstrap";
import CustomButton from "../../components/ui/CustomButton";
import { useNavigate } from "react-router-dom";

const Footer = (props) => {
  const {
    activeSection,
    handleNavLink,
    dark,
  } = props;

  const navigate = useNavigate();

  const redirectToPolicy = () => {
    navigate('/policy');
  }

  return (
    <div
      className="landing-footer overflow-hidden"
      style={{
        backgroundColor: dark ? '#131111' : '#171616',
      }}
    >
      <Container className="position-relative d-flex flex-column flex-lg-row">
        <img
          src="/img/footer-stroke.svg"
          className="d-none d-lg-block w-100 position-absolute"
          style={{
            top: '160px',
            right: '265px',
            rotate: '-10deg'
          }}
        />
        <img
          src="/img/paper-plane.svg"
          className="d-none d-lg-block position-absolute"
          style={{
            top: '30px',
            right: '210px'
          }}
        />
        <div
          className="d-flex flex-column align-items-center align-items-lg-start me-lg-auto m-lg-0 m-auto"
        >
          <div
            style={{
              color: '#98A1BE',
              fontSize: '24px',
              lineHeight: '29px',
              textAlign: 'left'
            }}
          >
            Navigation
          </div>
          <div
            className="footer-navigation mb-4 mb-lg-0 flex-column flex-md-row align-items-center"
          >
            <NavLink
              className={activeSection === 'home' ? 'selected' : ''}
              href="#home"
              data-name="home"
              onClick={handleNavLink}
            >
              Home
            </NavLink>
            <NavLink
              className={activeSection === 'products' ? 'selected' : ''}
              href="#products"
              data-name="products"
              onClick={handleNavLink}
            >
              Products
            </NavLink>
            <NavLink
              className={activeSection === 'banking' ? 'selected' : ''}
              href="#banking"
              data-name="banking"
              onClick={handleNavLink}
            >
              Banking
            </NavLink>
            <NavLink
              className={activeSection === 'aiclub' ? 'selected' : ''}
              href="#aiclub"
              data-name="aiclub"
              onClick={handleNavLink}
            >
              AI Club
            </NavLink>
            <NavLink
              className={activeSection === 'whyus' ? 'selected' : ''}
              href="#whyus"
              data-name="whyus"
              onClick={handleNavLink}
            >
              Why Us
            </NavLink>
            <NavLink
              className={activeSection === 'experts' ? 'selected' : ''}
              href="#experts"
              data-name="experts"
              onClick={handleNavLink}
            >
              Experts
            </NavLink>
          </div>
          <img
            src="/img/logo-v4.png"
            className="footer-logo fmt"
          />
          <div
            style={{
              marginTop: '10px',
              fontSize: '16px',
              lineHeight: '19px',
              color: '#FFFFFF',
            }}
          >
            All Rights Reserved
          </div>
        </div>
        <div
          className="d-flex flex-column align-items-lg-end align-items-center"
        >
          <CustomButton
            variant="main-dark"
            size="normal"
            style={{
              marginTop: '25px'
            }}
            className="d-none d-lg-block"
          >
            Join Watchlist
          </CustomButton>
          <div
            // style={{
            //   marginTop: '113px'
            // }}
            className="fmt"
          >
            <a
              style={{
                fontSize: '18px',
                lineHeight: '21px',
                color: '#FFFFFF',
                marginRight: '28px',
                cursor: 'pointer'
              }}
              onClick={redirectToPolicy}
            >
              Cookies policy
            </a>
            <a
              style={{
                fontSize: '18px',
                lineHeight: '21px',
                color: '#FFFFFF',
              }}
            >
              Terms of Use
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Footer;