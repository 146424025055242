import React, { useState, useEffect } from 'react';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/pro-thin-svg-icons';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../api/HTTP';

const InquiriesNavItem = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const [alertCount, setAlertCount] = useState(0);

  const openChat = () => {
    navigate('/chat');
  };

  useEffect(() => {
    if (user && user.clientid) {
      fetchChatRequest();

      const interval = setInterval(() => {
        if (user && user.clientid) {
          fetchChatRequest();
        }
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [user]);

  const fetchChatRequest = async () => {
    await HTTP.get(`/alerts/chat-requests/${user.clientid}`)
      .then((response) => {
         setAlertCount(response.data[0].requests);
      })
      .catch((e) => {
        console.log('fetchChatRequest', e.message);
      });
  };


  return (
    <Dropdown as={NavItem} className='me-2 me-lg-3' align='end'>
      <Dropdown.Toggle
        as={NavLink}
        className='text-gray-400 px-1 nav-link-icon'
        id='inquiries'
        title='Chat'
        onClick={openChat}
      >
        <FontAwesomeIcon icon={faMessage} size='xl' color='black' />

        {alertCount > 0 && (
          <span className='notification-badge notification-badge-number bg-primary'>{alertCount}</span>
        )}
      </Dropdown.Toggle>
    </Dropdown>
  );
};

export default InquiriesNavItem;
