import React from 'react';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopesBulk } from '@fortawesome/pro-thin-svg-icons';
import { useNavigate } from 'react-router-dom';

const CommunicateNav = () => {
  const navigate = useNavigate();

  const openCommPage = () => {
    navigate('/contactus');
  };

  return (
    <Dropdown as={NavItem} className='me-2 me-lg-3' align='end'>
      <Dropdown.Toggle as={NavLink} className=' px-1 nav-link-icon' title='Contact' onClick={openCommPage}>
        <FontAwesomeIcon icon={faEnvelopesBulk} size='xl' color='blue' />
      </Dropdown.Toggle>
    </Dropdown>
  );
};

export default CommunicateNav;
