import React from 'react';

import { Navbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import AlertNotifications from '../alert-system/AlertNotifications';
import Notifications from './Notifications';
import InquiriesNavNewMessages from '../../pages/inquiries/InquiriesNavNewMessages';
import UserMenu from './UserMenu';
import Search from './Search';
import InquiriesNavContact from '../../pages/inquiries/InquiriesNavContact';
import InquiriesNavInquiries from '../../pages/inquiries/InquiriesNavInquiries';
import ChatNav from './ChatNav';

export default function Header({ imgSrc, imgAlt, imgWidth, imgHeight }) {
  return (
    <React.Fragment>
      <header className='header'>
        <Navbar bg='white' expand='lg' variant={false} className='px-4 py-2 shadow'>

          <Navbar.Brand>
            {imgSrc && <Image src={imgSrc} alt={imgAlt} width={imgWidth} height={imgHeight} />}
          </Navbar.Brand>

          <div className='ms-auto d-flex align-items-center mb-0'>
            {/*<ChatNav />*/}
            {/*<InquiriesNavContact />*/}
            {/* <InquiriesNavItem />*/}
            {/*<AlertNotifications />*/}
            {/*<InquiriesNavNewMessages />*/}
            {/*<AlertNotifications />*}
            {/* <InquiriesNavInquiries /> */}
            <UserMenu />
          </div>
        </Navbar>
      </header>
    </React.Fragment>
  );
}
