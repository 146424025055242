import { Col, Container, Row } from "react-bootstrap";

const WhyUsSection = (props) => {
  return (
    <div className="whyus-section" {...props} >
      <Container>
        <Row className="gx-5 gy-5" >
          <Col xs={12} lg={4} className="d-flex align-items-center order-lg-1 order-2">
            <div>
              <h1 className="whyus-section-title">
                Why Us
              </h1>
              <p className="whyus-section-subtitle">
                We want to maximizing the value and growth of your financial resources, whether it be through investment opportunities, strategic planning, or innovative financial products. We show you a plan to use the untapped potential of your financial capabilities which is waiting to be discovered
              </p>
            </div>
          </Col>
          <Col xs={12} lg={8} className="d-flex justify-content-center order-lg-2 order-1">
            <div className="w-100 h-100 position-relative">
              <img src="/img/whyus-image.png" className="w-100" />
              <img src="/img/compas.png" className="d-none d-lg-block position-absolute compas" />
            </div>
          </Col>

          <Col xs={12} xl={4} className="order-3">
            <Row className="gx-5 gy-5">
              <Col xs={12} lg={6} xl={12}>
                <div className="whyus-item">
                  <div className="whyus-item-title">
                    <img src="/img/mission-statement.svg" />
                    <p className="m-0">
                      Mission Statement
                    </p>
                  </div>
                  <div className="whyus-item-subtitle">
                    Our mission is to empower you to achieve your financial goals by providing you the best financial products in the market, with a focus on competitive pricing, innovative technology, and exceptional customer journey."
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={6} xl={12}>
                <div className="whyus-item">
                  <div className="whyus-item-title">
                    <img src="/img/vision-statement.svg" />
                    <p className="m-0">
                      Vision Statement
                    </p>
                  </div>
                  <div className="whyus-item-subtitle">
                    "Our vision is to become the leading financial portal that provides access to a comprehensive range of financial products and services, with a commitment to delivering the best value, convenience, and customer experience in the market."
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} xl={8} className="order-3">
            <div className="whyus-item">
              <div className="whyus-item-title">
                <img src="/img/vision-statement.svg" />
                <p className="m-0">
                  Meaning of Capitavio
                </p>
              </div>
              <div className="whyus-item-subtitle">
                We created this phantasy word as a combination from the words „Capital“ and „avio“ (sapnish: prepare for a journey) in a catchy nice sounding way. Capitavio means in a figuratively way „Prepare your Capital for a journey“ - the journey of your life where we adjust our solutions to your changing needs for your capital.

                "The path for your capital" generally means the strategy or plan for investing or deploying your financial resources to achieve your financial goals, whether it be for personal wealth building or business growth. It implies that there is a well-defined route or direction for your capital to take, with a clear understanding of the risks and potential returns involved.
                We strive to empower individuals and businesses of all sizes to achieve their financial goals and aspirations, and to build a brighter, more prosperous future for all.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WhyUsSection;