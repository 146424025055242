import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../../api/HTTP';
import * as Yup from 'yup';
import Execute from './ModalForms/TradeDeposit/Execute';

export default function TradeDepositForm(props) {
  const { user, selCard, acctid, assetid } = props;
  const navigate = useNavigate();
  const [lpExecutionType, setLpExecutionType] = useState([]);
  const [clientBankAccounts, setClientBankAccounts] = useState([]);

  const formik = useFormik({
    initialValues: {
      currency: selCard.servicename,
      amount: '',
      fromBank: selCard.mb_serviceid,
      execution: '',
    },
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 500));
      // alert(JSON.stringify(values, null, 2))
      // navigate('/', { replace: true })
    },
    validationSchema: Yup.object().shape({
      currency: Yup.string().required('Required'),
      amount: Yup.number().required('Required').positive(),
      fromBank: Yup.string().required('Required'),
    }),
  });

  useEffect(() => {
    const fetcExecutionType = async () => {
      await HTTP.get(`/base/get-lookup-values/19`)
        .then((response) => {
          setLpExecutionType(response.data);
        })
        .catch((e) => {
          console.log('fetcExecutionType error occurred', e.message);
        });
    };

    const fetchClientBankAccounts = async () => {
      try {
        await HTTP.get(`/client/client-bank-accounts/${user.clientid}/${user.loggedinclientid}/${user.limitedaccounts}`)
          .then((response) => {
            formik.values.fromBank = response.data[0].assetaccountid;
            setClientBankAccounts(response.data);
          })
          .catch((e) => {
            console.log('fetchClientBankAccounts error occurred', e.message);
          });
      } catch (err) {}
    };

    fetcExecutionType();
    fetchClientBankAccounts();
  }, []);

  const cancelExecution = () => {
    navigate(-1);
    // navigate('/', { replace: true })
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='currency'>
            Deposit to
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            id='currency'
            name='currency'
            type='currency'
            placeholder='Deposit to Card'
            value={formik.values.currency}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.currency && formik.touched.currency && 'error'}`}
          />
          {formik.errors.currency && formik.touched.currency && (
            <div className='input-feedback'>{formik.errors.currency}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='amount'>
            Amount
          </Form.Label>
        </Col>
        <Col md={9}>
          <InputGroup>
            <InputGroup.Text>{selCard.assetcalcunit}</InputGroup.Text>
            <Form.Control
              id='amount'
              name='amount'
              type='amount'
              // placeholder="Amount"
              aria-label='Amount (with dot and two decimal places)'
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`form-control ${formik.errors.amount && formik.touched.amount && 'error'}`}
            />
          </InputGroup>
        </Col>
        <Col md={3} />
        <Col md={7}>
          {formik.errors.amount && formik.touched.amount && (
            <div className='input-feedback'>{formik.errors.amount}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='fromBank'>
            From
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Select
            id='fromBank'
            name='fromBank'
            as='select'
            placeholder='Bank Account'
            className='form-select'
            value={formik.values.fromBank}
            onChange={formik.handleChange}
          >
            {clientBankAccounts.map((item, rowindex) => (
              <option value={item.assetaccountid} key={rowindex}>
                {item.bank}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='execution'>
            Execution
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Select
            id='execution'
            name='execution'
            type='execution'
            as='select'
            placeholder='Execution'
            className='form-select'
            value={formik.values.execution}
            onChange={formik.handleChange}
          >
            {lpExecutionType.map((item, rowindex) => (
              <option value={item.lp_id} key={rowindex}>
                {item.lp_value}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <div className='mb-3'>
        <div className='float-end'>
          <Execute
            currency={formik.values.currency}
            currencyError={formik.errors.currency}
            amount={formik.values.amount}
            amountError={formik.errors.amount}
            fromBank={formik.values.fromBank}
            fromBankError={formik.errors.fromBank}
            user={user}
            selCard={selCard}
            accountid={acctid}
            assetid={assetid}
          />{' '}
          <Button variant='secondary' onClick={cancelExecution}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
