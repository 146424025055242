import { useEffect, useRef, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactUsPage.css';
import HTTP from '../../api/HTTP';

import { Form, Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';

// import Breadcrumbs from "../components/Breadcrumbs"
import { useAuth } from '../../contexts/auth';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, FilterRow, Scrolling, Pager, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import Chart, { Series, CommonSeriesSettings } from 'devextreme-react/chart';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faEnvelopesBulk, faFileUser } from '@fortawesome/pro-thin-svg-icons';
import { faXmark, faPhone, faPen, faMessageLines, faArrowRightToLine } from '@fortawesome/pro-thin-svg-icons';
import BankDeposit from '../home/AssetCards/BankDeposit';
import { useMessaging } from '../../contexts/messaging';

const CommPage = () => {
  const { user } = useAuth();
  const { unreadMessagesNumber, handleReadMessages } = useMessaging();
  const navigate = useNavigate();

  const closeAccount = () => {
    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user]);

  const contactFinbuilder = () => { };

  const cancelContact = () => {
    navigate(-1);
  };

  const openChat = () => {
    handleReadMessages(user);
    navigate('/chat');
  };

  const writeNote = () => {
    navigate('/sendusnote');
  };

  const callUs = () => {
    navigate('/callus');
  };

  const openRecent = () => {
    navigate('/recentcommunication');
  };

  const closeDialog = () => {
    navigate(-1);
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      <Row>
        <Col lg={5} className='mx-auto mb-5'>
          <Card>
            <Card.Header>
              <h4 className='card-heading'>Contact Us</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <p className='text-muted mb-4'>
                    {/* <span className='fw-bold'>Hello</span>  */}
                    Please use one of the following options
                  </p>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col md={5}>
                  <Form.Label className='text-uppercase mt-3' htmlFor='account'>
                    Call Us
                  </Form.Label>
                </Col>
                <Col md={5}>
                  <div className='d-grid'>
                    <Button variant='light' size='lg' onClick={callUs}>
                      <FontAwesomeIcon className='icon-pos' icon={faPhone} size='xl' color='blue' />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col md={5}>
                  <Form.Label className='text-uppercase mt-3' htmlFor='account'>
                    Send us a Note
                  </Form.Label>
                </Col>
                <Col md={5}>
                  <div className='d-grid'>
                    <Button variant='light' size='lg' onClick={writeNote}>
                      <FontAwesomeIcon className='icon-pos' icon={faPen} size='xl' color='blue' />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col md={5}>
                  <Form.Label className='text-uppercase mt-3' htmlFor='account'>
                    Chat with us
                  </Form.Label>
                </Col>
                <Col md={5}>
                  <div className='d-grid position-relative'>
                    <Button variant='light' size='lg' onClick={openChat}>
                      <FontAwesomeIcon className='icon-pos' icon={faMessageLines} size='xl' color='blue' />
                    </Button>
                    {(unreadMessagesNumber > 0) && (
                      <Badge
                        pill
                        bg='primary'
                        className='position-absolute'
                        style={{
                          top: '-8px',
                          right: '-10px',
                        }}
                      >
                        {unreadMessagesNumber}
                      </Badge>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <div className='float-end'>
                <Button variant='outline-secondary' onClick={closeDialog}>
                  Close
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>

    // <Container fluid className='px-lg-4 px-xl-5'>
    //   {/*
    //   <Breadcrumbs title='Statement' />
    //    */}
    //   <section className='mb-4 mb-lg-5'>
    //     <Row>
    //       <Col lg={12} className='mb-4 mb-lg-0'>
    //         <Card className='h-100'>
    //           <Card.Header>
    //             <h4 className='card-heading'>Contact Us</h4>
    //           </Card.Header>
    //           <Card.Body>
    //             <Row className='g-2 mt-0 mb-3'>
    //               <Col md>
    //                 {/* <h3 className="mb-4">Login</h3> */}
    //                 <p className='text-muted'>
    //                   <span className='fw-bold'>Hello</span> to get in touch with us please chose one of the following
    //                   options
    //                 </p>
    //               </Col>
    //             </Row>
    //             <Row>
    //               <Col md={3}>
    //                 {/* <div>
    //                   <DataGrid
    //                     style={{ height: `calc(65vh - 150px)`, width: 'auto', maxWidth: '300px' }}
    //                     // dataSource={dcCasesNews}
    //                     showColumnHeaders={true}
    //                     defaultFocusedRowIndex={0}
    //                     showBorders={true}
    //                     focusedRowEnabled={true}
    //                     showRowLines={true}
    //                     wordWrapEnabled={true}
    //                     // onFocusedRowChanged={newsFocusedRowChange}
    //                     onCellClick={() => {
    //                       // gridFocus(2);
    //                     }}
    //                     // onCellDblClick={openCaseDetails}
    //                   >
    //                     <Paging pageSize={50} />
    //                     <Scrolling mode='standard' />
    //                     <Column dataField='entryid' visible={false}></Column>
    //                     <Column dataField='case_entryid' visible={false}></Column>
    //                     <Column dataField='newsdata' caption='Latest' encodeHtml={false} dataType='string' />
    //                   </DataGrid>
    //                 </div> */}
    //                 <div className='d-grid mt-2' style={{ maxWidth: '300px' }}>
    //                   <Button variant='primary' onClick={contactFinbuilder}>
    //                     Contact Finbuilder
    //                   </Button>
    //                 </div>
    //                 <div className='d-grid mt-2' style={{ maxWidth: '300px' }}>
    //                   <Button variant='primary' onClick={contactFinbuilder}>
    //                     <FontAwesomeIcon className='icon-pos' icon={faPhone} size='xl' color='white' />
    //                     Call Us
    //                   </Button>
    //                 </div>
    //               </Col>
    //             </Row>
    //           </Card.Body>
    //           <Card.Footer>
    //             <Row>
    //               <Col md={12} xl={12} className='mb-1'></Col>
    //             </Row>
    //           </Card.Footer>
    //         </Card>
    //       </Col>
    //     </Row>
    //   </section>

    //   <Row className='mb-5'>
    //     <Col>
    //       <div className='float-end'>
    //         <Button variant='outline-secondary' onClick={closeAccount}>
    //           Close
    //         </Button>
    //       </div>
    //     </Col>
    //   </Row>
    // </Container>
  );
};

export default CommPage;
