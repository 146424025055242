import {
  HomePage,
  AssetBuyDialog,
  AssetSellDialog,
  BankDepositDialog,
  BankTransferDialog,
  CcardDepositDialog,
  CcardWithdrawDialog,
  TradeDepositDialog,
  TradeViewDialog,
  TradeWithdrawDialog,
  BankAccountPage,
  CCardAccountPage,
  CommoditiesAccountPage,
  CryptoAccountPage,
  ForexAccountPage,
  AccountDetailsPage,
  AccountStatementsPage,
  ChangeAddressDialog,
  ChangeEMailDialog,
  ChangePasswordDialog,
  ProductServicesDialog,
  ProductsDialog,
  ProductsSingle,
  AdditionalServicesDialog,
  AccountUsersDialog,
  InviteUsersDialog,
  ChatPage,
  Chat,
  InquiriesAll,
  InquiriesPage,
  SignupMain,
  AgreeToServicesMain,
  AlmostThereMain,
  ContactUsPage,
  CallUsPage,
  WriteNotePage,
  RecentCommPage,
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
  {
    path: '/home',
    element: HomePage,
  },
  {
    path: '/asset/bankdeposit',
    element: BankDepositDialog,
  },
  {
    path: '/asset/assetbuy',
    element: AssetBuyDialog,
  },
  {
    path: '/asset/ccarddeposit/',
    element: CcardDepositDialog,
  },
  {
    path: '/asset/tradeview/',
    element: TradeViewDialog,
  },
  {
    path: '/asset/banktransfer',
    element: BankTransferDialog,
  },
  {
    path: '/asset/assetsell',
    element: AssetSellDialog,
  },
  {
    path: '/asset/ccardwithdraw',
    element: CcardWithdrawDialog,
  },
  {
    path: '/asset/tradedeposit',
    element: TradeDepositDialog,
  },
  {
    path: '/asset/tradewithdraw',
    element: TradeWithdrawDialog,
  },
  {
    path: '/account/bank',
    element: BankAccountPage,
  },
  {
    path: '/account/crypto',
    element: CryptoAccountPage,
  },
  {
    path: '/account/commodities',
    element: CommoditiesAccountPage,
  },
  {
    path: '/account/ccard',
    element: CCardAccountPage,
  },
  {
    path: '/account/forex',
    element: ForexAccountPage,
  },
  {
    path: '/accountdetails',
    element: AccountDetailsPage,
  },
  {
    path: '/accountstatements',
    element: AccountStatementsPage,
  },
  {
    path: '/accountdetails/changeaddress',
    element: ChangeAddressDialog,
  },
  {
    path: '/accountdetails/changeemail',
    element: ChangeEMailDialog,
  },
  {
    path: '/accountdetails/changepassword',
    element: ChangePasswordDialog,
  },
  {
    path: '/accountdetails/productservices',
    element: ProductServicesDialog,
  },
  {
    path: '/accountdetails/products',
    element: ProductsDialog,
  },
  {
    path: '/accountdetails/productssingle',
    element: ProductsSingle,
  },
  {
    path: '/accountdetails/additional-services',
    element: AdditionalServicesDialog,
  },
  {
    path: '/accountdetails/account-users',
    element: AccountUsersDialog,
  },
  {
    path: '/accountdetails/account-users/inviteuser',
    element: InviteUsersDialog,
  },
  {
    path: '/chat',
    element: Chat,
  },
  {
    path: '/document-requests',
    element: InquiriesAll,
  },
  {
    path: '/inquiry',
    element: InquiriesPage,
  },
  {
    path: '/signupmain',
    element: SignupMain,
  },
  {
    path: '/agree-to-services-main',
    element: AgreeToServicesMain,
  },
  {
    path: '/almost-there-main',
    element: AlmostThereMain,
  },
  {
    path: '/contactus',
    element: ContactUsPage,
  },
  {
    path: '/callus',
    element: CallUsPage,
  },
  {
    path: '/sendusnote',
    element: WriteNotePage,
  },
  {
    path: '/recentcommunication',
    element: RecentCommPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
