import { Col, Container, Row } from "react-bootstrap";
import CustomButton from "../../components/ui/CustomButton";

const AiClubSection = (props) => {
  return (
    <div {...props} >
      <Row>
        <Col
          xs={12}
          lg={6}
        >
          <div
            className="aiclub-graphic"
          >
            <img
              src="/img/aiclub-graphic.png"
            />
          </div>
        </Col>
        <Col
          xs={12}
          lg={6}
          className="aiclub-info"
        >
          <Container
            className="d-lg-block d-flex flex-column align-items-center"
            style={{
              maxWidth: '680px'
            }}
          >
            <h1
              className="aiclub-title text-md-start text-center"
            >
              AI Supported Investments
            </h1>
            <p
              className="aiclub-subtitle text-md-start text-center"
              style={{
                marginTop: '35px',
                marginBottom: '50px'
              }}
            >
              Capitavio offers its customers an easy access to invest in an intelligent managed trading system supported by artificial intelligence. Customers can invest in a trading system which generated profitable results in the past. The chart shows the result of that trading strategy versus investing in S&P 500 index.
            </p>
            <CustomButton
              variant="main-light"
              size="normal"
            >
              Join Watchlist
            </CustomButton>
          </Container>
        </Col>
      </Row>
    </div>
  );
}

export default AiClubSection;