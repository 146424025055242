import { useEffect, useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from '../../contexts/auth';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';

export default function PersonBasic(props) {
  const { signupStep, nextSignupStep, signupCompletion, getCountries, doReloadUser } = props;
  const { signOut, user } = useAuth();

  const [countries, setCountries] = useState(null);

  const formik = useFormik({
    initialValues: {
      nationality: '',
      countryOfResidence: '',
      city: '',
      cityPlc: '',
      street: '',
    },
    onSubmit: async (values) => {
      console.log(values);

      const clientData = {
        signupstage: 70,
        signupid: user.signupid,
        partnerid: user.partnerid,
        signup_personal: true,
        nationality: values.nationality,
        countryofresidence: values.countryOfResidence,
        city: values.city,
        plc: values.cityPlc,
        street: values.street,
      };

      const updated = await signupCompletion(clientData);
      if (updated.updated) {
        await doReloadUser();
        nextSignupStep(updated.signupstage); // 70
      }
    },
    validationSchema: Yup.object().shape({
      city: Yup.string().required('Required'),
      cityPlc: Yup.number().required('Required'),
      street: Yup.string().required('Required'),
      nationality: Yup.string().required('Required'),
      countryOfResidence: Yup.string().required('Required'),
    }),
  });

  useEffect(() => {
    async function getAllCountries() {
      const allCountries = await getCountries();
      setCountries(allCountries);
    }
    getAllCountries();
  }, []);

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Comment: Country of Residence will be used to determine Risklevel One. Low: Germany... Medium: Andorra...
            High: Vietnam... Countries can be set in Admin
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ToastContainer className='p-3' position={'bottom-center'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Risklevel One will be used to determine whether the Customer has to pay the On-Boarding Fee now or after KYC
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Person - Basic Registration Infos</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Form id='personBasicForm' onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              {' '}
              <p>To continue your application we need the following details</p>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md={6}>
              <div className='form-floating mb-3'>
                <Form.Select
                  id='nationality'
                  name='nationality'
                  type='nationality'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.nationality && formik.touched.nationality && 'error'}
                >
                  <option key='nationality'></option>
                  {countries &&
                    countries.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Label htmlFor='nationality'>Nationality</Form.Label>
                {formik.errors.nationality && formik.touched.nationality && (
                  <div className='input-feedback'>{formik.errors.nationality}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Home Address</p>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md={4}>
              <div className='form-floating mb-3'>
                <Form.Control
                  id='city'
                  name='city'
                  type='city'
                  placeholder='City'
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.city && formik.touched.city && 'error'}
                />
                <Form.Label htmlFor='city'>City</Form.Label>
                {formik.errors.city && formik.touched.city && (
                  <div className='input-feedback'>{formik.errors.city}</div>
                )}
              </div>
            </Col>
            <Col md={2}>
              <div className='form-floating mb-3'>
                <Form.Control
                  id='cityPlc'
                  name='cityPlc'
                  type='cityPlc'
                  placeholder='City-PLC'
                  value={formik.values.cityPlc}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.cityPlc && formik.touched.cityPlc && 'error'}
                />
                <Form.Label htmlFor='city'>City-PLC</Form.Label>
                {formik.errors.cityPlc && formik.touched.cityPlc && (
                  <div className='input-feedback'>{formik.errors.cityPlc}</div>
                )}
              </div>
            </Col>
            <Col md>
              <div className='form-floating mb-3'>
                <Form.Control
                  id='street'
                  name='street'
                  type='street'
                  placeholder='Street'
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.street && formik.touched.street && 'error'}
                />
                <Form.Label htmlFor='street'>Street</Form.Label>
                {formik.errors.street && formik.touched.street && (
                  <div className='input-feedback'>{formik.errors.street}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md={6}>
              <div className='form-floating mb-3'>
                <Form.Select
                  id='countryOfResidence'
                  name='countryOfResidence'
                  type='countryOfResidence'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.countryOfResidence && formik.touched.countryOfResidence && 'error'}
                >
                  <option key='country'></option>
                  {countries &&
                    countries.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Label htmlFor='countryOfResidence'>Country of Residence</Form.Label>
                {formik.errors.countryOfResidence && formik.touched.countryOfResidence && (
                  <div className='input-feedback'>{formik.errors.countryOfResidence}</div>
                )}
              </div>
            </Col>
          </Row>{' '}
          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button variant='primary' size='lg' type='submit' disabled={formik.isSubmitting}>
                  Continue
                </Button>{' '}
                <Button variant='outline-primary' size='lg' onClick={logoutHandler}>
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </Form>
      </Card.Body>
    </Card>
  );
}
