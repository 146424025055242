import { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';

export default function CompanyBasic(props) {
  const { signupStep, nextSignupStep, signupCompletion, getCountries, doReloadUser } = props;
  const { signOut, user } = useAuth();
  const [countries, setCountries] = useState(null);

  const formik = useFormik({
    initialValues: {
      companyname: '',
      registeredInCountry: '',
      companyCountry: '',
      city: '',
      cityPlc: '',
      street: '',
    },
    onSubmit: async (values) => {
      const clientData = {
        signupstage: 70,
        partnerid: user.partnerid,
        signupid: user.signupid,
        signup_personal: false,
        cy_name: values.companyname,
        cy_regin_country: values.registeredInCountry,
        cy_country: values.companyCountry,
        city: values.city,
        plc: values.cityPlc,
        street: values.street,
      };

      const updated = await signupCompletion(clientData);
      if (updated.updated) {
        await doReloadUser();
        nextSignupStep(updated.signupstage); // 70
      }
    },

    validationSchema: Yup.object().shape({
      companyname: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      cityPlc: Yup.number().required('Required'),
      street: Yup.string().required('Required'),
      companyCountry: Yup.string().required('Required'),
      registeredInCountry: Yup.string().required('Required'),
    }),
  });

  useEffect(() => {
    async function getAllCountries() {
      const allCountries = await getCountries();
      setCountries(allCountries);
    }
    getAllCountries();
  }, []);

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Comment: Country of Address will be used to determine a "Risklevel". Low: Germany... Medium: Andorra...
            High: Vietnam... Countries can be set in Admin
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Company Basic Registration Infos</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Form id='companyBasicForm' onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              {' '}
              <p>To continue your application we need the following details</p>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md>
              <div className='form-floating mb-3'>
                <Form.Control
                  id='companyname'
                  name='companyname'
                  type='companyname'
                  placeholder='Company Name'
                  value={formik.values.companyname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.companyname && formik.touched.companyname && 'error'}
                />
                <Form.Label htmlFor='companyname'>Company Name</Form.Label>
                {formik.errors.companyname && formik.touched.companyname && (
                  <div className='input-feedback'>{formik.errors.companyname}</div>
                )}
              </div>
            </Col>
            <Col md>
              <div className='form-floating mb-3'>
                <Form.Select
                  id='registeredInCountry'
                  name='registeredInCountry'
                  type='registeredInCountry'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.registeredInCountry && formik.touched.registeredInCountry && 'error'}
                >
                  <option key='registeredInCountry'></option>
                  {countries &&
                    countries.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Label htmlFor='registeredInCountry'>Registered in</Form.Label>
                {formik.errors.registeredInCountry && formik.touched.registeredInCountry && (
                  <div className='input-feedback'>{formik.errors.registeredInCountry}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {' '}
              <p>Company Address</p>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md>
              <div className='form-floating mb-3'>
                <Form.Control
                  id='city'
                  name='city'
                  type='city'
                  placeholder='City'
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.city && formik.touched.city && 'error'}
                />
                <Form.Label htmlFor='city'>City</Form.Label>
                {formik.errors.city && formik.touched.city && (
                  <div className='input-feedback'>{formik.errors.city}</div>
                )}
              </div>
            </Col>
            <Col md>
              <div className='form-floating mb-3'>
                <Form.Control
                  id='cityPlc'
                  name='cityPlc'
                  type='cityPlc'
                  placeholder='City-PLC'
                  value={formik.values.cityPlc}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.cityPlc && formik.touched.cityPlc && 'error'}
                />
                <Form.Label htmlFor='city'>City-PLC</Form.Label>
                {formik.errors.cityPlc && formik.touched.cityPlc && (
                  <div className='input-feedback'>{formik.errors.cityPlc}</div>
                )}
              </div>
            </Col>
            <Col md>
              <div className='form-floating mb-3'>
                <Form.Control
                  id='street'
                  name='street'
                  type='street'
                  placeholder='Street'
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.street && formik.touched.street && 'error'}
                />
                <Form.Label htmlFor='street'>Street</Form.Label>
                {formik.errors.street && formik.touched.street && (
                  <div className='input-feedback'>{formik.errors.street}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md={6}>
              <div className='form-floating mb-3'>
                <Form.Select
                  id='companyCountry'
                  name='companyCountry'
                  type='companyCountry'
                  // placeholder="Country"
                  // value={values.companyCountry}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.companyCountry && formik.touched.companyCountry && 'error'}
                >
                  <option key='country'></option>
                  {countries &&
                    countries.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Label htmlFor='companyCountry'>Country</Form.Label>
                {formik.errors.companyCountry && formik.touched.companyCountry && (
                  <div className='input-feedback'>{formik.errors.companyCountry}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button variant='primary' size='lg' type='submit' disabled={formik.isSubmitting}>
                  Continue
                </Button>{' '}
                <Button
                  variant='outline-primary'
                  size='lg'
                  // type="submit"
                  // disabled={isSubmitting}
                  onClick={logoutHandler}
                >
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
