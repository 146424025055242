import { useEffect, useContext, useState } from 'react';
import ClientContext from '../../store/client-context';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import { useAuth } from '../../contexts/auth';
import HTTP from '../../api/HTTP';

export default function ProductSelection(props) {
  const { nextSignupStep, signupCompletion } = props;
  const { signOut, user } = useAuth();

  const [productsList, setProductsList] = useState([]);
  const [selProductid, setSelProductid] = useState(-1);
  const [selProductName, setSelProductName] = useState('None');

  useEffect(() => {
    const fetchProducts = async () => {
      console.log(user.partnerid);
      console.log(user.client_risklevelid);

      await HTTP.get(`/signup/partner-products/${user.partnerid}/${user.client_risklevelid}/1`)
        .then((response) => {
          if (user.client_risklevelid === 10) {
            setSelProductid(-10);
          }

          setProductsList(response.data);
        })
        .catch((e) => {
          console.log('fetchTransactions occurred', e.message);
        });
    };

    if (user) {
      fetchProducts();
    }
  }, [user]);

  const saveProductSelection = async () => {
    const clientData = {
      signupstage: 110,
      signupid: user.signupid,
      productid: selProductid, // values.productId,
    };

    const updated = await signupCompletion(clientData);
    if (updated.updated) {
      nextSignupStep(updated.signupstage);
    }
  };

  function logoutHandler() {
    signOut();
  }

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>Comment: Listing the products available for the Customer according the Risk Value</Toast.Body>
        </Toast>
      </ToastContainer>
      {productsList && (
        <PageHeader
          title={
            user && user.client_risklevelid === 10
              ? 'Your Signup requires completion of certain documentation before you may chose a product. ' +
                'Please Continue and Login to the Banking App at the end of the process to complete your application'
              : 'Please chose a Product'
          }
        />
      )}
      <section>
        <Row className='mb-5'>
          {productsList &&
            productsList.map((item, rowindex) => (
              <Col lg={4} key={rowindex}>
                <Card
                  className={`mb-5 mb-lg-4 border-0  ${
                    item.row_to_json.productname ? 'card-highlight shadow-lg overflow-hidden' : 'shadow'
                  }`}
                >
                  {item.row_to_json.featured && <div className='card-status bg-primary' />}
                  <Card.Body>
                    <h2 className='card-heading text-primary fw-bold py-3'>{item.row_to_json.productname}</h2>
                    <p className='text-muted mb-3'>
                      <span className='h5 text-dark fw-bold'>
                        {item.row_to_json.currency} {item.row_to_json.recurringfee}
                      </span>
                      <span className='ms-2'>/ {item.row_to_json.period}</span>
                    </p>
                    <p className='text-muted mt-1'>
                      <span className='ms-0'>
                        Setup Fee {item.row_to_json.currency} {item.row_to_json.setupfee}
                      </span>
                    </p>
                    <hr className='text-muted' />

                    <ul className='fa-ul my-4'>
                      {item.row_to_json.services &&
                        item.row_to_json.services.map((serviceitem, rowindex) => (
                          <li className='mb-0' key={rowindex}>
                            {serviceitem.servicename}
                            <ul className='my-1'>
                              {serviceitem.serviceaccounts &&
                                serviceitem.serviceaccounts.map((srvaccounts, gindex) => (
                                  <li className='mb-0' key={gindex}>
                                    {srvaccounts.assetname}
                                  </li>
                                ))}
                            </ul>
                          </li>
                        ))}
                    </ul>
                    <p className='text-muted mt-0 mb-0'>
                      <span className='ms-2'>Usage incurs individual transaction fees.</span>{' '}
                    </p>
                    <p className='text-muted mt-0'>
                      <span className='ms-2'>For details please review full</span>{' '}
                      <a href={`/productpricing/${item.row_to_json.productid}/${user.client_risklevelid}`}> Pricing</a>
                    </p>
                    <hr className='text-muted' />

                    <div className='float-end'>
                      <Button
                        variant='outline-primary'
                        onClick={() => {
                          console.log(item.row_to_json);
                          setSelProductid(item.row_to_json.productid);
                          setSelProductName(item.row_to_json.productname);
                        }}
                      >
                        Select
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <Row className='g-2 mt-0 mb-3 '>
          <Col md>
            <div className='float-end'>
              <div className='mb-0'>{'Selected Product: '} </div>
              <div className='mb-1 fw-bold'>
                <span className='fw-bold'> {selProductName} </span>{' '}
              </div>
              <Button
                variant='primary'
                size='lg'
                disabled={selProductid !== -1 ? false : true}
                onClick={saveProductSelection}
              >
                Continue
              </Button>{' '}
              <Button variant='outline-primary' size='lg' onClick={logoutHandler}>
                Leave
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
