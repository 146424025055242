import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Toast, Button, Modal, ToastContainer } from 'react-bootstrap';

import { useHttpClient } from '../../../../../hooks/http-hook';

import { useAuth } from '../../../../../contexts/auth';
import HTTP from '../../../../../api/HTTP';

export default function Execute(props) {
  const { newemail, newemailError, user } = props;

  const { signOut } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [emailUsed, setEmailUsed] = useState(false);
  const submitButtonRef = useRef(false);

  const saveEMail = async () => {
    const mail_count = await email_in_use();
    if (mail_count > 0) {
      console.log('--- EMail in Use Please enter a different one ---');
      setEmailUsed(true);
      setShow(false);
    } else {
      setEmailUsed(false);
      toggleModal();
    }
  };

  const toggleModal = async () => {
    if (!newemailError && newemail !== '') {
      setShow(!show);
    }
  };

  async function email_in_use() {
    try {
      const dataArray = await HTTP.get(`/client/email-in-use/${user.clientid}`);
      return dataArray.data[0].email_count;
    } catch (err) {
      console.log(err);
    }
  }

  async function changeEMailAddress() {
    const response = await HTTP.post(
      '/client/change-address',
      JSON.stringify({
        mod_data: 20,
        clientid: user.clientid,
        cl_email: newemail,
      })
    );
    console.log(5555, response.data[0]);
    const operationresult = response.data[0].mod_client_data;
    if (operationresult == 20) {
      console.log('E-Mail changed. Please login again');
      // navigate('/home');
    } else {
      console.log('Please use a different E-Mail as the selected one is in use already');
      if (operationresult == 19) {
        //  navigate('/account-details');
      }
    }
  }

  return (
    <React.Fragment>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={emailUsed} bg='warning'>
          <Toast.Body>
            The entered E-Mail cannot be used as it is in use already. Please enter a different one
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Button variant='primary' type='submit' onClick={saveEMail}>
        Save
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Please Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to change the E-Mail to</p>
          <Form
            onSubmit={async (values) => {
              await changeEMailAddress();
              signOut();
            }}
          >
            <div className='mb-3'>
              <p>{newemail}?</p>
              <p>
                If so you will be logged out automatically on a successful change and you have to log in again using the
                new E-Mail
              </p>
            </div>
            <Button variant='primary' className='d-none' type='submit' ref={submitButtonRef}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitButtonRef.current.click()}>
            Yes
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
