import { useEffect, useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';

import { useAuth } from '../../contexts/auth';
import { v4 as uuidv4 } from 'uuid';
import HTTP from '../../api/HTTP';

async function getBody(clientData, externalUserId) {
  let body = {};
  // Check for personal or company account
  if (clientData.personal_account) {
    body = {
      externalUserId: externalUserId,
      email: clientData.cl_email,
      phone: clientData.cl_mobilephone,
      lang: 'English',
      fixedInfo: {
        firstName: clientData.cl_firstname,
        lastName: clientData.cl_lastname,
        // middleName: "",
        // legalName: "",
        // gender: "", // M or W
        dob: clientData.cl_dateofbirth, // "1999-12-01"
        placeOfBirth: clientData.cl_bornin_city,
        // countryOfBirth: "Country",
        // stateOfBirth: "State",
        country: await getCountryISOCode(clientData.cl_countryofresidence), // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
        nationality: await getCountryISOCode(clientData.cl_nationality), // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
        addresses: [
          {
            country: await getCountryISOCode(clientData.cl_countryofresidence), // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
            postCode: clientData.cl_plc,
            town: clientData.cl_city,
            street: clientData.cl_street,
          },
        ],
      },
    };
  } else {
    console.log('Type not known!');
  }

  return body;
}

async function getCountryISOCode(country) {
  try {
    const response = await HTTP.get(`/signup/country-iso-code/${country}`);
    const data = await response.data;
    return data.iso_code;
  } catch (error) {
    console.log('getCountryISOCode error', error);
  }
}

async function getUserData(email) {
  try {
    const response = await HTTP.get(`/signup/client-data/${email}`);
    const data = await response.data;
    return data;
  } catch (error) {
    console.log('getUserData error', error);
  }
}

async function updateKYCIDs(kycId, kycExternalUserId, kycProviderId, email) {
  try {
    const response = await HTTP.post(
      '/signup/update-kyc-ids',
      JSON.stringify({
        kycId: kycId,
        kycExternalUserId: kycExternalUserId,
        kycProviderId: kycProviderId,
        email: email,
      })
    );
    const data = await response.data;

    if (data.status === 'success') return 'success';
    return false;
  } catch (error) {
    console.log('updateKYCIDs error', error);
  }
}

async function createApplicant(email) {
  const levelName = 'kyc';
  const kycProviderId = 1;
  const kycexternalUserId = uuidv4();
  const clientData = await getUserData(email);
  // console.log(kycexternalUserId)
  // console.log(clientData)
  const body = await getBody(clientData, kycexternalUserId);

  const response = await fetch('/api/sumsub/create-applicant', {
    method: 'POST',
    body: JSON.stringify({
      levelName: levelName,
      body: body,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  console.log(data);

  // Get sumsub applicant id
  const kycId = data.kycid;

  // Save ids in database
  const updatedKYC = await updateKYCIDs(kycId, kycexternalUserId, kycProviderId, email);

  if (updatedKYC === 'success') return { result: 'success', kycId: kycId };
  return false;
}

async function setRiskLevel(riskLevel) {
  const response = await fetch('/api/sumsub/set-risk-level', {
    method: 'POST',
    body: JSON.stringify({
      riskLevel: riskLevel,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (data.status === 'success') return 'success';
  return false;
}

export default function PersonRiskMatrix(props) {
  const { signupStep, nextSignupStep, signupCompletion, doReloadUser } = props;
  const { signOut, user } = useAuth();

  const modelid = 1;
  const requestentryid = 1;
  const [requestMatrixFeatures, setRequestMatrixFeatures] = useState([]);
  const [requestMatrixSelectables, setRequestMatrixSelectables] = useState([]);
  const [dataIncomplete, setDataIncomplete] = useState(false);
  const [nQueryResult, setNQueryResult] = useState({});

  const resultValues = [];

  useEffect(() => {
    const fetchRequestMatrixFeatures = async () => {
      await HTTP.get(`/signup/request-matrix-features/${modelid}`)
        .then((response) => {
          setRequestMatrixFeatures(response.data);
        })
        .catch((e) => {
          console.log('fetchRequestMatrixFeatures error', e.message);
        });
    };

    const fetchRequestMatrixSelectables = async () => {
      await HTTP.get(`/signup/request-matrix-selectables/${modelid}`)
        .then((response) => {
          setRequestMatrixSelectables(response.data);
        })
        .catch((e) => {
          console.log('fetchRequestMatrixFeatures error', e.message);
        });
    };

    fetchRequestMatrixFeatures();
    fetchRequestMatrixSelectables();
  }, []);

  function logoutHandler() {
    signOut();
  }

  function createInputs() {
    return requestMatrixFeatures.map((item, i) => (
      <Form.Group className='mb-3' key={i}>
        <Form.Label>{item.risk_name}</Form.Label>
        <Form.Select
          aria-label={item.risk_name}
          onChange={(e) => {
            handleChange(item.entryid, item.risk_name, e);
          }}
        >
          <option value='-1'></option>
          {requestMatrixSelectables.map((selitem, index) =>
            selitem.risk_groupid === item.entryid ? (
              <option value={selitem.group_entryid} key={index}>
                {selitem.group_entry}
              </option>
            ) : (
              []
            )
          )}
        </Form.Select>
      </Form.Group>
    ));
  }

  function handleChange(aentryid, featurename, event) {
    const entrydata = {
      modelid: modelid,
      requestentryid: requestentryid,
      entryid: aentryid,
      featurename: featurename,
      featurevalue: event.target.value,
    };
    const index = resultValues.findIndex(({ entryid }) => entryid === entrydata.entryid);

    if (index === -1) {
      // result.featurevalue = event.target.value;
      resultValues.push(entrydata);
    } else {
      resultValues[index] = entrydata;
    }
  }

  const checkcompletion = async () => {
    const reqCount = requestMatrixFeatures.length;

    const arrayCount = resultValues.length;
    const emptyArrays = resultValues.filter((obj) => {
      return obj.featurevalue == '-1';
    });

    if (arrayCount !== reqCount || emptyArrays.legnth > 0) {
      setDataIncomplete(true);
      return true;
    } else {
      setDataIncomplete(false);
      return false;
    }
  };

  const saveDataEntries = async () => {
    try {
      const dataIsIncomplete = await checkcompletion();

      if (dataIsIncomplete) {
        console.log('-- incomplete Data --');
        return;
      }

      console.log('-- continue --');

      const anbody = JSON.stringify({
        clientid: user.signupid,
        cliententry: requestentryid,
        modelid: modelid,
        resultValues: resultValues,
      });

      const response = await HTTP.post('/signup/build-client-riskmatrix', anbody);
      console.log(response.data);

      console.log('--1--');
      const nwresponse = await HTTP.get(`/signup/request-matrix-neuronetwork/${modelid}/${user.signupid}`);
      const data = await nwresponse.data;
      setNQueryResult(data);
      console.log(data);

      console.log('--2--');

      const riskresult = JSON.stringify({
        clientid: user.signupid,
        matrix_result: data.NetworkQueryResult,
        risklevel: data.Risklevel,
      });

      user.client_risklevelid = parseInt(data.Risklevel);

      console.log('--3--', riskresult);

      const saveresponse = await HTTP.post('/signup/save-client-riskresult', riskresult);

      console.log('--4--');
      console.log(saveresponse.data);

      console.log('--5--');
      MoveOn(data.Risklevel);
    } catch (error) {
      console.log('client-risk-matrix error', error);
    }
  };

  const MoveOn = async (riskresult) => {
    console.log('--6--');

    console.log('--7--');

    const clientData = {
      signupstage: 80,
      signupid: user.signupid,
      signup_personal: true,
    };

    console.log('--8--');

    // Send client data to sumsub and save in database
    const createdApplicantresult = 'success'; // = await createApplicant(user.email); createdApplicant.result === 'success'
    if (createdApplicantresult === 'success') {
      /* 
        TODO
        Comment in to make setting of risk level work after contacting sumsub for production environment
      
        Reason from doc:
        https://developers.sumsub.com/api-reference/#set-risk-level-for-the-applicant
        --> "Please note that you should have special permission to make this request (contact the Sum&Substance tech team).""
      */

      // Set risk level of client at sumsub
      // const updatedRiskLevel = await setRiskLevel(
      //   createdApplicant.kycId,
      //   riskresult
      // )

      // if (updatedRiskLevel === "success") {
      const updated = await signupCompletion(clientData);
      if (updated.updated) {
        console.log('--8--');
        nextSignupStep(updated.signupstage); // 80
      }
      // }
    } else {
      console.log('--9--');
      console.log('Could not create applicant at sumsub!');
    }
  };

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={dataIncomplete} bg='warning'>
          <Toast.Body>Please complete all Questions to proceed</Toast.Body>
        </Toast>
      </ToastContainer>

      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Person - Risk Matrix Registration Questionaire</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Form id='personBasicForm'>
          <Row>
            <Col>
              {' '}
              <p>As part of your online application we need the following details</p>
            </Col>
          </Row>
          <Row>
            <Col>{createInputs()}</Col>
          </Row>
          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                {/* 
                <Button variant="primary" size="lg" onClick={saveDataEntries}>
                  Save
                </Button>{" "}
              */}
                <Button variant='primary' size='lg' onClick={saveDataEntries}>
                  Continue
                </Button>{' '}
                <Button variant='outline-primary' size='lg' onClick={logoutHandler}>
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
