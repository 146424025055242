import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../../api/HTTP';
import * as Yup from 'yup';
import Execute from './ModalForms/BankTransfer/Execute';
import { IMaskInput } from 'react-imask';

export default function BankTransferForm(props) {
  const { user, selCard, acctid, assetid } = props;
  const navigate = useNavigate();
  const [formerTransfers, setFormerTransfers] = useState(false);
  const [lpClientTransfers, setLpClientTransfers] = useState([]);
  const [lpBankTransferType, setLpBankTransferType] = useState([]);
  const [lpExecutionType, setLpExecutionType] = useState([]);

  const formik = useFormik({
    initialValues: {
      task: 1,
      recipient: '',
      iban: '',
      amount: '',
      intendeduse: '',
      execution: 1,
    },
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 500));
      // alert(JSON.stringify(values, null, 2))
      // navigate('/', { replace: true })
    },
    validationSchema: Yup.object().shape({
      recipient: Yup.string().required('Required'),
      iban: Yup.string().required('Required'),
      amount: Yup.number().required('Required').positive(),
      intendeduse: Yup.string().max(100).required('Required'),
    }),
  });

  useEffect(() => {
    const fetchClientTransfers = async () => {
      const response = await HTTP.get(`/client/client-transfers/${user.clientid}`);
      setLpClientTransfers(response.data);
      if (response.data.length > 0) setFormerTransfers(true);
    };

    const fetchBankTransferType = async () => {
      await HTTP.get(`/base/get-lookup-values/18`)
        .then((response) => {
          setLpBankTransferType(response.data);
        })
        .catch((e) => {
          console.log('fetchBankTransferType error occurred', e.message);
        });
    };

    const fetchExecutionType = async () => {
      await HTTP.get(`/base/get-lookup-values/19`)
        .then((response) => {
          setLpExecutionType(response.data);
        })
        .catch((e) => {
          console.log('fetcExecutionType error occurred', e.message);
        });
    };

    fetchClientTransfers();
    fetchBankTransferType();
    fetchExecutionType();
  }, []);

  const cancelExecution = () => {
    navigate(-1);
  };

  const setRecipient = async (setFieldValue) => {
    const recipientIban = document.getElementById('useTransfer');
    // console.log(recipientIban.value)
    for (let i = 0; i < lpClientTransfers.length; i++) {
      if (lpClientTransfers[i].iban === recipientIban.value) {
        // console.log(lpClientTransfers[i])
        formik.setFieldValue('recipient', lpClientTransfers[i].receipient);
        formik.setFieldValue('iban', recipientIban.value);
      }
    }
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      {formerTransfers && (
        <Row className='mb-3'>
          <Col md={3}>
            <Form.Label className='text-uppercase mt-1' htmlFor='useTransfer'>
              Previous
            </Form.Label>
          </Col>
          <Col md={7}>
            <Form.Select
              id='useTransfer'
              name='useTransfer'
              type='useTransfer'
              as='select'
              // placeholder="Transfers"
              className='form-select'
            >
              {lpClientTransfers.map((item, rowindex) => (
                <option value={item.iban} key={rowindex}>
                  {item.receipient} {item.iban}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={2} className='d-grid'>
            <Button
              variant='primary'
              size='sm'
              // onClick={setRecipient(setFieldValue)}
              onClick={() => setRecipient()}
            >
              Select
            </Button>
          </Col>
        </Row>
      )}
      <br />

      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='task'>
            Task
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            id='task'
            name='task'
            type='task'
            as='select'
            // placeholder="Transfer, Standing Order"
            className='form-select'
          >
            {lpBankTransferType.map((item, rowindex) => (
              <option value={item.lp_id} key={rowindex}>
                {item.lp_value}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='recipient'>
            Beneficiary
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            id='recipient'
            name='recipient'
            type='recipient'
            placeholder='Recipient'
            value={formik.values.recipient}
            onChange={formik.handleChange}
            // onChange={(e) => { handleChange(e); setFieldValue('recipient', getPriceUsingID(e.target.value)); }}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.recipient && formik.touched.recipient && 'error'}`}
          />
          {formik.errors.recipient && formik.touched.recipient && (
            <div className='input-feedback'>{formik.errors.recipient}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='iban'>
            IBAN
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            id='iban'
            name='iban'
            type='iban'
            placeholder='IBAN'
            value={formik.values.iban}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.iban && formik.touched.iban && 'error'}`}
          />
          {formik.errors.iban && formik.touched.iban && <div className='input-feedback'>{formik.errors.iban}</div>}
        </Col>
      </Row>
      <Row className='mb-0'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='amount'>
            Amount
          </Form.Label>
        </Col>
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text>{selCard.currency}</InputGroup.Text>
            <Form.Control
              id='amount'
              name='amount'
              type='amount'
              // placeholder="Amount"
              aria-label='Amount (with dot and two decimal places)'
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`form-control ${formik.errors.amount && formik.touched.amount && 'error'}`}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3} />
        <Col md={7}>
          {formik.errors.amount && formik.touched.amount && (
            <div className='input-feedback'>{formik.errors.amount}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='intendeduse'>
            Intended Use
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            as='textarea'
            id='intendeduse'
            name='intendeduse'
            type='intendeduse'
            placeholder='Intended Use'
            // style={{ height: '60px' }}
            value={formik.values.intendeduse}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.intendeduse && formik.touched.intendeduse && 'error'}`}
          />
          {formik.errors.intendeduse && formik.touched.intendeduse && (
            <div className='input-feedback'>{formik.errors.intendeduse}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='execution'>
            Execution
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Select
            id='execution'
            name='execution'
            type='execution'
            as='select'
            placeholder='Execution'
            className='form-select'
            value={formik.values.execution}
            onChange={formik.handleChange}
          >
            {lpExecutionType.map((item, rowindex) => (
              <option value={item.lp_id} key={rowindex}>
                {item.lp_value}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <div className='mb-3'>
        <div className='float-end'>
          <Execute
            task={formik.values.task}
            taskError={formik.errors.task}
            recipient={formik.values.recipient}
            recipientError={formik.errors.recipient}
            iban={formik.values.iban}
            ibanError={formik.errors.iban}
            amount={formik.values.amount}
            amountError={formik.errors.amount}
            intendeduse={formik.values.intendeduse}
            intendeduseError={formik.errors.intendeduse}
            execution={formik.values.execution}
            executionError={formik.errors.execution}
            user={user}
            selCard={selCard}
            accountid={acctid}
            assetid={assetid}
          />{' '}
          <Button variant='secondary' onClick={cancelExecution}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
