import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import HTTP from "../../api/HTTP";
import { io } from "socket.io-client";
import { createChart, CrosshairMode } from "lightweight-charts";

const Lightweight = (props) => {
  const { selSymbol, chartPeriod, chartResolution } = props;
  const chart = useRef();
  const chartContainerRef = useRef();
  const symbol = selSymbol;

  const fetchChartData = async () => {
    const response = await HTTP.get(
      `/forex/fx-m-chart-data/${selSymbol}/${chartPeriod}/${chartResolution}`
    )
    const data = response.data;
    console.log(data);

    const seriesData = data.map((d) => {
      return {
        time: (new Date(d.bucket).getTime() + 2 * 60 * 60 * 1000) / 1000,
        open: parseFloat(d.open),
        high: parseFloat(d.high),
        low: parseFloat(d.low),
        close: parseFloat(d.close),
      };
    });

    chart.current = createChart(chartContainerRef.current, {
      width: 1000,
      height: 600,
      //   layout: {
      //     backgroundColor: "#253248",
      //     textColor: "rgba(255, 255, 255, 0.9)",
      //   },
      //   grid: {
      //     vertLines: {
      //       color: "#334158",
      //     },
      //     horzLines: {
      //       color: "#334158",
      //     },
      //   },
      //   crosshair: {
      //     mode: CrosshairMode.Normal,
      //   },
      //   priceScale: {
      //     borderColor: "#485c7b",
      //   },
      //   timeScale: {
      //     borderColor: "#485c7b",
      //     timeVisible: true,
      //     secondsVisible: false,
      //   },
    });

    const candleSeries = chart.current.addCandlestickSeries({
      priceFormat: {
        type: "price",
        precision: 5,
        minMove: 0.00001,
      },
      //   upColor: "#4bffb5",
      //   downColor: "#ff4976",
      //   borderDownColor: "#ff4976",
      //   borderUpColor: "#4bffb5",
      //   wickDownColor: "#838ca1",
      //   wickUpColor: "#838ca1",
    });

    // console.log(seriesData);

    candleSeries.setData(seriesData);

    // const volumeSeries = chart.current.addHistogramSeries({
    //   color: "#182233",
    //   lineWidth: 2,
    //   priceFormat: {
    //     type: "volume",
    //   },
    //   overlay: true,
    //   scaleMargins: {
    //     top: 0.8,
    //     bottom: 0,
    //   },
    // });
    // volumeSeries.setData(volumeData);

    const socket = io.connect(process.env.REACT_APP_SOCKET_SERVER_URL);
    socket.emit("join", "sym_min_candle");
    socket.on(symbol.replace("%2F", "/"), (pl) => {
      console.log(pl);
      pl.time = pl.time / 1000;
      candleSeries.update(pl);
    });
  };

  useEffect(() => {
    fetchChartData();
  }, [selSymbol, chartPeriod, chartResolution]);

  return (
    <>
      <Container ref={chartContainerRef} />
    </>
  );
};

export default Lightweight;
