import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, logOut } from '../api/auth';
import HTTP from '../api/HTTP';
import { useAuth } from './auth';

function MesssagingProvider(props) {
  const [unreadMessagesNumber, setUnreadMessagesNumber] = useState(0);
  const [doUpdateMessagesList, setDoUpdateMessagesList] = useState(false);
  const [doReadMessages, setDoReadMessages] = useState(false);

  const { user } = useAuth();

  const countUnreadMessages = async (threadId, clientId) => {
    const unreadMessagesCount = await HTTP.get(`/internal_messaging/get-unread-messages/${threadId}/${clientId}`);
    setUnreadMessagesNumber(unreadMessagesCount.data.unreadMessagesCount);
  }

  useEffect(() => {
    if (doUpdateMessagesList) {
      countUnreadMessages(user.clientchatid, user.clientid);
    }
  }, [doUpdateMessagesList]);

  useEffect(() => {
    if (user) {
      countUnreadMessages(user.clientchatid, user.clientid);
    }
  }, [user]);

  const handleReadMessages = async (user = user) => {
    try {
      await HTTP.get(`/internal_messaging/read-message/${user.clientchatid}/${user.clientid}`);
      setUnreadMessagesNumber(0);
    } catch (error) {
      console.log('Error occure while reading messages');
    }
  }

  return (
    <MessagingContext.Provider
      value={{
        unreadMessagesNumber,
        setUnreadMessagesNumber,
        doUpdateMessagesList,
        setDoUpdateMessagesList,
        handleReadMessages,
        doReadMessages,
        setDoReadMessages,
      }}
      {...props}
    />
  );
}

const MessagingContext = createContext({ loading: false });
const useMessaging = () => useContext(MessagingContext);

export { MesssagingProvider, useMessaging, MessagingContext };
