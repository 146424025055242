import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../api/HTTP';
import HTTPFileUpload from '../../api/HTTPFileUpload';
import { downloadFileFromBlob, downloadFileFromBuffer, getFileExtension } from '../../utils/attach-helper';

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
  Toast,
  ToastContainer,
  FormControl,
  InputGroup,
} from 'react-bootstrap';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ThreadMessages from './ThreadMessages';
import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';

const Inquiry = (props) => {
  const { inquiryId, user, showChatArea, signupDlg } = props;

  const clientResponse = useRef('');
  const [inquiryDetails, setInquiryDetails] = useState({});
  const [documentDataArray, setDocumentDataArray] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showUploadable, setShowUploadable] = useState(false);
  const [showUploadWarning, setShowUploadWarning] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [documentStatus, setDocumentStatus] = useState([]);
  const [uploadDocumentMaxSize, setUploadDocumentMaxSize] = useState(0);
  const [amountUploadedDocuments, setAmountUploadedDocuments] = useState(0);
  const [amountUploadableDocuments, setAmountUploadableDocuments] = useState(0);

  const [requiredDocData, setRequiredDocData] = useState({});
  const [selectedDockey, setSelectedDockey] = useState(-1);
  const [downloadFileRow, setDownloadFileRow] = useState({});
  const [selectedDownloadkey, setSelectedDownloadkey] = useState(-1);

  const [reloadUpload, setReloadUpload] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/home', { replace: true });
    }
  }, [user]);

  useEffect(() => {
    const fetchInquiryDetails = async () => {
      await HTTP.get(`/inquiry/details/${inquiryId}`)
        .then((response) => {
          setInquiryDetails(response.data[0]);
          setAmountUploadableDocuments(response.data[0].documentcount);
        })
        .catch((error) => {
          console.log('inquiry details ', error);
        });

      await HTTP.get(`/alerts/mark-read/${inquiryId}`)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const fetchDocumentStatus = async () => {
      await HTTP.get(`/inquiry/threads-status`)
        .then((response) => {
          setDocumentStatus(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const fetchDocumentMaxSize = async () => {
      await HTTP.get(`/inquiry/threads-document-sizes`)
        .then((response) => {
          setUploadDocumentMaxSize(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (inquiryId) {
      fetchInquiryDetails();
      fetchDocumentStatus();
      fetchDocumentMaxSize();
    }
  }, [inquiryId]);

  useEffect(() => {
    async function fetchReqDocs() {
      await HTTP.get(`/inquiry/requested-docs/${inquiryId}`)
        .then((response) => {
          setDocumentDataArray(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (inquiryId) {
      fetchReqDocs();
    }
  }, [inquiryId]);

  useEffect(() => {
    const fetchUploadedDocuments = async () => {
      await HTTP.get(`/inquiry/uploaded-docs/${inquiryId}`)
        .then((response) => {
          setUploadedDocuments(response.data);
          setAmountUploadedDocuments(response.data.length);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (inquiryId) {
      fetchUploadedDocuments();
    }
  }, [inquiryId, reloadUpload]);

  const closeAccount = () => {
    navigate(-1);
  };

  const stDocumentDataArray = new ArrayStore({
    key: 'docid',
    data: documentDataArray,
  });

  const dcDocumentDataArray = new DataSource({
    store: stDocumentDataArray,
  });

  const stUploadedDocuments = new ArrayStore({
    key: 'id',
    data: uploadedDocuments,
  });

  const dcUploadedDocuments = new DataSource({
    store: stUploadedDocuments,
  });

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const customDataTableStyles = {
    head: {
      style: {
        fontSize: '14px',
        fontWeight: 500,
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: '#2647d9',
        color: 'white',
        userSelect: 'none',
      },
    },
  ];

  const columnsDownload = [
    {
      name: 'Name',
      selector: (row) => row.file_name,
      sortable: true,
    },
    {
      name: 'Title',
      selector: (row) => row.usage_advice,
      sortable: true,
      minWidth: '130px',
    },
    {
      name: 'Description',
      selector: (row) => row.comment,
      sortable: true,
      minWidth: '170px',
    },
  ];

  const columnsUpload = [
    {
      name: 'Name',
      selector: (row) => row.file_name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => getStatus(row.status_id),
      sortable: true,
    },
  ];

  const getStatus = (status) => {
    for (let i = 0; i < documentStatus.length; i++) {
      if (documentStatus[i].id === status) {
        return documentStatus[i].label;
      }
    }
  };

  const handleRowClicked = (row) => {
    // console.log(row)
    const updatedData = documentDataArray.map((item) => {
      if (row.documentid !== item.documentid) {
        return item;
      }

      return {
        ...item,
        toggleSelected: !item.toggleSelected,
      };
    });

    setDocumentDataArray(updatedData);
  };

  const getDocument = async (documentId, documentName) => {
    try {
      await HTTP.get(`/inquiry/download-document/${documentId}`, { responseType: 'blob' })
        .then(function (response) {
          downloadFileFromBlob(response.data, documentName.file_name);
        })
        .catch((err) => {
          console.log('Document Download failed', err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const downloadDocument = async () => {
    if (selectedDockey) {
      await getDocument(selectedDockey, requiredDocData);
    } else {
      setShowDownload(true);
      await sleep(5000);
      setShowDownload(false);
    }
  };

  const validateAmountUploadedFiles = async () => {
    // setShowUpload(true);

    if (amountUploadedDocuments < amountUploadableDocuments) {
      setShowUpload(true);
    } else {
      setShowUploadable(true);
      await sleep(5000);
      setShowUploadable(false);
    }
  };

  const saveRequiredDoc = async (e) => {
    // Validate size of uploaded documents
    // console.log(e.target.files[0].size)
    if (e.target.files[0].size < uploadDocumentMaxSize) {
      const docData = new FormData();

      docData.append('file_name', e.target.files[0].name);
      docData.append('clientId', inquiryDetails.clientid);
      docData.append('inquiryId', inquiryDetails.inquiryid);
      docData.append('portalid', user.portalid);
      docData.append('userid', -1);
      docData.append('file', e.target.files[0]);

      await HTTPFileUpload.post(`/inquiry/upload-document`, docData)
        .then(() => {
          console.log('Upload done');
          setShowUpload(false);
          setReloadUpload(!reloadUpload);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setShowUploadWarning(true);
      await sleep(5000);
      setShowUploadWarning(false);
    }
  };

  const cellRenderDocumentStatus = (data) => {
    for (let i = 0; i < documentStatus.length; i++) {
      if (documentStatus[i].id === data.value) {
        return documentStatus[i].label;
      }
    }
  };

  const ShowUploadSection = () => {
    if (amountUploadableDocuments > 0) {
      return (
        <React.Fragment>
          <section className='mb-4 mb-lg-4'>
            <Row className='justify-content-md-center'>
              {showChatArea && (
                <Col lg={8} className='mb-4 mb-lg-0'>
                  {inquiryDetails.chatthreadid && <ThreadMessages threadId={inquiryDetails.chatthreadid} user={user} />}
                </Col>
              )}
              <Col lg={8} className='mb-4 mb-lg-0'>
                <Card className='h-100'>
                  <Card.Header>
                    <h4 className='card-heading'>Requested Documents</h4>
                  </Card.Header>
                  <Card.Body>
                    <Row className='mb-4 mb-lg-5'>
                      <p>Documents for Download and Review</p>
                      <Row className='mb-4 mb-lg-3'>
                        <DataGrid
                          id='inquiry-req-doc-grid'
                          dataSource={dcDocumentDataArray}
                          width='480px'
                          height='260px'
                          showBorders={true}
                          showRowLines={true}
                          showColumnLines={false}
                          showColumnHeaders={false}
                          onSelectionChanged={(e) => {
                            if (e.selectedRowKeys[0]) {
                              setRequiredDocData(e.selectedRowsData[0]);
                              setSelectedDockey(e.selectedRowKeys[0]);
                            }
                          }}
                        >
                          <Selection mode='single' />
                          <Column dataField='docid' visible={false} />
                          <Column dataField='file_name' caption='Document' />
                        </DataGrid>
                      </Row>
                      <Row>
                        <Col>
                          <Button variant='outline-secondary' onClick={downloadDocument}>
                            Download
                          </Button>{' '}
                        </Col>
                        <Col>
                          <ToastContainer>
                            <Toast show={showDownload} bg='warning'>
                              <Toast.Body>Please select one or more documents first</Toast.Body>
                            </Toast>
                          </ToastContainer>
                        </Col>
                      </Row>
                    </Row>

                    <Row>
                      <p>Uploaded Documents (Max. {uploadDocumentMaxSize / 1000000}mb per file)</p>
                      <Row>
                        <DataGrid
                          id='req-doc-up-Grid'
                          dataSource={dcUploadedDocuments}
                          width='480px'
                          height='260px'
                          showBorders={true}
                          showRowLines={true}
                          showColumnLines={false}
                          showColumnHeaders={false}
                          onSelectionChanged={(e) => {
                            if (e.selectedRowKeys[0]) {
                              setDownloadFileRow(e.selectedRowsData[0]);
                              setSelectedDownloadkey(e.selectedRowKeys[0]);
                            }
                          }}
                        >
                          <Selection mode='single' />
                          <Column dataField='docid' visible={false} />
                          <Column dataField='file_name' caption='Document' />
                          <Column
                            alignment='center'
                            caption='Document Status'
                            dataField='status_id'
                            width={120}
                            cellRender={cellRenderDocumentStatus}
                          />
                        </DataGrid>
                      </Row>
                      <Row className='mt-4'>
                        <Col>
                          <Button
                            variant='outline-secondary'
                            disabled={amountUploadedDocuments === amountUploadableDocuments}
                            onClick={() => validateAmountUploadedFiles()}
                          >
                            Upload
                          </Button>
                        </Col>
                        <Col>
                          <ToastContainer>
                            <Toast show={showUploadable} bg='warning'>
                              <Toast.Body>Max {amountUploadableDocuments} documents can be uploaded!</Toast.Body>
                            </Toast>
                          </ToastContainer>
                        </Col>
                      </Row>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section className='mb-4 mb-lg-4'>
            <Row className='justify-content-md-center'>
              <Col lg={8} className='mb-4 mb-lg-0'>
                {inquiryDetails.chatthreadid && <ThreadMessages threadId={inquiryDetails.chatthreadid} user={user} />}
              </Col>
              {/* 
              <Col lg={6} className="mb-4 mb-lg-0">
                <Card className="h-100">
                  <Card.Header>
                    <h4 className="card-heading">Requested Documents</h4>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mb-4 mb-lg-5">
                      <p>No requested documents</p>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              */}
            </Row>
          </section>
        </React.Fragment>
      );
    }
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      <section className='mb-4 mb-lg-4'>
        <Row className='justify-content-md-center'>
          <Col lg={amountUploadableDocuments > 0 ? 8 : 8} className='mb-4 mb-lg-0'>
            <Card className='h-100'>
              <Card.Header>
                <h4 className='card-heading'>{inquiryDetails.subject}</h4>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <div className='flex-shrink-0 me-1 mb-3'>
                      <React.Fragment>
                        <Row>
                          <Col>
                            <div>{inquiryDetails.inquirytext}</div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      <ShowUploadSection />

      <Modal size='lg' show={showUpload} onHide={() => setShowUpload(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>File will be uploaded immediately:</p>
          <Form.Control type='file' onChange={(e) => saveRequiredDoc(e)} />
          <p></p>
          <ToastContainer>
            <Toast show={showUploadWarning} bg='warning'>
              <Toast.Body>File exceeds max size!</Toast.Body>
            </Toast>
          </ToastContainer>
        </Modal.Body>
      </Modal>

      <Row className='mb-5'>
        <Col>
          <div className='float-end'>
            <Button variant='outline-secondary' onClick={closeAccount}>
              Close
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Inquiry;
