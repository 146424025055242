import { FormControl } from "react-bootstrap";

/**
 * Custom Input
 * @param {*} param0 
 * @returns {FormControl}
 */

const CustomInput = (props) => {
  console.log(props);
  return (
    <>
      <style type="text/css">
        {`
          .form-control {
            background: transparent;
            padding: 15px 20px;
            border: 1px solid;
            border-radius: 45px;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px !important;
          }

          .form-control:focus {
            background: transparent;
            box-shadow: none;
          }

          .main-form-control {
            border-color: #98A1BE;
          }

          .secondary-form-control {
            border-color: #FEFEFF;
          }
        `}
      </style>

      <FormControl
        bsPrefix={`form-control ${props.variant === 'main' ? 'main-form-control' : 'secondary-form-control'}`}
        {...props}
      />
    </>
  );
}

export default CustomInput;