import { useContext } from 'react';
import ClientContext from '../../../store/client-context';

import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
// import Breadcrumbs from "../../components/Breadcrumbs"
import ChangePassword from './Change-Password';

const ChangePasswordDialog = () => {
  const navigate = useNavigate();

  const accountid = 1;

  const acsctid = 1;

  const userCtx = useContext(ClientContext);

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/*
      <Breadcrumbs pages={[{ link: '/account-details', name: 'Account Details' }]} title='Change Password' />
 */}
      <section>
        <Row>
          <Col lg={6} className='mx-auto mb-5'>
            <Card>
              <Card.Header>
                <h4 className='card-heading'>Password</h4>
              </Card.Header>
              <Card.Body>
                <p className='text-muted mb-4'>Please enter</p>
                <ChangePassword user={userCtx.user} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default ChangePasswordDialog;
