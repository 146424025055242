import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const TradingViewState = (props) => {
  const { user, tradeAccountState, showTradeDepositPage, showTradeWithdrawPage, formatter, formatCurrency } = props;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Row>
        <Col lg={4} className='mb-4'>
          <Card className='h-100'>
            <Card.Body>
              <Row>
                <Col>
                  <div className='text-uppercase flex-shrink-0 mb-3'>
                    <div className='fw-bold'>Balance</div>
                  </div>{' '}
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div>
                    {user.selCard &&
                      tradeAccountState &&
                      tradeAccountState.balance &&
                      user.selCard.assetcalcunit +
                        ' ' +
                        formatCurrency(parseFloat(tradeAccountState.balance).toFixed(2)).replace('EUR', '')}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className='float-end'>
                    <Button variant='outline-primary' size='sm' onClick={showTradeDepositPage}>
                      Deposit
                    </Button>{' '}
                    <Button variant='outline-primary' size='sm' onClick={showTradeWithdrawPage}>
                      Withdraw
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className='mb-4'>
          <Card className='h-100'>
            <Card.Body>
              <Row className='mb-0'>
                <Col>
                  <div className='text-uppercase flex-shrink-0'>
                    <div className='fw-bold'>Margin</div>
                  </div>{' '}
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <div className='text-gray-500'>Used</div>
                  <div>
                    <div>
                      {tradeAccountState &&
                        tradeAccountState.margin_used &&
                        formatCurrency(parseFloat(tradeAccountState.margin_used).toFixed(2)).replace('EUR', '')}
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className='text-gray-500'>Free</div>
                  <div>
                    <div>
                      {tradeAccountState &&
                        tradeAccountState.marginfree &&
                        formatCurrency(parseFloat(tradeAccountState.marginfree).toFixed(2)).replace('EUR', '')}
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className='text-gray-500'>Level</div>
                  <div>
                    <div>
                      {tradeAccountState &&
                        tradeAccountState.marginlevel &&
                        formatter.format(tradeAccountState.marginlevel)}
                      %
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className='mb-4'>
          <Card className='h-100'>
            <Card.Body>
              <Row className='mb-0'>
                <Col>
                  <div className='text-uppercase flex-shrink-0'>
                    <div className='fw-bold'>Equity</div>
                  </div>{' '}
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className='text-gray-500'>Floating P/L</div>
                  <div>
                    <div>
                      {tradeAccountState &&
                        tradeAccountState.unrealized_pl &&
                        formatCurrency(parseFloat(tradeAccountState.unrealized_pl).toFixed(2)).replace('EUR', '')}
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className='text-gray-500'>Total</div>
                  <div>
                    <div>
                      {tradeAccountState &&
                        tradeAccountState.totalequity &&
                        formatCurrency(parseFloat(tradeAccountState.totalequity).toFixed(2)).replace('EUR', '')}
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TradingViewState;
