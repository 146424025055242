import React, { useEffect, useContext, useState } from 'react';
import ClientContext from '../../../store/client-context';

import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import PageHeader from '../../../components/PageHeader';
// import Breadcrumbs from "../../components/Breadcrumbs"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ProductsDialog = () => {
  const userCtx = useContext(ClientContext);
  const [productsList, SetProductsList] = useState([]);
  const [selProductid, SetSelProductid] = useState(-1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(
        `/api/partner-products/${userCtx.user.partnerid}/${userCtx.user.client_risklevelid}/1`,
        { method: 'GET' }
      );
      const data = await response.json();
      SetProductsList(data);
    };

    if (userCtx.user && userCtx.user.clientid) {
      fetchProducts();
    } else {
      closeProductPage();
    }
  }, [userCtx.user]);

  const closeProductPage = () => {
    navigate('/account-details');
  };

  const OpenProductPricing = (anProductid) => {
    navigate(`/accountdetails/productpricing`);
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/* 
      <Breadcrumbs
        pages={[{ link: "/account-details", name: "Account Details" }]}
        title={"Available Products"}
      />
      */}
      <PageHeader title={'Products'} />
      <section>
        <Row className='mb-5'>
          {productsList &&
            productsList.map((item, rowindex) => (
              <Col lg={4} key={rowindex}>
                <Card
                  className={`mb-5 mb-lg-4 border-0  ${
                    item.row_to_json.productname ? 'card-highlight shadow-lg overflow-hidden' : 'shadow'
                  }`}
                >
                  {item.row_to_json.featured && <div className='card-status bg-primary' />}
                  <Card.Body>
                    <h2 className='card-heading text-primary fw-bold py-3'>{item.row_to_json.productname}</h2>
                    <p className='text-muted mb-3'>
                      <span className='h5 text-dark fw-bold'>
                        {item.row_to_json.currency} {item.row_to_json.recurringfee}
                      </span>
                      <span className='ms-2'>/ {item.row_to_json.period}</span>
                    </p>
                    <p className='text-muted mt-1'>
                      <span className='ms-0'>
                        Setup Fee {item.row_to_json.currency} {item.row_to_json.setupfee}
                      </span>
                    </p>
                    <hr className='text-muted' />

                    <ul className='fa-ul my-4'>
                      {item.row_to_json.services &&
                        item.row_to_json.services.map((serviceitem, rowindex) => (
                          <li className='mb-0' key={rowindex}>
                            {serviceitem.servicename}
                            <ul className='my-1'>
                              {serviceitem.serviceaccounts &&
                                serviceitem.serviceaccounts.map((srvaccounts, gindex) => (
                                  <li className='mb-0' key={gindex}>
                                    {srvaccounts.assetname}
                                  </li>
                                ))}
                            </ul>
                          </li>
                        ))}
                    </ul>
                    <p className='text-muted mt-0 mb-0'>
                      <span className='ms-2'>Usage incurs individual transaction fees.</span>{' '}
                    </p>
                    <p className='text-muted mt-0'>
                      <span className='ms-2'>For details please review full</span>{' '}
                      <a
                        href={`/accountdetails/productpricing/${item.row_to_json.productid}/${userCtx.user.client_risklevelid}`}
                      >
                        {' '}
                        Pricing
                      </a>
                      {/* 
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="text-blue-500"
                        onClick={(e) => {
                          OpenProductPricing(item.row_to_json.productid)
                        }}
                      >
                        Pricing
                      </Button>
                    */}
                    </p>
                    <hr className='text-muted' />

                    <div className='float-end'>
                      <Button variant='outline-primary' href='#'>
                        Select
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </section>
      <section>
        <Row className='mb-5'>
          <Col>
            <div className='float-end'>
              <Button variant='outline-secondary' onClick={closeProductPage}>
                Close
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default ProductsDialog;
