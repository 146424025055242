import React from 'react';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import CustomButton from '../../components/ui/CustomButton';
import CustomInput from '../../components/ui/CustomInput';
import { LoginForm } from '../../components';

const SignupSection = (props) => {
  const { children } = props;
  return (
    <div {...props}>
      <div className='home-section-wrapper'>
        <Container className='home-section'>
          <Row>
            <Col lg={6} md={12} className='home-section-right'>
              <img src='/img/logo-v4.svg' className='home-section-logo' />
              <p className='home-secondary-text'>Unlock the power of your capital.</p>
              <Row className='w-100'>
                <Col xxl={12} lg={12} md={12} sm={12}>
                  <LoginForm />
                </Col>
              </Row>
              <div
                className='d-none d-lg-block border-gradient'
                style={{
                  // background: 'linear-gradient(234.47deg, rgba(255, 255, 255, 0) 13.46%, rgba(255, 255, 255, 0.61) 32.18%, rgba(255, 255, 255, 0.88) 61.38%, #FFFFFF 85.34%)',
                  padding: '50px 31px',
                  fontSize: '18px',
                  lineHeight: '111.2%',
                  borderRadius: '20px',
                  marginTop: '75px',
                  color: '#FFFFFF',
                  border: '1px solid rgba(255, 255, 255, 0.61)',
                }}
              >
                Capitavio combines a unique combination of banking services, debit cards, equity trading,
                cryptocurrencies, savings, and digital assets. Embrace the Capitavio universe and let your capital work
                for you.
              </div>
            </Col>
            <Col lg={6} md={12} className='home-section-left'>
              <img id='dashboard_img' src='/img/dashboard.png' />
              <div className='description'>
                <h1 className='description-title'>Prepare your Capital for a journey</h1>
                <p className='description-subtitle'>Maximizing the value and growth of your financial resources</p>
                <img id='hand_with_phone_img' src='/img/hand_holding_phone.png' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        style={{
          backgroundColor: '#131111',
          color: '#FFFFFF',
        }}
      >
        <Container>
          <div className='possibilities-section'>
            <div
              className='possibilities-title'
              // style={{
              //   fontWeight: 400,
              //   fontSize: '24px',
              //   lineHeight: '33px',
              //   maxWidth: '320px',
              //   marginRight: 'auto',
              // }}
            >
              One App, Infinite Financial Possibilities.
            </div>
            <div className='possibilities-item'>
              <img src='/img/invest.svg' />
              Invest globally & effortlessly into divers markets
            </div>
            <div className='possibilities-item'>
              <img src='/img/inovative.svg' />
              Innovative AI Trading solutions
            </div>
            <div className='possibilities-item'>
              <img src='/img/investment.svg' />
              Investment Oriented Banking
            </div>
            <div className='possibilities-item'>
              <img src='/img/secure.svg' />
              Secure custody for all your assets.
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SignupSection;
