import { Container, Nav, NavItem, NavLink, Row, TabContainer, TabContent, TabPane } from "react-bootstrap";
import Footer from "./footer";
import Header from "./header";
import PolicyTopic from "./policy-topics/policy-topic";
import Topic2 from "./policy-topics/topic-2";
import Topic3 from "./policy-topics/topic-3";
import { useNavigate } from "react-router-dom";

const PolicyPage = () => {
  const navigate = useNavigate();

  const handleNavLink = (e) => {
    e.preventDefault();
    navigate('/');
  }

  return (
    <div
      className="d-flex flex-column"
      style={{
        backgroundColor: '#131111',
        minHeight: '100vh',
      }}
    >
      <Header handleNavLink={handleNavLink} />
      <Container
        className="flex-grow-1 policy-content-container"
        style={{
          marginTop: '112px'
        }}
      >
        <TabContainer
          defaultActiveKey='firstTab'
        >
          <Row>
            <Nav
              variant='pills'
            >
              <NavItem>
                <NavLink eventKey='firstTab'>Policy etc</NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey='secondTab'>Topic #2</NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey='thirdTab'>Topic #3</NavLink>
              </NavItem>
            </Nav>
          </Row>

          <TabContent className="mt-5">
            <TabPane eventKey='firstTab'>
              <PolicyTopic />
            </TabPane>
            <TabPane eventKey='secondTab'>
              <Topic2 />
            </TabPane>
            <TabPane eventKey='thirdTab'>
              <Topic3 />
            </TabPane>
          </TabContent>
        </TabContainer>
      </Container>
      <Footer
        dark={true}
        handleNavLink={handleNavLink}
      />
    </div>
  );
}

export default PolicyPage;