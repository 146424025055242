import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Modal } from 'react-bootstrap';
import HTTP from '../../../../../api/HTTP';

export default function Execute(props) {
  const { invitedEmail, user, deletedInvite, inviteid } = props;

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const submitButtonRef = useRef(false);

  const toggleModal = () => {
    setShow(!show);
  };

  const cancelInvite = async () => {
    try {
      const response = await HTTP.post(
        '/client-user/cancel-invite',
        JSON.stringify({
          clientid: user.clientid,
          user_email: invitedEmail,
          inviteid: inviteid,
        })
      );
      deletedInvite();
      setShow(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Button variant='outline-primary' type='submit' onClick={toggleModal}>
        Cancel Invitation
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Cancel Invitation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please confirm that you intend to cancel the invitation of the following User?</p>
          <Form>
            <div className='mb-3'>
              <p>{invitedEmail}</p>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={cancelInvite}>
            Yes
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
