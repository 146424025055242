import React from "react"
import { Col, Row } from "react-bootstrap"
import AssetCard from "./AssetCard"

export default function Assets(props) {
  const { data, clientid } = props

  const formatCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "code",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format

  return (
    <section className="mb-4 mb-lg-4">
      <Row className="text-dark">
        {/*<div className="text-dark">*/}
        {data.map((card, index) => {
          let gradient
          switch (index) {
            case 1:
              gradient = "blue"
              break
            case 2:
              gradient = "green"
              break
            default:
              gradient = "indigo"
          }
          return (
            <Col md={6} xl={4} className="mb-4" key={index}>
              <AssetCard gradient={gradient} card={card} />
            </Col>
          )
        })}
        {/*   </div>*/}
      </Row>
    </section>
  )
}
