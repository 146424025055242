import React, { useEffect, useContext, useState } from 'react';
import { useAuth } from '../../../../contexts/auth';
import HTTP from '../../../../api/HTTP';

import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import PageHeader from '../../../../components/PageHeader';
// import Breadcrumbs from "../../../components/Breadcrumbs"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const AddedServices = () => {
  const { user } = useAuth();
  const [productsList, SetProductsList] = useState([]);
  const [productData, SetProductData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    {
      const fetchProducts = async () => {
        await HTTP.get(`/account/product-single-services/${user.clientid}/${user.client_risklevelid}`)
        .then((response) => {
          SetProductsList(response.data)
      })
        .catch((e) => {
          console.log('fetchProducts error occurred', e.message);
        });
      };

      if (user && user.clientid) {
        fetchProducts();
      }
    }
  }, [user]);

  const changeSingleServiceProducts = () => {
    navigate('/accountdetails/productssingle');
  };

  const closeProductPage = () => {
    navigate(-1);
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/*
      <Breadcrumbs
        pages={[{ link: "/account-details", name: "Account Details" }]}
        title={"Additional Servicess"}
      />
       */}
      {/*  <PageHeader title={"Product"} /> */}

      <section className='mb-4 mb-lg-4'>
        <Card className='h-100'>
          <Card.Header>
            <h4 className='card-heading'>Additional Services</h4>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Button variant='outline-primary' size='sm' onClick={changeSingleServiceProducts}>
                  Modify...
                </Button>{' '}

              </Col>
            </Row>
          </Card.Body>
        </Card>
      </section>

      <section>
        {productsList && (
          <Row className='mb-5'>
            {productsList &&
              productsList.map((item, rowindex) => (
                <Col lg={4} key={rowindex}>
                  <Card
                    className={`mb-5 mb-lg-4 border-0  ${
                      item.row_to_json.productname ? 'card-highlight shadow-lg overflow-hidden' : 'shadow'
                    }`}
                  >
                    {item.row_to_json.featured && <div className='card-status bg-primary' />}
                    <Card.Body>
                      <h2 className='card-heading text-primary text-center fw-bold py-3'>
                        {item.row_to_json.productname}
                      </h2>
                      <p className='text-muted text-center mb-1'>
                        <span className='ms-2'>
                          Fee per month - {item.row_to_json.currency} {item.row_to_json.recurringfee}
                        </span>
                      </p>
                      <p className='text-muted text-center mb-4'>
                        <span className='ms-2'>
                          Setup Fee - {item.row_to_json.currency} {item.row_to_json.setupfee}{' '}
                        </span>
                      </p>

                      <hr className='text-muted' />

                      <ul className='fa-ul my-4'>
                        {item.row_to_json.services.map(
                          (anserviceitem, rowindex) =>
                            anserviceitem.service_fees &&
                            anserviceitem.service_fees.map((serviceitem, gindex) => (
                              <li className='mb-3' key={gindex}>
                                <span className={`fa-li ${serviceitem.feature ? 'text-muted' : 'text-primary'}`}>
                                  {/*   <FontAwesomeIcon
                              icon={serviceitem.feature ? faTimes : faCheck}
                            />
                          */}
                                </span>
                                {(serviceitem.costvalue > 0 || serviceitem.costperc > 0) &&
                                serviceitem.feature_group_position > 1
                                  ? serviceitem.feature_group +
                                    ' - Cost' +
                                    (serviceitem.costvalue || serviceitem.costperc
                                      ? serviceitem.costvalue
                                        ? ' ' + item.row_to_json.currency + ' ' + serviceitem.costvalue
                                        : serviceitem.costperc_minvalue
                                        ? ' ' +
                                          serviceitem.costperc +
                                          '% min. ' +
                                          ' ' +
                                          item.row_to_json.currency +
                                          ' ' +
                                          serviceitem.costperc_minvalue +
                                          ' max. ' +
                                          ' ' +
                                          serviceitem.costperc_maxvalue
                                        : ' ' + serviceitem.costperc + '%'
                                      : '')
                                  : ''}
                              </li>
                            ))
                        )}
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        )}
      </section>

      <section>
        <Row className='mb-5'>
          <Col>
            <div className='float-end'>
              <Button variant='outline-secondary' onClick={closeProductPage}>
                Close
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default AddedServices;
