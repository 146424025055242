import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ArrayStore from 'devextreme/data/array_store';
import { Form, Col, Container, Row, Button, Card } from 'react-bootstrap';
import DataGrid, { Column, Selection, Scrolling, Pager, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import { merchantPartner } from '../../../utils/global-const';

// import Breadcrumbs from "../../components/Breadcrumbs"
import { useAuth } from '../../../contexts/auth';
import HTTP from '../../../api/HTTP';

export default function Index(props) {
  const textSubject = useRef();
  const textAreaRef = useRef();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [caseNotes, setCaseNotes] = useState([]);
  const [reloadGrid, setReloadGrid] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/home', { replace: true });
    }
  }, [user]);

  useEffect(() => {
    const fetchCasesNotes = async () => {
      try {
        const dataArray = await HTTP.get(`/contact/get-client-notes/${user.clientid}`);
        setCaseNotes(dataArray.data);
      } catch (err) {}
    };

    if (user.clientid) {
      fetchCasesNotes();
    }
  }, [reloadGrid, user.userId]);

  const stCaseNotes = new ArrayStore({
    key: 'entryid',
    data: caseNotes,
  });

  const saveNote = async () => {
    try {
      await HTTP.post(
        '/contact/send-client-note',
        JSON.stringify({
          clientid: user.clientid,
          subject: textSubject.current.value,
          case_content: textAreaRef.current.value,
        })
      );
      setReloadGrid(!reloadGrid);
    } catch (err) {
      console.log(err);
    }
  };

  const closeDialog = () => {
    navigate(-1);
  };

  const handleSubmit = (e) => {
    console.log(1111, textSubject.current.value);
    console.log(2222, textAreaRef.current.value);
    saveNote();
    textSubject.current.value = '';
    textAreaRef.current.value = '';
    e.preventDefault();
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/*
      <Breadcrumbs title={'Chat'} />
       */}
      <section>
        <Row className='justify-content-md-center'>
          <Col lg={10}>
            <Card className='h-100'>
              <Card.Header>
                <h4 className='card-heading'>Send us a Note</h4>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <DataGrid
                      style={{ height: `calc(65vh - 150px)`, width: 'auto' }}
                      dataSource={stCaseNotes}
                      showColumnHeaders={true}
                      defaultFocusedRowIndex={0}
                      showBorders={false}
                      //  focusedRowEnabled={true}
                      showRowLines={true}
                      wordWrapEnabled={true}
                      showColumnHeaders={false}
                      // onFocusedRowChanged={newsFocusedRowChange}
                      onCellClick={() => {
                        // gridFocus(2);
                      }}
                      // onCellDblClick={openCaseDetails}
                    >
                      <Selection mode='single' />
                      <Paging pageSize={50} />
                      <Scrolling mode='standard' />
                      <Column dataField='entryid' visible={false}></Column>
                      <Column dataField='case_content' caption='Sent Notes' encodeHtml={false} dataType='string' />
                    </DataGrid>
                  </Col>
                  <Col>
                    <Row>
                      <div style={{ height: `calc(65vh - 580px)` }}></div>
                    </Row>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Form.Label className='text-uppercase mt-1'>Subject</Form.Label>
                        <Form.Control
                          style={{ marginLeft: 10, width: '95%' }}
                          ref={textSubject}
                          name='notesubject'
                          type='text'
                          // placeholder="Input your message
                        />
                      </Row>
                      <Row>
                        <Form.Label className='text-uppercase mt-1'>Note</Form.Label>
                        <Form.Control
                          style={{ height: 280, marginLeft: 10, width: '95%' }}
                          ref={textAreaRef}
                          as='textarea'
                          name='note'
                          type='textarea'
                          // placeholder="Input your message
                        />
                      </Row>

                      <Row>
                        <div style={{ textAlign: 'end', marginTop: 10 }}>
                          <Button type={'submit'} width={'auto'} onClick={handleSubmit}>
                            Send
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <div className='float-end'>
                  <Button variant='outline-secondary' onClick={closeDialog}>
                    Close
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
