import axios from 'axios';
import tokenService from '../services/TokenService';

let axiosOptions = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // timeout: 5000,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: {
      toString() {
        const token = tokenService.getAccessToken();
        if (!!token) {
          return 'Bearer ' + token;
        }

        return '';
      },
    },
  },
};

const HTTPFileUpload = axios.create(axiosOptions);

export default HTTPFileUpload;
