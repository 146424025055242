import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Toast, ToastContainer, Button, Modal } from 'react-bootstrap';
import { useHttpClient } from '../../../../../hooks/http-hook';
import { useAuth } from '../../../../../contexts/auth';
import HTTP from '../../../../../api/HTTP';

export default function Execute(props) {
  const { oldPassword, oldPasswordError, passwordOne, passwordOneError, passwordTwo, passwordTwoError, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { signOut } = useAuth;

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [enteredPasswordsDiffer, setEnteredPasswordsDiffer] = useState(false);

  const submitButtonRef = useRef(false);

  const toggleModal = () => {
    if (
      !passwordOneError &&
      passwordOne !== '' &&
      !passwordTwoError &&
      passwordTwo !== '' &&
      !oldPasswordError &&
      oldPassword !== ''
    ) {
      setShow(!show);
    }
  };

  async function passwordsDiffer() {
    if (passwordOne !== passwordTwo) {
      setEnteredPasswordsDiffer(true);
    } else {
      setEnteredPasswordsDiffer(false);
      toggleModal();
    }
  }

  async function savePasswords() {
    passwordsDiffer();
    if (!enteredPasswordsDiffer) {
      toggleModal();
    }
  }

  const saveNewPassword = async () => {
    const response = await HTTP.post(
      '/client/change-password',
      JSON.stringify({
        email: user.cl_email,
        oldPassword: oldPassword,
        newPassword: passwordOne,
      })
    );

    if (response.data.changedfor === user.cl_lastname) {
      console.log('Password changed. Please login again');
      toggleModal();
      navigate('/home');
    } else {
      console.log(response.data);
      alert(response.data.message);
      // router.replace("/account-details")
    }
  };

  const handleSubmit = async (values) => {
    await saveNewPassword();
  };

  return (
    <React.Fragment>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={enteredPasswordsDiffer} bg='warning'>
          <Toast.Body>The entered Passwords do not match. Please re-enter the Passwords</Toast.Body>
        </Toast>
      </ToastContainer>

      <Button variant='primary' type='submit' onClick={savePasswords}>
        Save
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Please Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to change the Password?</p>
          <Form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <p>
                If so you will be logged out automatically on a successful change and you have to log in again using the
                new Password
              </p>
            </div>
            <Button variant='primary' className='d-none' type='submit' ref={submitButtonRef}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitButtonRef.current.click()}>
            Yes
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
