import React, { useEffect, useRef, useState } from 'react';
import HTTP from '../../api/HTTP';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { formatDate } from '../../utils/date-helper';
import MessagesList from './MessagesList';
import * as Yup from 'yup';

import ValidationHelper, {
  checkFileListByCallback,
  ERROR_MESSAGES,
} from '../../components/document-exchange/ValidationHelper';
import { querystring } from '../../utils/functions';

import { merchantPartner } from '../../utils/global-const';

const ThreadMessages = (props) => {
  const { threadId, user } = props;
  const navigate = useNavigate();
  const [threadDetails, setThreadDetails] = useState({});
  const [messagesDataList, setMessagesDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  //  const [isLoading, setIsLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true);

  const attachesRef = useRef(null);
  const messageListRef = useRef(null);

  const validationSchema = Yup.object().shape({
    message: Yup.string().required('Required field'),
    files: Yup.mixed()
      .test('fileSize', ERROR_MESSAGES.invalidSize, (fileList) => {
        if (fileList && fileList.length) {
          return checkFileListByCallback(fileList, ValidationHelper.validateFileSize);
        }
        return true;
      })
      .test('fileFormat', ERROR_MESSAGES.invalidExtension, (fileList) => {
        if (fileList && fileList.length) {
          return checkFileListByCallback(fileList, ValidationHelper.validateFileExtension);
        }
        return true;
      }),
  });

  const formik = useFormik({
    onSubmit: (values, { setSubmitting, resetForm, setErrors }) => {
      handleSubmitMessage(values, resetForm, setErrors);
      setSubmitting(false);
    },
    initialValues: {
      message: '',
      attaches: [],
    },
    //  validationSchema: { validationSchema },
  });

  useEffect(() => {
    const fetchInquiryThreads = async () => {
      try {
        const response = await HTTP.get(`/inquiry/threads/${threadId}`);
        setThreadDetails(response.data);
      } catch (error) {
        console.log('fetchInquiryThreads error', error);
      }
    };

    if (threadId && user) {
      fetchInquiryThreads();
    }
  }, [threadId, user]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.onscroll = handleLoadMore;
      scrollTo();
    }
  }, [messageListRef.current]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadList(page);
    }, 10000);
    return () => clearInterval(interval);
  }, [page, threadId]);

  useEffect(() => {
    loadList(page);
    if (user) {
      markChatRead();
    }
  }, [page, threadId]);

  const loadList = async (page = 1) => {
    var qs = querystring({
      page: page,
      limit: 10,
    });
    try {
      const response = await HTTP.get(`/inquiry/messages/${threadId}${qs}`);
      const reverseArray = response.data.items.reverse();
      setTotalPages(response.data.meta.totalPages);
      if (page === 1) {
        setMessagesDataList([...reverseArray]);
        scrollTo();
      } else {
        setMessagesDataList([...reverseArray, ...messagesDataList]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const markChatRead = async () => {
    try {
      const response = await HTTP.get(`/alerts/chat-mark-read/${user.clientid}`);
      console.log(response.data);
    } catch (error) {
      console.log('markChatRead error', error);
    }
  };

  const scrollTo = (scrollHeight) => {
    if (messageListRef.current) {
      if (!scrollHeight) {
        scrollHeight = messageListRef.current.scrollHeight;
      }
      messageListRef.current.scroll({ top: scrollHeight });
    }
  };

  const handleLoadMore = () => {
    if (messageListRef.current.scrollTop === 0) {
      const nextPage = page + 1;
      if (nextPage <= totalPages) {
        setPage(nextPage);
      }
    }
  };

  const handleSubmitMessage = async (data, resetForm, setErrors) => {
    // console.log(4, String(threadId));
    // console.log(3, data.message);

    const formData = new FormData();
    formData.append('threadId', String(threadId));
    formData.append('message', data.message);
    formData.append('clientid', user.clientid);
    if (data.attaches && data.attaches.length > 0) {
      for (let i = 0; i < data.attaches.length; i++) {
        formData.append('files', data.attaches[i], data.attaches[i].name);
      }
    }

    // for (let key of formData.entries()) {
    //   console.log(key[0] + ', ' + key[1]);
    //  }

    HTTP.post('/inquiry/create-new-message', formData)
      .then((res) => {
        resetForm();
        attachesRef.current.value = null;
        loadList(1);
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.message, 'error', 2000);
        } else {
          setErrors('An error occurred while sending the message.', 'error', 2000);
        }
      });
  };

  const closeDialog = () => {
    navigate(-1);
  };

  return (
    <>
      <Card className='h-100'>
        <Card.Header>
          <h4 className='card-heading'>Chat with us</h4>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <div className={'mt-0 mb-0 thread-messages-wrapper'}>
                <MessagesList
                  threadInfo={threadDetails}
                  // isLoading={isLoading}
                  className={'p-4 mb-0'}
                  ref={messageListRef}
                  height={400}
                  messageslistData={messagesDataList}
                />
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Label className='text-uppercase mt-1' htmlFor='thread'>
                    Write a message
                  </Form.Label>

                  <Form.Control
                    as='textarea'
                    name='message'
                    type='textarea'
                    // placeholder="Input your message
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`form-control message-text-input ${
                      formik.errors.message && formik.touched.message && 'error'
                    }`}
                  />
                  {formik.errors.message && formik.touched.message && (
                    <div className='input-feedback'>{formik.errors.message}</div>
                  )}

                  <Form.Label className='text-uppercase mt-1' htmlFor='thread'>
                    Attach
                  </Form.Label>

                  <input
                    ref={attachesRef}
                    name='attaches'
                    type='file'
                    multiple
                    onChange={(e) => {
                      formik.setFieldValue('attaches', e.target.files);
                    }}
                    onBlur={formik.handleBlur}
                    className={`form-control message-file-input ${
                      formik.errors.attaches && formik.touched.attaches && 'error'
                    }`}
                  />
                  {formik.errors.attaches && formik.touched.attaches && (
                    <div className='input-feedback'>{formik.errors.attaches}</div>
                  )}

                  <div style={{ textAlign: 'end' }}>
                    <Button type={'submit'} width={'auto'} disabled={formik.isSubmitting}>
                      Send
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <div className='float-end'>
            <Button variant='outline-secondary' onClick={closeDialog}>
              Close
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};

export default ThreadMessages;
