import React from "react"
import { Spinner } from "react-bootstrap"

const LoadPanel = ({ visible = true }) => {
  if (visible) {
    return (
      <div className={"loadpanel-wrapper"}>
        <div className={"loadpanel-content"}>
          <Spinner
            className={"loadpanel-spinner"}
            animation="border"
            variant="primary"
          />
        </div>
      </div>
    )
  }
  return <></>
}

export default LoadPanel