import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Col, Row, InputGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import * as Yup from 'yup';
import Execute from './ModalForms/ChangePassword/Execute';

const ChangePasswordForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      passwordOne: '',
      passwordTwo: '',
    },
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 500));
      // alert(JSON.stringify(values, null, 2))
      // navigate('/', { replace: true })
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required('Required'),
      passwordOne: Yup.string()
        .required('No password provided.')
        .min(4, 'Password is too short - should be 12 chars minimum.')
        .matches(/(?=.*[0-9])/, 'Password must contain a number.'),
      passwordTwo: Yup.string().required('Required'),
    }),
  });

  const cancelExecution = () => {
    navigate(-1);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className='mb-3'>
        <Col md={4}>
          <Form.Label className='text-uppercase mt-1' htmlFor='oldPassword'>
            Old Password
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            id='oldPassword'
            name='oldPassword'
            type='password'
            // placeholder="New Password"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.oldPassword && formik.touched.oldPassword && 'error'}`}
          />
          {formik.errors.oldpassword && formik.touched.oldpassword && (
            <div className='input-feedback'>{formik.errors.oldpassword}</div>
          )}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col md={4}>
          <Form.Label className='text-uppercase mt-1' htmlFor='passwordOne'>
            New Password
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            id='passwordOne'
            name='passwordOne'
            type='password'
            // placeholder="New Password"
            value={formik.values.newpasswordone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.passwordOne && formik.touched.passwordOne && 'error'}`}
          />
          {formik.errors.passwordOne && formik.touched.passwordOne && (
            <div className='input-feedback'>{formik.errors.passwordOne}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={4}>
          <Form.Label className='text-uppercase mt-1' htmlFor='passwordTwo'>
            Repeat Password
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            id='passwordTwo'
            name='passwordTwo'
            type='password'
            // placeholder="Repeat Password"
            value={formik.values.passwordTwo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.passwordTwo && formik.touched.passwordTwo && 'error'}`}
          />
          {formik.errors.passwordTwo && formik.touched.passwordTwo && (
            <div className='input-feedback'>{formik.errors.passwordTwo}</div>
          )}
        </Col>
      </Row>
      <div className='mb-3'>
        <div className='float-end'>
          <Execute
            oldPassword={formik.values.oldPassword}
            oldPasswordError={formik.errors.oldPassword}
            passwordOne={formik.values.passwordOne}
            passwordOneError={formik.errors.passwordOne}
            passwordTwo={formik.values.passwordTwo}
            passwordTwoError={formik.errors.passwordTwo}
            user={user}
          />{' '}
          <Button variant='secondary' onClick={cancelExecution}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ChangePasswordForm;
