import { saveAs } from 'file-saver';
import xssFilters from 'xss-filters';

const setLocalStream = (stream, mirrorMode = true) => {
  const localVidElem = document.getElementById('local');

  localVidElem.srcObject = stream;
  mirrorMode ? localVidElem.classList.add('mirror-mode') : localVidElem.classList.remove('mirror-mode');
};

const adjustVideoElemSize = () => {
  let elem = document.getElementsByClassName('card');
  let totalRemoteVideosDesktop = elem.length;
  let newWidth =
    totalRemoteVideosDesktop === 1
      ? '68%'
      : totalRemoteVideosDesktop === 2
      ? '40%'
      : totalRemoteVideosDesktop === 3
      ? '33.33%'
      : totalRemoteVideosDesktop <= 8
      ? '25%'
      : totalRemoteVideosDesktop <= 15
      ? '20%'
      : totalRemoteVideosDesktop <= 18
      ? '16%'
      : totalRemoteVideosDesktop <= 23
      ? '15%'
      : totalRemoteVideosDesktop <= 32
      ? '12%'
      : '10%';

  for (let i = 0; i < totalRemoteVideosDesktop; i++) {
    // console.log(i, elem[i].nodeName);
    if (elem[i].nodeName === 'ITEM') {
      elem[i].style.width = newWidth;
    }
  }
};

const closeVideo = (elemId) => {
  if (document.getElementById(elemId)) {
    document.getElementById(elemId).remove();
    adjustVideoElemSize();
  }
};

const pageHasFocus = () => {
  return !(document.hidden || document.onfocusout || window.onpagehide || window.onblur);
};

const userMediaAvailable = () => {
  return !!(
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  );
};

const getUserFullMedia = () => {
  if (userMediaAvailable()) {
    return navigator.mediaDevices.getUserMedia({
      video: true,
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
      },
    });
  } else {
    throw new Error('User media not available');
  }
};

const getUserAudio = () => {
  if (userMediaAvailable()) {
    return navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
      },
    });
  } else {
    throw new Error('User media not available');
  }
};

const shareScreen = () => {
  if (userMediaAvailable()) {
    return navigator.mediaDevices.getDisplayMedia({
      video: {
        cursor: 'always',
      },
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        sampleRate: 44100,
      },
    });
  } else {
    throw new Error('User media not available');
  }
};

const getIceServer = () => {
  return {
    iceServers: [
      {
        urls: [process.env.REACT_APP_ICE_STUN_URL],
      },
      {
        username: process.env.REACT_APP_ICE_USERNAME,
        credential: process.env.REACT_APP_ICE_CREDENTIAL,
        urls: [process.env.REACT_APP_ICE_TURN_URL_1],
      },
    ],
  };
};

const addChat = (data, senderType) => {
  function convertDate(date) {
    let time = new Date(date - new Date(date).getTimezoneOffset() * 60000).toISOString().substring(0, 16);

    return time.replace('T', ' ');
  }

  let chatMsgDiv = document.querySelector('#chat-messages');
  let contentAlign = 'justify-content-end';
  let senderName = 'You';
  let msgBg = 'bg-white';

  if (senderType === 'remote') {
    contentAlign = 'justify-content-start';
    senderName = data.sender;
    msgBg = '';

    toggleChatNotificationBadge();
  }

  let infoDiv = document.createElement('div');
  infoDiv.className = 'sender-info';
  infoDiv.innerText = `${senderName} - ${convertDate(new Date())}`;

  let colDiv = document.createElement('div');
  colDiv.className = `col-10 card chat-card msg ${msgBg}`;
  colDiv.innerHTML = xssFilters.inHTMLData(data.msg);

  let rowDiv = document.createElement('div');
  rowDiv.className = `row ${contentAlign} mb-2`;

  colDiv.appendChild(infoDiv);
  rowDiv.appendChild(colDiv);

  chatMsgDiv.appendChild(rowDiv);

  /**
   * Move focus to the newly added message but only if:
   * 1. Page has focus
   * 2. User has not moved scrollbar upward. This is to prevent moving the scroll position if user is reading previous messages.
   */
  if (pageHasFocus) {
    rowDiv.scrollIntoView();
  }
};

const toggleChatNotificationBadge = () => {
  if (document.querySelector('#chat-pane').classList.contains('chat-opened')) {
    document.querySelector('#new-chat-notification').setAttribute('hidden', true);
  } else {
    document.querySelector('#new-chat-notification').removeAttribute('hidden');
  }
};

const replaceTrack = (stream, recipientPeer) => {
  let sender = recipientPeer.getSenders
    ? recipientPeer.getSenders().find((s) => s.track && s.track.kind === stream.kind)
    : false;

  return sender ? sender.replaceTrack(stream) : '';
};

const saveRecordedStream = (stream, user) => {
  let blob = new Blob(stream, { type: 'video/webm' });
  let file = new File([blob], `${user}-record.webm`);
  saveAs(file);
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const allHelpers = {
  setLocalStream,
  adjustVideoElemSize,
  closeVideo,
  pageHasFocus,
  userMediaAvailable,
  getUserFullMedia,
  getUserAudio,
  shareScreen,
  getIceServer,
  addChat,
  toggleChatNotificationBadge,
  replaceTrack,
  saveRecordedStream,
  sleep,
};

export default allHelpers;
