import React, { useContext, useState, useEffect } from 'react';

import { Button, Card, Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap';

import { useAuth } from '../../contexts/auth';
import useNavigate from 'react-router-dom';

export default function PayOnboarding(props) {
  const { signupStep, nextSignupStep, signupCompletion, doReloadUser } = props;
  const { signOut, user } = useAuth();

  const [onBoardingPaid, setOnBoardingPaid] = useState(false);

  function logoutHandler() {
    signOut();
  }

  const saveOnBoardingPaid = async () => {
    if (onBoardingPaid) {
      const clientData = {
        signupstage: 85,
        signupid: user.signupid,
        signup_personal: user.personal_account,
        onboardingfee_paid: onBoardingPaid,
      };

      const updated = await signupCompletion(clientData);
      console.log(updated);
      if (updated.updated) {
        await doReloadUser();
        nextSignupStep(updated.signupstage);
      }
    }
  };

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Comment: According to the prelimary Risk Level based on "Country Evaluation" the Onboarding Fee has to be
            paid now.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Onboarding Fee</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Form id='PayOnboarding'>
          <Row className='text-muted g-2 mt-0 mb-3'>
            <Col md>
              {/* <h3 className="mb-4">Login</h3> */}
              Part of the registration is to ensure that applicants are real and that they are eligable for the services
              offered. This comprises comprenhensive and often time consuming review and verification processes.
              Therefore it is now required to pay the Onboarding Fee.
            </Col>
          </Row>
          <Row className='text-muted g-2 mt-0 mb-3'>
            <Col md>Please transfer the following amount</Col>
          </Row>
          <Row className='text-muted g-2 mt-0 mb-3'>
            <Col md>
              {'EUR '} {user && user.onboardingfee}
            </Col>
          </Row>
          <Row className='text-muted g-2 mt-0 mb-3'>
            <Col md>to the Bank Account listed below:</Col>
          </Row>

          <Row className='text-muted g-2 mt-0 mb-0'>
            <Col md>{'FinPortal GmbH'}</Col>
          </Row>
          <Row className='text-muted g-2 mt-0 mb-0'>
            <Col md>{'Bank: Com-Bank'}</Col>
          </Row>
          <Row className='text-muted g-2 mt-0 mb-0'>
            <Col md>{'IBAN: DE87 3434 3467 1200 0034 55'}</Col>
          </Row>
          <Row className='text-muted g-2 mt-0 mb-3'>
            <Col md>{'Subject: ID XYZ453345k '}</Col>
          </Row>

          <Row className='text-muted g-2 mt-0 mb-5'>
            <Col md>
              We will notify you as soon as we received the amount and you can continue with the registration process.
            </Col>
          </Row>

          <Row>
            <Col>
              <div className='form-floating mb-3'>
                <Form.Group>
                  <Form.Check
                    label='Paid the OnBoarding Fee'
                    type='checkbox'
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setOnBoardingPaid(e.target.checked);
                    }}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button
                  variant='primary'
                  size='lg'
                  // type="submit"
                  disabled={!onBoardingPaid}
                  onClick={saveOnBoardingPaid}
                >
                  Continue
                </Button>{' '}
                <Button
                  variant='outline-primary'
                  size='lg'
                  // type="submit"
                  // disabled={isSubmitting}
                  onClick={logoutHandler}
                >
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
