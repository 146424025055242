import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IMaskInput } from 'react-imask';

import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';

// import Form, {
//   Item,
//   Label,
//   ButtonItem,
//   ButtonOptions,
//   RequiredRule,
//   EmailRule,
//   EmptyItem,
// } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
// import { Container, Row, Col, Card } from 'react-bootstrap';
import CustomButton from '../../components/ui/CustomButton';

import './LoginForm.scss';

const LoginForm = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', password: '' });

  // const onSubmit = useCallback(
  //   async (e) => {
  //     e.preventDefault();
  //     const { email, password } = formData.current;
  //     setLoading(true);

  //     const result = await signIn(email, password);
  //     if (!result.isOk) {
  //       setLoading(false);
  //       notify(result.message, 'error', 2000);
  //     }
  //   },
  //   [signIn]
  // );

  const onCreateAccountClick = useCallback(() => {
    navigate('/create-account', {
      state: {
        partnerid: 8, // Partnerid determines Partner of Client
      },
    });
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required('Required'),
      password: Yup.string().required('Required'),
    }),

    onSubmit: async (values) => {
      console.log('Log in', values);

      const result = await signIn(values.email, values.password);
      if (!result.isOk) {
        setLoading(false);
        notify(result.message, 'error', 2000);
      }
    },
  });

  return (
    <Container fluid>
      {/* <form className={'login-form'} onSubmit={onSubmit}> */}
      <Row>
        <Col className='mx-auto mb-5'>
          <Card>
            <Card.Header>
              <h3 className='card-heading'>Login</h3>
            </Card.Header>
            <Card.Body>
              <Form id='loginForm' onSubmit={formik.handleSubmit}>
                <Row className='g-2'>
                  <Col md>
                    <div className='form-floating mb-3'>
                      <Form.Control
                        id='email'
                        name='email'
                        type='email'
                        placeholder='name@example.com'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={formik.errors.email && formik.touched.email && 'error'}
                      />
                      <Form.Label htmlFor='email'>E-Mail</Form.Label>
                      {formik.errors.email && formik.touched.email && (
                        <div className='input-feedback'>{formik.errors.email}</div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className='g-2'>
                  <Col md>
                    <div className='form-floating mb-3'>
                      <Form.Control
                        id='password'
                        name='password'
                        type='password'
                        placeholder='Password'
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={formik.errors.password && formik.touched.password && 'error'}
                      />
                      <Form.Label htmlFor='password'>Password</Form.Label>
                      {formik.errors.password && formik.touched.password && (
                        <div className='input-feedback'>{formik.errors.password}</div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='float-end'>
                      <Button
                        variant='primary'
                        size='small'
                        type='submit'
                        disabled={formik.isSubmitting}
                        // onClick={formik.onSubmit}
                      >
                        Login
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>

              {/* <Row>
                  <Col>
                    <Form formData={formData.current} disabled={loading}>
                      <Item dataField='email' editorType='dxTextBox' editorOptions={emailEditorOptions}>
                        <RequiredRule message='Email is required' />
                        <EmailRule message='Email is invalid' />
                        <Label visible={false} />
                      </Item>
                      <Item dataField='password' editorType='dxTextBox' editorOptions={passwordEditorOptions}>
                        <RequiredRule message='Password is required' />
                        <Label visible={false} />
                      </Item>
                      <EmptyItem />
                      <ButtonItem>
                        <ButtonOptions width='100%' type='default' useSubmitBehavior={true}>
                          <span className='dx-button-text'>
                            {loading ? <LoadIndicator width='24px' height='24px' visible={true} /> : 'Log In'}
                          </span>
                        </ButtonOptions>
                      </ButtonItem>
                      <EmptyItem />
                      <ButtonItem>
                        <ButtonOptions text={'Sign Up'} width={'100%'} onClick={onCreateAccountClick} />
                      </ButtonItem>
                    </Form>
                  </Col>
                </Row> */}
            </Card.Body>
            <Card.Footer className='px-lg-5 py-lg-4'>
              <div className='flex-container-end'>
                <div className='text-sm text-muted marg-top-10'>Don't have an account?</div>
                <Button variant='link' size='small' onClick={onCreateAccountClick}>
                  Signup
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      {/* </form> */}
    </Container>
  );
};

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };

export default LoginForm;
