import React, { useContext, useEffect, useState } from 'react';
import HTTP from '../../api/HTTP';
import { Formik, Field, useFormik } from 'formik';
import { Form, Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

export default function CreateInquiryPopUp(props) {
  const { cancelCreateInquiry, user } = props;
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    onSubmit: { handleSubmitForm },
    initialValues: {
      subject: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      subject: Yup.string().required('Required'),
      message: Yup.string().required('Required'),
    }),
  });

  const postClientInquiry = async (subject, message) => {
    // setLoading(true)

    await HTTP.post(
      `/inquiry/upload-document`,
      JSON.stringify({
        clientid: user.clientid,
        subject: subject,
        inquirytext: message,
        userid: -1,
      })
    )
      .then(() => {
        console.log('Inquiry created');
        cancelCreateInquiry();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitForm = async (data) => {
    await postClientInquiry(data.subject, data.message);
  };

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Row className='mb-3'>
        <Col md={12}>
          <Form.Label className='text-uppercase mt-1' htmlFor='subject'>
            subject
          </Form.Label>
          <Form.Control
            id='subject'
            name='subject'
            type='text'
            placeholder='Subject'
            value={formik.values.theme}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.subject && formik.touched.subject && 'error'}`}
          />
          {formik.errors.subject && formik.touched.subject && (
            <div className='input-feedback'>{formik.errors.subject}</div>
          )}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col md={12}>
          <Form.Label className='text-uppercase mt-1' htmlFor='inquiry'>
            message
          </Form.Label>
          <Form.Control
            as='textarea'
            id='message'
            name='message'
            type='textarea'
            placeholder='Your message'
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{ height: '350px' }}
            className={`form-control ${formik.errors.message && formik.touched.message && 'error'}`}
          />
          {formik.errors.message && formik.touched.message && (
            <div className='input-feedback'>{formik.errors.message}</div>
          )}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col md={12}>
          <div className={'float-end'}>
            <Button type='submit'>Send</Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
