import { getFileExtension } from "../../utils/attach-helper"

const ONE_KILOBYTE = 1024
const ONE_MEGABYTE = ONE_KILOBYTE * ONE_KILOBYTE
export const ALLOWED_EXTENSION = ["doc", "docx", "jpg", "jpeg", "pdf", "png", "bmp", "xls", "xlsx"]
export const ALLOWED_FILE_SIZE_MB = 1
export const ERROR_MESSAGES = {
  invalidSize: `File is too large. Max filesize is ${ALLOWED_FILE_SIZE_MB} MB`,
  invalidExtension:
    "Unsupported file type, allowed only (" +
    ALLOWED_EXTENSION.join(", ") +
    ")",
}

class ValidationHelper {
  constructor() {
    this.fileMaxSize = ALLOWED_FILE_SIZE_MB * ONE_MEGABYTE
    this.errorMessages = []
  }

  validateFileSize = file => {
    if (file.size > this.fileMaxSize) {
      this.errorMessages.push(ERROR_MESSAGES.invalidSize)
      return ERROR_MESSAGES.invalidSize
    }
    return ""
  }

  validateFileExtension = file => {
    const ext = getFileExtension(file.name)
    if (!ALLOWED_EXTENSION.includes(ext)) {
      this.errorMessages.push(ERROR_MESSAGES.invalidExtension)
      return ERROR_MESSAGES.invalidExtension
    }

    return ""
  }

  clearMessageList = () => {
    this.errorMessages = []
  }

  runAllValidations = file => {
    this.validateFileSize(file)
    this.validateFileExtension(file)

    if (this.errorMessages.length) {
      const result = this.errorMessages.join(". ")
      this.clearMessageList()
      return result
    }
    return ""
  }
}

export default new ValidationHelper()

export const checkFileListByCallback = (files, callback) => {
  if (!files instanceof FileList) {
    throw new Error("Error, object is not FileList")
  }

  for (let i = 0; i < files.length; i++) {
    const result = callback(files[i])
    if (result !== "") {
      return false
    }
  }
  return true
}