import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const InviteRequest = (props) => {
  const { clientid, userfirstname, userlastname, inviteid } = props;
  const [inviteAccount, setInviteAccount] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInviteAccount = async () => {
      const response = await fetch(`/api/client-invited/invite-account/${inviteid}`, {
        method: 'GET',
      });
      const data = await response.json();
      setInviteAccount(data[0].cl_email);
    };
    if (inviteid) {
      fetchInviteAccount();
    }
  }, [inviteid]);

  async function acceptInvite() {
    const response = await fetch('/api/client-invited/acceptinvite', {
      method: 'POST',
      body: JSON.stringify({
        inviteid: inviteid,
        clientid: clientid,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    console.log(data);
    navigate('/', { replace: true });
  }

  return (
    <section className='mb-4 mb-lg-4'>
      <Row>
        <Col md={12}>
          <Card className='h-100'>
            <Card.Header>
              <h4 className='card-heading'>Invite Request</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <p>
                    You reiceived an <span className='fw-bold'>invite request</span> from the account{' '}
                    <span className='fw-bold'>"{inviteAccount}"</span>
                  </p>
                  <p>
                    Following acceptance you can log in to this account using your first and lastname in front of the
                    "account login" separated via dots. <br /> Your <span className='fw-bold'>login</span> for "
                    {inviteAccount}" is{' '}
                    <span className='fw-bold'>
                      {' '}
                      {userfirstname}.{userlastname}.{inviteAccount}{' '}
                    </span>
                    <br />
                    Your <span className='fw-bold'>Password</span> is the{' '}
                    <span className='fw-bold'>same like on this account</span> (it can be managed via Account of
                    this/your Account)
                    <br />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12} xl={12} className='mt-2'>
                  <div className='float-end'>
                    <Button
                      variant='primary'
                      // size="sm"
                      onClick={acceptInvite}
                    >
                      Accept
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default InviteRequest;
