import { Container, Nav, NavLink, Navbar, NavbarBrand } from 'react-bootstrap';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import CustomButton from '../../components/ui/CustomButton';
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
  const { activeSection, handleNavLink } = props;

  const navigate = useNavigate();

  const handleBrandClick = () => {
    navigate('/');
  };

  return (
    <div className='nav-wrapper'>
      <Container>
        <Navbar className='nav' collapseOnSelect expand='lg'>
          <NavbarBrand
            className='nav-logo'
            style={{
              cursor: 'pointer',
            }}
            onClick={handleBrandClick}
          >
            <img src='/img/logo-v4.svg' />
          </NavbarBrand>
          <NavbarToggle aria-controls='responsive-navbar-nav'>
            <img src='/img/burger.svg' />
          </NavbarToggle>
          <NavbarCollapse className='me-auto'>
            <Nav className='ms-auto'>
              <NavLink
                className={activeSection === 'home' ? 'selected' : ''}
                href='#home'
                data-name='home'
                onClick={handleNavLink}
              >
                Home
              </NavLink>
              <NavLink
                className={activeSection === 'products' ? 'selected' : ''}
                href='#products'
                data-name='products'
                onClick={handleNavLink}
              >
                Products
              </NavLink>
              <NavLink
                className={activeSection === 'banking' ? 'selected' : ''}
                href='#banking'
                data-name='banking'
                onClick={handleNavLink}
              >
                Banking
              </NavLink>
              <NavLink
                className={activeSection === 'aiclub' ? 'selected' : ''}
                href='#aiclub'
                data-name='aiclub'
                onClick={handleNavLink}
              >
                AI Club
              </NavLink>
              <NavLink
                className={activeSection === 'whyus' ? 'selected' : ''}
                href='#whyus'
                data-name='whyus'
                onClick={handleNavLink}
              >
                Why Us
              </NavLink>
              <NavLink
                className={activeSection === 'experts' ? 'selected' : ''}
                href='#experts'
                data-name='experts'
                onClick={handleNavLink}
              >
                Experts
              </NavLink>
              <NavLink href='#joinwallet'>
                <CustomButton variant='main-dark' size='small'>
                  Join Waitlist
                </CustomButton>
              </NavLink>
              <NavLink>
                <CustomButton variant='secondary-dark' size='small' onClick={() => navigate('/login')}>
                  Signup/Login
                </CustomButton>
              </NavLink>
            </Nav>
          </NavbarCollapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
