import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts';
import { SingleCardCptv } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, SignupStart } from './components';
import { LandingPage } from './pages';
import PolicyPage from './pages/landing/policy';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/policy' element={<PolicyPage />} />
      <Route
        path='/login'
        element={
          <SingleCardCptv title='Sign In'>
            <LoginForm />
          </SingleCardCptv>
        }
      />
      <Route
        path='/create-account'
        element={
          <SingleCard title='Sign Up'>
            <SignupStart />
          </SingleCard>
        }
      />
      <Route
        path='/reset-password'
        element={
          <SingleCard
            title='Reset Password'
            description='Please enter the email address that you used to register, and we will send you a link to reset your password via Email.'
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route
        path='/change-password/:recoveryCode'
        element={
          <SingleCard title='Change Password'>
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
