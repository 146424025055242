import { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../contexts/auth';

import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row } from 'react-bootstrap';

// import Breadcrumbs from '../../components/Breadcrumbs';
import Inquiry from './Inquiry';

const InquiriesPage = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/*
      <Breadcrumbs title='Documents' />
       */}
      <section>
        <Row>
          <Col lg={12}>
            {user.selInquiryId && (
              <Inquiry inquiryId={user.selInquiryId} user={user} showChatArea={false} signupDlg={true} />
            )}
            {/* {inquiryId && <ThreadInquiries inquiryId={inquiryId} />} */}
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default InquiriesPage;
