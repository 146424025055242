import React, { useState, createContext, useContext } from "react"

const ClientContext = createContext({
  user: null,
  saveUser: function (userData) {},
  selCard: null,
  saveCard: function (cardData) {},
  selReport: null,
  saveReport: function (reportData) {},

  countNewAlertNotifications: null,
  saveCountNewAlertNotifications: function (qty) {},
})

export function UserContextProvider(props) {
  const [activeUser, setActiveUser] = useState()
  const [activeCard, setActiveCard] = useState()
  const [activeReport, setActiveReport] = useState()
  const [countNewAlertNotifications, setCountNewAlertNotifications] = useState()

  function saveActiveUser(userData) {
    setActiveUser(userData)
  }

  function saveActiveCard(cardData) {
    setActiveCard(cardData)
  }

  function saveActiveReport(reportData) {
    setActiveReport(reportData)
  }

  function saveCountNewAlertNotifications(qty) {
    setCountNewAlertNotifications(qty)
  }

  const context = {
    user: activeUser,
    saveUser: saveActiveUser,
    selCard: activeCard,
    saveCard: saveActiveCard,
    selReport: activeReport,
    saveReport: saveActiveReport,

    countNewAlertNotifications: countNewAlertNotifications,
    saveCountNewAlertNotifications: saveCountNewAlertNotifications,
  }

  return (
    <ClientContext.Provider value={context}>
      {props.children}
    </ClientContext.Provider>
  )
}

export default ClientContext
