import { useState, useEffect} from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row } from 'react-bootstrap';

import Breadcrumbs from '../components/Breadcrumbs';
import Inquiry from '../pages/inquiries/Inquiry';
import ClientContext from '../store/client-context';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

const SignupDocuments = (props) => {
  const { inquiryId } = props;
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
     {/*
      <Breadcrumbs title='Signup Documents' />
       */} 
      <section>
        <Row>
          <Col lg={12}>{inquiryId && <Inquiry inquiryId={inquiryId} user={user} showChatArea={false} signupDlg={false} />}</Col>
        </Row>
      </section>
    </Container>
  );
};

export default SignupDocuments;
