import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';

export default function NoProductAvailable(props) {
  const { signupStep } = props;
  const { signOut, user } = useAuth();

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Registration</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Form id='NoProductAvailable'>
          <Row className='mb-3'>
            {' '}
            <Col>
              <p>
                Unfortunately we do not have a product available in your Region. Therefore we can not continue the
                signup process.
              </p>
            </Col>
          </Row>
          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button
                  variant='primary'
                  size='lg'
                  // type="submit"
                  disabled={true}
                >
                  Continue
                </Button>{' '}
                <Button
                  variant='outline-primary'
                  size='lg'
                  // type="submit"
                  // disabled={isSubmitting}
                  onClick={logoutHandler}
                >
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
