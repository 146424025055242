import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row, Button } from 'react-bootstrap';

// import Breadcrumbs from "../../components/Breadcrumbs"
import InquiriesList from './InquiriesList';

const InquiriesAll = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate('/home');
    }
  }, [user]);

  const closeInquiryList = () => {
    navigate(-1);
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/* 
      <Breadcrumbs title={'Documents'} />
      */}
      <section>
        <Row>
          <Col>
            <InquiriesList user={user} />
          </Col>
        </Row>
        <Row className='mb-5'>
          <Col>
            <div className='float-end'>
              <Button variant='outline-secondary' onClick={closeInquiryList}>
                Close
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default InquiriesAll;
