const Topic3 = () => {
  return (
    <>
      <h2 className="policy-title">
        TOPIC #3
      </h2>

      <h4 className="policy-subtitle">
        General INFORMATION
      </h4>

      <p className="policy-text">
        The FinPortal website uses cookies. By continuing to use this website, you are giving consent to cookies being used. Last updated 1 December 2020 www.FinPortal.com is the website domain of Swissmoney AG. The data controller is Swissmoney AG, a payment Institution registered with the Swiss Companies Register under the company number CHE-105.854.576, with its registered address at Rue des Alpes 11, CH-1700 Freiburg, Switzerland. In this Cookie Statement, ‘FinPortal’, ‘we’, ‘us’ and ‘our’ means SwissmoneyAG (and its brand FinPortal). This Cookie Statement explains how FinPortal uses cookies and similar technologies when you visit our websites located at www.FinPortal.com, or any other websites, pages, features, or content we own or operate (collectively, the “Site(s)”), when you use the FinPortal mobile app, and/or interact with FinPortal online advertisements or marketing emails (collectively the “Services”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
      </p>

      <h4 className="policy-subtitle">
        General INFORMATION
      </h4>

      <p className="policy-text">
        The FinPortal website uses cookies. By continuing to use this website, you are giving consent to cookies being used. Last updated 1 December 2020 www.FinPortal.com is the website domain of Swissmoney AG. The data controller is Swissmoney AG, a payment Institution registered with the Swiss Companies Register under the company number CHE-105.854.576, with its registered address at Rue des Alpes 11, CH-1700 Freiburg, Switzerland. In this Cookie Statement, ‘FinPortal’, ‘we’, ‘us’ and ‘our’ means SwissmoneyAG (and its brand FinPortal). This Cookie Statement explains how FinPortal uses cookies and similar technologies when you visit our websites located at www.FinPortal.com, or any other websites, pages, features, or content we own or operate (collectively, the “Site(s)”), when you use the FinPortal mobile app, and/or interact with FinPortal online advertisements or marketing emails (collectively the “Services”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
      </p>

      <h4 className="policy-subtitle">
        General INFORMATION
      </h4>

      <p className="policy-text">
        The FinPortal website uses cookies. By continuing to use this website, you are giving consent to cookies being used. Last updated 1 December 2020 www.FinPortal.com is the website domain of Swissmoney AG. The data controller is Swissmoney AG, a payment Institution registered with the Swiss Companies Register under the company number CHE-105.854.576, with its registered address at Rue des Alpes 11, CH-1700 Freiburg, Switzerland. In this Cookie Statement, ‘FinPortal’, ‘we’, ‘us’ and ‘our’ means SwissmoneyAG (and its brand FinPortal). This Cookie Statement explains how FinPortal uses cookies and similar technologies when you visit our websites located at www.FinPortal.com, or any other websites, pages, features, or content we own or operate (collectively, the “Site(s)”), when you use the FinPortal mobile app, and/or interact with FinPortal online advertisements or marketing emails (collectively the “Services”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
      </p>

      <h4 className="policy-subtitle">
        General INFORMATION
      </h4>

      <p className="policy-text">
        The FinPortal website uses cookies. By continuing to use this website, you are giving consent to cookies being used. Last updated 1 December 2020 www.FinPortal.com is the website domain of Swissmoney AG. The data controller is Swissmoney AG, a payment Institution registered with the Swiss Companies Register under the company number CHE-105.854.576, with its registered address at Rue des Alpes 11, CH-1700 Freiburg, Switzerland. In this Cookie Statement, ‘FinPortal’, ‘we’, ‘us’ and ‘our’ means SwissmoneyAG (and its brand FinPortal). This Cookie Statement explains how FinPortal uses cookies and similar technologies when you visit our websites located at www.FinPortal.com, or any other websites, pages, features, or content we own or operate (collectively, the “Site(s)”), when you use the FinPortal mobile app, and/or interact with FinPortal online advertisements or marketing emails (collectively the “Services”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
      </p>

      <h4 className="policy-subtitle">
        General INFORMATION
      </h4>

      <p className="policy-text">
        The FinPortal website uses cookies. By continuing to use this website, you are giving consent to cookies being used. Last updated 1 December 2020 www.FinPortal.com is the website domain of Swissmoney AG. The data controller is Swissmoney AG, a payment Institution registered with the Swiss Companies Register under the company number CHE-105.854.576, with its registered address at Rue des Alpes 11, CH-1700 Freiburg, Switzerland. In this Cookie Statement, ‘FinPortal’, ‘we’, ‘us’ and ‘our’ means SwissmoneyAG (and its brand FinPortal). This Cookie Statement explains how FinPortal uses cookies and similar technologies when you visit our websites located at www.FinPortal.com, or any other websites, pages, features, or content we own or operate (collectively, the “Site(s)”), when you use the FinPortal mobile app, and/or interact with FinPortal online advertisements or marketing emails (collectively the “Services”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
      </p>
    </>
  );
}

export default Topic3;