import { Button } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useCallback, useEffect, useState } from 'react';
import MessagingHelper from './MessagingHelper';
import moment from 'moment';
import MessagesList from './MessagesList';
import ChatFooter from './ChatFooter';
import { useNavigate } from 'react-router-dom';
import { useMessaging } from '../../../contexts/messaging';

const MESSAGES_PERS_PAGE = 20;

const ChatBody = (props) => {
  const {
    user,
    selectedChatData,
    readMessagesHandler,
  } = props;

  const navigate = useNavigate();
  const {
    setDoUpdateMessagesList,
    doUpdateMessagesList,
    doReadMessages,
    setDoReadMessages,
  } = useMessaging();

  const [messagesList, setMessagesList] = useState([]);
  const [lastMessageIdOnPage, setLastMessageIdOnPage] = useState(null);
  const [firstMessageIdOnPage, setFirstMessageIdOnPage] = useState(null);
  const [messageIdToReply, setMessageIdToReply] = useState(-1);
  const [messageToReply, setMessageToReply] = useState(null);
  const [messagesNumber, setMessagesNumber] = useState(0);
  const [hasMoreStatus, setHasMoreStatus] = useState(false);
  const [unreadMessagesList, setUnreadMessagesList] = useState([]);

  useEffect(() => {
    if (doUpdateMessagesList) {
      getNewMessages();
      setDoUpdateMessagesList(false);
    }
  }, [doUpdateMessagesList]);

  useEffect(() => {
    setMessageIdToReply(-1);
    setLastMessageIdOnPage(null);
    setMessagesNumber(0);
    setMessageToReply(null);

    //  console.log(4000, selectedChatData);

    if (selectedChatData) {
      setMessagesList([]);
      getMessagesList(selectedChatData.threadId, null);
    }
  }, [selectedChatData]);

  useEffect(() => {
    if (doReadMessages) {
      handleReadMessages();
      setDoReadMessages(false);
    }
  }, [doReadMessages]);

  const handleReadMessages = useCallback(() => {
    setMessagesList((prevState) =>
      prevState.map((msg) => {
        msg.unread = false;
        return msg;
      })
    );
  });

  const newMessageReseivedHandler = useCallback((message) => {
    if (Number(message.threadId) === Number(selectedChatData?.threadId)) {
      setFirstMessageIdOnPage(message.id);
      setMessagesList((prevState) => [...new Set([].concat(message, prevState))]);
    }
  });

  const getNewMessages = () => {
    MessagingHelper.getNewThreadMessages(selectedChatData?.threadId, {
      messageId: firstMessageIdOnPage,
    }).then(response => {
      setFirstMessageIdOnPage(response[0].id);
      setMessagesList((oldMessagesList) => response.concat(oldMessagesList));
      setUnreadMessagesList((prevState) => [...prevState, ...response]);
    });
  };

  const getMessagesList = async (threadId, messageId = lastMessageIdOnPage) => {
    MessagingHelper.getThreadMessages(threadId, {
      lastMessage: messageId,
      limit: MESSAGES_PERS_PAGE,
    }).then((response) => {
      const lastMessageId = response.items[response.items.length - 1]?.id;

      if (messagesList.length > 0) {
        setFirstMessageIdOnPage(messagesList[0].id);
      } else {
        setFirstMessageIdOnPage(response.items[0].id);
      }

      setHasMoreStatus(Number(response.meta.lastItemId) !== Number(lastMessageId));

      setMessagesNumber((prevState) => prevState + response.meta.itemCount);
      setMessagesList((oldMessagesList) => oldMessagesList.concat(response.items));
      setLastMessageIdOnPage(Number(lastMessageId));
    });
  };

  const groupedMessagesList = messagesList.reduce((acc, el, i) => {
    const messageDay = moment(el.createdAt).format('YYYY-MM-DD');
    if (acc[messageDay]) {
      return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
    }
    return { ...acc, [messageDay]: [el] };
  }, {});

  useEffect(() => {
    if (messageIdToReply) {
      const messageToReplyList = messagesList.filter((message) => {
        return message.id == messageIdToReply;
      });
      setMessageToReply(messageToReplyList[0]);
    }
  }, [messageIdToReply]);

  const handleRemoveMessageToReply = () => {
    setMessageToReply(null);
    setMessageIdToReply(-1);
  };

  return (
    <div
      className='chat-body mx-auto'
      style={{
        maxHeight: 'calc(100vh - 120px)',
      }}
    >
      <Toolbar className='chat-header'>
        <Item visible={selectedChatData} location={'before'}>
          <div className='chat-user-info'>
            <div className='chat-user-name'>{selectedChatData?.chatTitle}</div>
          </div>
        </Item>

        <Item visible={selectedChatData} location='after'>
          <Button
            className='chat-actions'
            onClick={() => navigate(-1)}
            width={30}
            height={30}
            stylingMode='text'
            type='default'
            icon='remove'
          />
        </Item>
      </Toolbar>

      {selectedChatData ? (
        <>
          <div
            className='chat-content'
            style={{
              maxHeight: `calc(100% - 50px - ${messageToReply ? '122px' : '66px'})`,
              height: `calc(100% - 50px - ${messageToReply ? '122px' : '66px'})`,
            }}
          >
            <div className='messages-container-wrapper'>
              {messagesList.length === 0 ? (
                <div className='empty-messages-container'>
                  <p>There are no any messages in chat</p>
                </div>
              ) : (
                <MessagesList
                  groupedMessagesList={groupedMessagesList}
                  setMessagesList={setMessagesList}
                  user={user}
                  selectedChatData={selectedChatData}
                  setMessageIdToReply={setMessageIdToReply}
                  loadMore={getMessagesList}
                  hasMore={hasMoreStatus}
                  messagesNumber={messagesNumber}
                  unreadMessagesList={unreadMessagesList}
                  setUnreadMessagesList={setUnreadMessagesList}
                  readMessagesHandler={readMessagesHandler}
                />
              )}
            </div>
          </div>
          <ChatFooter
            selectedChatData={selectedChatData}
            user={user}
            messageToReply={messageToReply}
            messageIdToReply={messageIdToReply}
            handleRemoveMessageToReply={handleRemoveMessageToReply}
            setMessageToReply={setMessageToReply}
            setMessageIdToReply={setMessageIdToReply}
            newMessageReseivedHandler={newMessageReseivedHandler}
          />
        </>
      ) : (
        <div className='chat-content'>
          <div className='messages-container'>
            <div className='empty-messages-container'>
              <p>Select user to start messaging</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBody;
