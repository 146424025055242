import { useEffect, useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, ListGroup, Toast, ToastContainer } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';

import { useNavigate, useLocation } from 'react-router-dom';
import HTTP from '../../api/HTTP';

const AgreeToServicesMain = () => {
  const { signOut, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const selProductid = location.state.productid;

  const [serviceProviders, setServiceProviders] = useState(null);
  const [agreetoServices, setAgreetoServices] = useState(false);

  useEffect(() => {
    try {
      async function getAllServiceProviders() {
        const response = await HTTP.get(`/signup/product-providers/${selProductid}`);
        setServiceProviders(response.data);
      }
      getAllServiceProviders();
    } catch (error) {
      console.log('getAllServices-error-', error);
    }
  }, []);

  async function createClientAtServiceProvider(client) {
    try {
      const response = await HTTP.post('/providers/create-client', JSON.stringify(client));
      console.log(response.data);
    } catch (error) {
      console.log('Creation of Client at Service Simulator failed ', error);
    }
  }

  async function completeSignup() {
    const response = await HTTP.get(`/signup/save-client-state/${user.clientid}/${selProductid}/10000`);
    const data = await response.data;
    console.log(data);
  }

  const acceptSignUp = async () => {
    if (agreetoServices === true) {
      completeSignup();

      for (let i = 0; i < serviceProviders.length; i++) {
        let client = {
          createclient: true,
          categoryident: serviceProviders[i].categoryident,
          providerid: serviceProviders[i].providerid,
          clientid: user.clientid,
          name: user.cl_lastname,
          firstname: user.cl_firstname,
          salutation: user.cl_salutation,
          email: user.cl_email,
          phone: user.cl_mobilephone,
          kyc: true,
          status: 'approved',
          login: 'logged in',
          password: user.cl_password,
        };

        await createClientAtServiceProvider(client);
      }
      // END: CREATE CLIENT AT SERVICE PROVIDER
      // console.log(1234, 'Push');
      // Now to save productid with Client

      navigate('/almost-there-main');
    }
  };

  function logoutHandler() {
    signOut();
  }

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Comment: Agreeing to accept the Service Providers of the selected Services as Business Partners
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Services Selection</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Row className='g-2 mb-0'>
          <Col className='form-floating mb-3'>
            <p>
              The following service providers provide the services of your product. They require that you accept this
              and that you accept their Terms of Use ahead of first use of the services. Additionally it might be
              required that you provide additional data according to financial regulations if requested by the Service
              Providers.
            </p>
          </Col>
        </Row>
        <Row className='g-2'>
          <Col md={4} className='form-floating mb-3'>
            <ul className='my-0'>
              {serviceProviders &&
                serviceProviders.map((item, index) => (
                  <li className='mb-2' key={index}>
                    {item.providername}
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        <Row className='g-2'>
          <Col md={3} className='form-floating mb-3'>
            <div>
              <Form.Check label='I Agree' type='checkbox' onChange={(e) => setAgreetoServices(e.target.checked)} />
            </div>
          </Col>
        </Row>
        <Row className='g-2 mt-0 mb-3 '>
          <Col md>
            <div className='float-end'>
              <Button variant='primary' size='lg' disabled={!agreetoServices} onClick={acceptSignUp}>
                Continue
              </Button>{' '}
              <Button variant='outline-primary' size='lg' onClick={logoutHandler}>
                Leave
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    </Container>
  );
};

export default AgreeToServicesMain;
