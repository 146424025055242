import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import HTTP from '../../../../../api/HTTP';

export default function Execute(props) {
  const {
    buyAssetError,
    buyAsset,
    buyAmountError,
    buyAmount,
    buyValueError,
    buyValue,
    buyFrom,
    user,
    selCard,
    accountid,
    assetid,
  } = props;

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const submitButtonRef = useRef(false);
  const [execTransactionid, setEexecTransactionid] = useState();
  const [clientFee, setClientFee] = useState();

  const toggleModal = () => {
    if (!buyAssetError && buyAsset !== '' && !buyAmountError && buyAmount !== '' && !buyValueError && buyValue !== '') {
      setShow(!show);
    }
  };

  const executeBuyAsset = async (offertype) => {
    console.log('-');
    console.log(user);
    console.log('-');
    console.log('---');
    console.log(selCard);
    console.log('---');
    console.log(66666, buyFrom);

    try {
      const response = await HTTP.post(
        '/ops/buy-asset',
        JSON.stringify({
          transactionid: execTransactionid,
          assetaccountid: selCard.assetaccountid,
          provider_accountid: selCard.provider_accountid,
          buyfrom_assetaccountid: buyFrom,
          buysellop: 1,
          offertype: offertype,
          stage: 5,
          clientid: user.clientid,
          serviceid: selCard.serviceid,
          providerid: selCard.providerid,
          partnerid: user.partnerid,
          productid: user.productid,
          routingid: selCard.routingid,
          bankaccountid: selCard.bankaccountid,
          categoryid: selCard.categoryid,
          categoryident: selCard.category_ident,
          assetid: selCard.assetid,
          assetcalcunit: selCard.assetcalcunit,
          assetamount: parseFloat(buyAmount),
          currencyamount: parseFloat(buyValue),
          currency: selCard.currency,
          reqfunds: buyValue,
          mb_assetid: selCard.mb_assetid,
          mb_serviceid: selCard.mb_serviceid,
          svassetid: selCard.svassetid,
          assetname: selCard.assetname,
        })
      );

      const data = await response.data;
      console.log(data);

      if (data.status === 'success') return true;
    } catch (error) {
      console.log('buy-asset-error', error);
    }
  };

  const DetermineFees = async () => {
    try {
      const response = await HTTP.post(
        '/ops/calc-transaction-fees',
        JSON.stringify({
          clientid: user.clientid,
          client_userid: user.loggedinclientid,
          riskid: user.client_risklevelid,
          buysellop: 1,
          assetaccountid: selCard.assetaccountid,
          currency: selCard.currency,
          assetamount: buyAmount,
          currencyamount: buyValue,
          svassetid: selCard.svassetid,
          assetid: selCard.assetid,
        })
      );

      const data = response.data;

      await setEexecTransactionid(data[0].transactionid);
      await setClientFee(data[1].calc_transaction_fees);

      console.log(data);

      toggleModal();
    } catch (error) {
      console.log('calc-transaction-fees-error', error);
    }
  };

  const handlSubmit = async () => {
    const buyAsset = await executeBuyAsset(1);

    if (buyAsset) {
      navigate(-1);
    } else {
      console.log('Buying of asset was not executed!');
    }
  };

  return (
    <React.Fragment>
      <Button variant='primary' type='submit' onClick={DetermineFees}>
        Execute
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Review Buy Asset
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure to make the buy with following values?</p>

          <Form onSubmit={handlSubmit}>
            <div className='mb-3'>
              <br />
              <p>Buy: {buyAsset}</p>
              <p>Amount: {buyAmount}</p>
              <p>
                For: {selCard.currency} {buyValue}
              </p>
              <p>
                Fees: {selCard.currency} {clientFee && clientFee}
              </p>{' '}
            </div>
            <Button variant='primary' className='d-none' type='submit' ref={submitButtonRef}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitButtonRef.current.click()}>
            Apply
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
