import { useEffect, useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, ListGroup, Toast, ToastContainer } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';

import useNavigate from 'react-router-dom';
import HTTP from '../../api/HTTP';

async function getProviderIdent(categoryid) {
  const response = await HTTP.get(`/providers/get-provider-ident/${categoryid}`);
  console.log(response.data);
  return response.data;
}

async function getProviderIds(servicename) {
  const response = await HTTP.get(`/providers/get-provider-ids/${servicename}`);
  console.log(response.data);
  return response.data;
}

async function getServiceProviders(services) {
  // Get providerids
  let providers = [];
  for (let i = 0; i < services.length; i++) {
    providers.push(await getProviderIds(services[i]));
  }

  // console.log(providers)

  // Get category_idents
  for (let i = 0; i < providers.length; i++) {
    // console.log(providers[i])
    let ident = await getProviderIdent(providers[i].categoryid);
    // console.log(ident)

    providers[i].categoryident = ident.categoryident;
  }

  // console.log(providers)

  return providers;
}

async function getUserData(email) {
  const response = await HTTP.get(`/client/get-client-id/${email}`);
  console.log(response.data);
  return response.data;
}

async function createClientAtServiceProvider(client) {
  const response = await HTTP.post('/providers/create-client', JSON.stringify(client));
  console.log(response.data);
}

async function createSignupInquiry(userData) {
  const response = await HTTP.post('/client/create-signup-inquiry', JSON.stringify({ userData: userData }));
  return response.data;
}

const AgreetoServices = (props) => {
  const {
    signupStep,
    nextSignupStep,
    signupCompletion,
    getServices,
    signupPathPerson,
    signupDocsPerson,
    signupDocsCompany,
  } = props;
  const { signOut, user } = useAuth();
  const [serviceProviders, setServiceProviders] = useState(null);
  const [agreetoServices, setAgreetoServices] = useState(false);
  const [userData, setUserData] = useState();

  useEffect(() => {
    try {
      async function getAllServiceProviders() {
        const response_user = await HTTP.get(`/client/get-client-id/${user.email}`);
        setUserData(response_user.data);
        const productid = response_user.data.productid;
        const response = await HTTP.get(`/signup/product-providers/${productid}`);
        setServiceProviders(response.data);
      }
      getAllServiceProviders();
    } catch (error) {
      console.log('getAllServices-error-', error);
    }
  }, []);

  const botActivity = async (anClientId) => {
    try {
      console.log(1000);

      const resp1 = await HTTP.get(`/signup/signup-bot/${anClientId}/9`);
      console.log(1001, resp1.data);

      console.log(2000);

      const resp2 = await HTTP.get(`/signup/signup-provider-approval-bot/${anClientId}/11`);
      console.log(2001, resp2.data);
    } catch (error) {
      console.log('botActivity', error);
    }
  };

  const acceptSignUp = async () => {
 
    if (agreetoServices === true) {
      const clientData = {
        signupstage: 120,
        signupid: user.signupid,
        producttouid_agreed: agreetoServices,
        signup_120_id: 5,
      };

      // Create signup Inquiry
      if ((signupPathPerson && signupDocsPerson) || (!signupPathPerson && signupDocsCompany)) {
        const signupInquiry = await createSignupInquiry(userData);
      }

      for (let i = 0; i < serviceProviders.length; i++) {
        let client = {
          createclient: true,
          categoryident: serviceProviders[i].categoryident,
          providerid: serviceProviders[i].providerid,
          clientid: user.signupid,
          name: userData.cl_lastname,
          firstname: userData.cl_firstname,
          salutation: userData.cl_salutation,
          email: userData.cl_email,
          phone: userData.cl_mobilephone,
          kyc: true,
          status: 'approved',
          login: 'logged in',
          password: userData.cl_password,
        };

        await createClientAtServiceProvider(client);
      }
      // END: CREATE CLIENT AT SERVICE PROVIDER

      console.log(1, clientData);

      const updated = await signupCompletion(clientData);
      console.log(111);
      if (updated.updated) {
        // Bot Activities
        console.log(222);
        botActivity(user.signupid);
        console.log(333);
        nextSignupStep(updated.signupstage);
      }
    }
  };

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Comment: Agreeing to accept the Service Providers of the selected Services as Business Partners
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Services Selection</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Row className='g-2 mb-0'>
          <Col className='form-floating mb-3'>
            <p>
              The following service providers provide the services of your product. They require that you accept this
              and that you accept their Terms of Use ahead of first use of the services. Additionally it might be
              required that you provide additional data according to financial regulations if requested by the Service
              Providers.
            </p>
          </Col>
        </Row>
        <Row className='g-2'>
          <Col md={4} className='form-floating mb-3'>
            <ul className='my-0'>
              {serviceProviders &&
                serviceProviders.map((item, index) => (
                  <li className='mb-2' key={index}>
                    {item.providername}
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        <Row className='g-2'>
          <Col md={3} className='form-floating mb-3'>
            <div>
              <Form.Check label='I Agree' type='checkbox' onChange={(e) => setAgreetoServices(e.target.checked)} />
            </div>
          </Col>
        </Row>
        <Row className='g-2 mt-0 mb-3 '>
          <Col md>
            <div className='float-end'>
              <Button variant='primary' size='lg' disabled={!agreetoServices} onClick={acceptSignUp}>
                Continue
              </Button>{' '}
              <Button variant='outline-primary' size='lg' onClick={logoutHandler}>
                Leave
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AgreetoServices;
