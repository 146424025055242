import { useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';

export default function KYC(props) {
  const { signupStep, nextSignupStep, signupCompletion, selSignupPath } = props;
  const { signOut, user } = useAuth();

  const [personalAccount, setPersonalAccount] = useState(false);
  const [companyAccount, setCompanyAccount] = useState(false);
  const [showInvalidEntry, setshowInvalidEntry] = useState(false);

  const setSignupType = async () => {
    if (personalAccount === true || companyAccount === true) {
      const clientData = {
        signupstage: 60,
        signupid: user.signupid,
        personal_account: personalAccount,
        company_account: companyAccount,
      };

      const updated = await signupCompletion(clientData);
      if (updated.updated) {
        selSignupPath(personalAccount);
        nextSignupStep(updated.signupstage); // 60
      }
    } else {
      setshowInvalidEntry(true);
    }
  };

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>Comment: Selection of the Sign Up Path</Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Type of Registration</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <ToastContainer className='p-3' position={'bottom-start'}>
          <Toast show={showInvalidEntry} bg='warning'>
            <Toast.Body>Please select an option</Toast.Body>
          </Toast>
        </ToastContainer>
        <Form id='kycForm'>
          <Form.Group className='mb-3'>
            <Row className='mb-3'>
              {' '}
              <Col>
                <p>Please let us know whether you want to register the account for</p>
              </Col>
            </Row>
            <Row className='mb-3'>
              {' '}
              <Col>
                <Form.Check
                  label='Yourself for personnel use'
                  type='radio'
                  name='accountType'
                  id='formHorizontalRadios1'
                  onChange={(e) => {
                    setshowInvalidEntry(false);
                    setPersonalAccount(e.target.checked);
                  }}
                />
              </Col>{' '}
            </Row>
          </Form.Group>
          <Row>
            {' '}
            <Col>
              <Form.Check
                label='A Company for company use'
                type='radio'
                name='accountType'
                id='formHorizontalRadios2'
                onChange={(e) => {
                  setshowInvalidEntry(false);
                  setCompanyAccount(e.target.checked);
                }}
              />
            </Col>{' '}
          </Row>
          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button
                  variant='primary'
                  size='lg'
                  // type="submit"
                  disabled={personalAccount === false && companyAccount === false}
                  onClick={setSignupType}
                >
                  Continue
                </Button>{' '}
                <Button
                  variant='outline-primary'
                  size='lg'
                  // type="submit"
                  // disabled={isSubmitting}
                  onClick={logoutHandler}
                >
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
