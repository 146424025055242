import { useEffect, useState } from "react";
import CustomButton from "../../components/ui/CustomButton";
import AiClubSection from "./aiclub-section";
import BankingSection from "./banking-section";
import ExpertsSection from "./experts-section";
import HomeSection from "./home-section";
import ProductsSection from "./products-section";
import WhyUsSection from "./whyus-section";
import Footer from "./footer";

import "./styles.scss";
import Header from "./header";
import CookiesPopup from "./cookies-popup";
import { useLocation, useNavigate } from "react-router-dom";

const Landing = () => {
  const [activeSection, setActiveSection] = useState('home');

  const handleNavLink = (e) => {
    e.preventDefault();
    setActiveSection(e.target.dataset.name);
  }

  useEffect(() => {
    const element = document.getElementById(activeSection);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeSection]);

  return (
    <div className="overflow-hidden" >
      <Header activeSection={activeSection} handleNavLink={handleNavLink} />

      <HomeSection id="home" />
      <ProductsSection id="products" />
      <BankingSection id="banking" />
      <AiClubSection id="aiclub" />
      <WhyUsSection id="whyus" />
      <ExpertsSection id="experts" />
      <CookiesPopup />

      <Footer activeSection={activeSection} handleNavLink={handleNavLink} />
    </div>
  );
};

export default Landing;