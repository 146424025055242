import React, { useEffect } from 'react';

import { Navbar, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import AlertNotifications from '../alert-system/AlertNotifications';
import UserMenu from './UserMenu';
import ChatNav from './ChatNav';
import ClientCall from './ClientCall';
import AccounntDetailNav from './AccountDetailNav';
import { useAuth } from '../../contexts/auth';
import CommunicateNav from './CommunicateNav';
import { io } from 'socket.io-client';
import { useMessaging } from '../../contexts/messaging';

export default function Header({ imgSrc, imgAlt, imgWidth, imgHeight }) {
  const { user } = useAuth();
  const { setDoUpdateMessagesList, unreadMessagesNumber, setDoReadMessages } = useMessaging();

  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

    socket.on('connect', () => {
      socket.emit('join', 'messaging_events');
      socket.on('messaging_event', async (data) => {
        if (data && user.clientid === data.userid) {
          // console.log(1, data);
          switch (data.eventtype) {
            case 'NCM':
              setDoUpdateMessagesList(true);
              break;

            case 'RCM':
              setDoReadMessages(true);
              break;

            default:
              break;
          }
        }
      });
    });
  }, [user]);

  return (
    <React.Fragment>
      <header className='header'>
        <Navbar bg='white' expand='lg' variant={false} className='px-4 py-2 shadow'>
          <Navbar.Brand>
            {imgSrc && <Image src={imgSrc} alt={imgAlt} width={imgWidth} height={imgHeight} />}
          </Navbar.Brand>

          <div className='ms-auto d-flex align-items-center mb-0'>
            <ClientCall user={user} />
            {/* <AccounntDetailNav />
            <ChatNav user={user} />
            <AlertNotifications /> */}
            <div
              className='position-relative'
            >
              <CommunicateNav />
              {(unreadMessagesNumber > 0) && (
                <Badge
                  pill
                  bg='primary'
                  className='position-absolute'
                  style={{
                    top: '5px',
                    right: '10px',
                  }}
                >
                  {unreadMessagesNumber}
                </Badge>
              )}
            </div>
            <UserMenu />
          </div>
        </Navbar>
      </header>
    </React.Fragment>
  );
}
