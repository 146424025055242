import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Col, Row, InputGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../../api/HTTP';
import * as Yup from 'yup';
import Execute from './ModalForms/BankDeposit/Execute';

export default function BankDepositForm(props) {
  const { user, selCard, acctid, assetid } = props;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      account: user.cl_firstname + ' ' + user.cl_lastname,
      bank: '',
      iban: '',
      amount: '',
    },
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 500));
      // alert(JSON.stringify(values, null, 2))
      // navigate('/', { replace: true })
    },
    validationSchema: Yup.object().shape({
      account: Yup.string().required('Required'),
      bank: Yup.string().required('Required'),
      iban: Yup.string().required('Required'),
      amount: Yup.number().required('Required').positive(),
    }),
  });

  const cancelExecution = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchClientDepositDetails = async () => {
      try {
        const response = await HTTP.get(`/client/client-deposit-details/${user.clientid}/${selCard.assetaccountid}`);
        formik.setFieldValue('bank', response.data[0].servicebank);
        formik.setFieldValue('iban', response.data[0].accountvalue);
      } catch (err) {}
    };
    if (user && user.clientid) {
      fetchClientDepositDetails();
    }
  }, [user.clientid]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='account'>
            Account
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            id='account'
            name='account'
            type='account'
            placeholder='Account'
            value={formik.values.account}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.account && formik.touched.account && 'error'}`}
          />
          {formik.errors.account && formik.touched.account && (
            <div className='input-feedback'>{formik.errors.account}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='bank'>
            Bank
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            id='bank'
            name='bank'
            type='bank'
            placeholder='Bank'
            value={formik.values.bank}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.bank && formik.touched.bank && 'error'}`}
          />
          {formik.errors.bank && formik.touched.bank && <div className='input-feedback'>{formik.errors.bank}</div>}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='iban'>
            IBAN
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control
            id='iban'
            name='iban'
            type='iban'
            placeholder='IBAN'
            value={formik.values.iban}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.iban && formik.touched.iban && 'error'}`}
          />
          {formik.errors.iban && formik.touched.iban && <div className='input-feedback'>{formik.errors.iban}</div>}
        </Col>
      </Row>
      <Row className='mb-0'>
        <Col md={3}>
          <Form.Label className='text-uppercase mt-1' htmlFor='amount'>
            Amount
          </Form.Label>
        </Col>
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text>{selCard.currency}</InputGroup.Text>
            <Form.Control
              id='amount'
              name='amount'
              type='amount'
              // placeholder="Amount"
              aria-label='Amount (with dot and two decimal places)'
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`form-control ${formik.errors.amount && formik.touched.amount && 'error'}`}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={3} />
        <Col md={7}>
          {formik.errors.amount && formik.touched.amount && (
            <div className='input-feedback'>{formik.errors.amount}</div>
          )}
        </Col>
      </Row>
      <div className='mb-3'>
        <div className='float-end'>
          <Execute
            account={formik.values.account}
            accountError={formik.errors.account}
            bank={formik.values.bank}
            bankError={formik.errors.bank}
            iban={formik.values.iban}
            ibanError={formik.errors.iban}
            amount={formik.values.amount}
            amountError={formik.errors.amount}
            user={user}
            selCard={selCard}
            accountid={acctid}
            assetid={assetid}
          />{' '}
          <Button variant='secondary' onClick={cancelExecution}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
