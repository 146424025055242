import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Modal } from 'react-bootstrap';
import HTTP from '../../../../../api/HTTP';

export default function Execute(props) {
  const {
    task,
    taskError,
    recipient,
    recipientError,
    iban,
    ibanError,
    amount,
    amountError,
    intendeduse,
    intendeduseError,
    execution,
    executionError,
    user,
    selCard,
    accountid,
    assetid,
  } = props;

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const submitButtonRef = useRef(false);
  const [execTransactionid, setEexecTransactionid] = useState();
  const [clientFee, setClientFee] = useState();

  const toggleModal = () => {
    if (
      !recipientError &&
      recipient !== '' &&
      !ibanError &&
      iban !== '' &&
      !amountError &&
      amount !== '' &&
      !intendeduseError &&
      intendeduse !== '' &&
      !executionError &&
      execution !== ''
    ) {
      setShow(!show);
    }
  };

  async function transferCustomerFunds() {
    console.log('-');
    console.log(user);
    console.log('-');
    console.log('---');
    console.log(selCard);
    console.log('---');

    try {
      const response = await HTTP.post(
        '/ops/transfer-funds',
        JSON.stringify({
          transactionid: execTransactionid,
          assetaccountid: selCard.assetaccountid,
          provider_accountid: selCard.provider_accountid,
          buysellop: 3,
          clientid: user.clientid,
          serviceid: selCard.serviceid,
          providerid: selCard.providerid,
          assetid: selCard.svassetid,
          partnerid: selCard.partnerid,
          productid: selCard.productid,
          routingid: selCard.routingid,
          bankaccountid: selCard.bankaccountid,
          categoryident: selCard.category_ident,
          categoryid: selCard.categoryid,
          sendreceivetype: task,
          recipient: recipient,
          iban: iban,
          currency: selCard.currency,
          currencyamount: parseFloat(amount),
          assetamount: amount,
          intended_use: intendeduse,
          execution: execution,
          transfertask: execution,
          assetname: selCard.assetname,
          svassetid: selCard.svassetid,
        })
      );
      const data = await response.data;
      console.log(data);

      if (data.status === 'success') return true;
    } catch (error) {
      console.log('bank-transfer-error', error);
    }
  }

  const DeterminFees = async () => {
    try {
      const response = await HTTP.post(
        '/ops/calc-transaction-fees',
        JSON.stringify({
          clientid: user.clientid,
          client_userid: user.loggedinclientid,
          riskid: user.client_risklevelid,
          buysellop: 3,
          assetaccountid: selCard.assetaccountid,
          currency: selCard.currency,
          assetamount: amount,
          currencyamount: amount,
          svassetid: selCard.svassetid,
          assetid: selCard.assetid,
        })
      );
      const data = await response.data;

      await setEexecTransactionid(data[0].transactionid);
      await setClientFee(data[1].calc_transaction_fees);

      toggleModal();
    } catch (error) {
      console.log('calc-transaction-fees-error', error);
    }
  };

  const handleSubmit = async () => {
    // await transferCustomerFunds()
    // navigate('/', { replace: true })

    const transferFunds = await transferCustomerFunds();

    if (transferFunds) {
      navigate(-1);
    } else {
      console.log('Transfer of funds was not executed!');
    }
  };

  return (
    <React.Fragment>
      <Button variant='primary' type='submit' onClick={DeterminFees}>
        Execute
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Review Bank Transfer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure to make the transfer with following values?</p>

          <Form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <br />
              <p>Recipient: {recipient}</p>
              <p>IBAN: {iban}</p>
              <p>
                Amount: {selCard.currency} {amount}
              </p>
              <p>Intended use: {intendeduse}</p>
              <p>
                Fees: {selCard.currency} {clientFee && clientFee}
              </p>
            </div>
            <Button variant='primary' className='d-none' type='submit' ref={submitButtonRef}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitButtonRef.current.click()}>
            Apply
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
