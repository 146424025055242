import { Col, Container, Row } from "react-bootstrap"
import CustomButton from "../../components/ui/CustomButton";

const ProductsSection = (props) => {
  return (
    <div
      {...props}
      style={{
        backgroundColor: '#EFEEF5',
        paddingTop: '90px',
        paddingBottom: '90px',
      }}
    >
      <Container>
        <div
          className="main-text dark"
        >
          Benefit from the entire World of digital Assets
        </div>
        <div
          className="secondary-text dark"
          style={{
            marginTop: '30px'
          }}
        >
          Our vast range of product offerings - one app for all your needs
        </div>

        <Row
          className="benefit-cards-section gx-4 gy-4"
        >
          <Col
            lg={6}
            xs={12}
          >
            <div
              className="etf-card custom-card custom-card-main"
              style={{
                justifyContent: 'end'
              }}
            >
              <img
                className="refresh"
                src="/img/refresh.png"
              />
              <img
                className="refresh-bage"
                src="/img/refresh-badge.png"
              />
              <div
                className="main-card-text mt-md-0 mt-5"
                style={{
                  marginBottom: '40px',
                }}
              >
                ETF'S
              </div>
              <div
                className="secondary-card-text"
                style={{
                  marginBottom: '40px',
                  maxWidth: '330px',
                }}
              >
                Experience the diversity of the ETF market effortlessly with our simple tools for managing a wide range of funds.
              </div>
              <CustomButton
                variant="main-light"
                size="normal"
              >
                Join Waitlist
              </CustomButton>
            </div>
          </Col>
          <Col
            lg={6}
            xs={12}
          >
            <div className="custom-card custom-card-secondary precious-metals">
              <img src="/img/metals-phone.png" className="metals-phone" />
              <img src="/img/metals-card.png" className="metals-card" />
              <div
                className="main-card-text"
                style={{
                  marginBottom: '40px',
                }}
              >
                Precious Metals
              </div>
              <div
                className="secondary-card-text"
                style={{
                  marginBottom: '40px',
                  maxWidth: '330px',
                }}
              >
                Embrace the lasting value of precious metals with our accessible platform, simplifying your journey into gold, silver, and more.
              </div>
              <CustomButton
                variant="main-dark"
                size="normal"
              >
                Join Now
              </CustomButton>
            </div>
          </Col>
          <Col
            xl={8}
            lg={6}
            xs={12}
          >
            <div
              className="custom-card custom-card-main"
              style={{
                backgroundImage: 'url("/img/cryptocurrency-background.png")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left',
                backgroundSize: 'cover',
              }}
            >
              <div className="d-block d-lg-none cryptocurrency-alt">
                <div
                  className="main-card-text"
                  style={{
                    marginBottom: '40px',
                  }}
                >
                  Cryptocurrencies
                </div>
                <div
                  className="secondary-card-text"
                  style={{
                    marginBottom: '40px',
                    maxWidth: '330px',
                  }}
                >
                  Join the crypto wave with our easy-to-use platform, making buying, selling, and holding digital currencies a breeze.
                </div>
                <CustomButton
                  variant="main-light"
                  size="normal"
                >
                  Join Waitlist
                </CustomButton>
              </div>
            </div>
          </Col>
          <Col
            xl={4}
            lg={6}
            xs={12}
            className="d-none d-md-block"
          >
            <div className="custom-card custom-card-main">
              <img
                src="/img/bitcoin.svg"
                style={{
                  position: 'absolute',
                  bottom: '38px',
                  right: '58px'
                }}
              />
              <div
                className="main-card-text"
                style={{
                  marginBottom: '40px',
                }}
              >
                Cryptocurrencies
              </div>
              <div
                className="secondary-card-text"
                style={{
                  marginBottom: '40px',
                  maxWidth: '330px',
                }}
              >
                Join the crypto wave with our easy-to-use platform, making buying, selling, and holding digital currencies a breeze.
              </div>
              <CustomButton
                variant="main-light"
                size="normal"
              >
                Join Waitlist
              </CustomButton>
            </div>
          </Col>
          <Col
            xs={12}
          >
            <div
              className="custom-card custom-card-main stocks-block"
              style={{
                justifyContent: 'center',
                overflow: 'visible',
              }}
            >
              <img src="/img/stocks-phone.png" className="stocks-phone" />
              <img src="/img/stocks-cpi.png" className="stocks-cpi" />
              <img src="/img/stocks-tsla.png" className="stocks-tsla" />
              <div
                className="main-card-text"
                style={{
                  marginBottom: '40px',
                }}
              >
                Stocks
              </div>
              <div
                className="secondary-card-text"
                style={{
                  marginBottom: '40px',
                  maxWidth: '330px',
                }}
              >
                Step into the world of stocks with our seamless services, making it easy to be part of the corporate growth story.
              </div>
              <CustomButton
                variant="main-light"
                size="normal"
              >
                Join Waitlist
              </CustomButton>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductsSection;