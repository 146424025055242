import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row, Button, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPhone, faPen, faMessageLines, faArrowRightToLine } from '@fortawesome/pro-thin-svg-icons';

// import Breadcrumbs from "../../components/Breadcrumbs"
import { useAuth } from '../../../contexts/auth';

export default function Index(props) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate('/home', { replace: true });
    }
  }, [user]);

  const closeDialog = () => {
    navigate(-1);
  };

  const clientChatid = user && parseInt(user.clientchatid);

  const executeCall = () => {};

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/*
      <Breadcrumbs title={'Chat'} />
       */}
      <section>
        <Row className='justify-content-md-center'>
          <Col lg={6}>
            <Card className='h-100'>
              <Card.Header>
                <h4 className='card-heading'>Call Us</h4>
              </Card.Header>
              <Card.Body>
                <div>
                  <Row>
                    <Col>
                      <p className='text-muted mb-4'>
                        {/* <span className='fw-bold'>Hello</span>  */}
                        You can use this app to call us when you are at a PC, Notebook, Tablet or Android Smartphone.
                        The system will build up a voice video call allowing you and us to talk directly. All what is
                        necessary, is that you have a camera and microphone or headset available with your PC, Notebook,
                        Tablet or Android Smartphone.
                      </p>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col md={5}>
                      <Form.Label className='text-uppercase mt-3' htmlFor='account'>
                        Call Us Now
                      </Form.Label>
                    </Col>
                    <Col md={5}>
                      <div className='d-grid'>
                        <Button variant='light' size='lg' onClick={executeCall}>
                          <FontAwesomeIcon className='icon-pos' icon={faPhone} size='xl' color='blue' />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className='text-muted mb-4'>
                        {/* <span className='fw-bold'>Hello</span>  */}
                        In case you want to call us using traditional telephony you can call us using the following
                        Number:
                      </p>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col md={5}>
                      <Form.Label className='text-uppercase mt-3' htmlFor='account'>
                        +49 (0) 69 43245546
                      </Form.Label>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
              <Card.Footer>
                <div className='float-end'>
                  <Button variant='outline-secondary' onClick={closeDialog}>
                    Close
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
