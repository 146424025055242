import { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import HTTP from '../../api/HTTP';

import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

import {
  KYCCheck,
  KYCCheckSumSub,
  KYC,
  ProductSelection,
  AgreetoServices,
  TermsOfUse,
  VerifyMobile,
  VerifyEMail,
  PersonBasic,
  CompanyBasic,
  NoProductAvailable,
  PersonRiskMatrix,
  KYCCheckFailed,
  AlmostThere,
  PayOnboarding,
  CompanyRiskMatrix,
  RequiresSignupDocuments,
} from '../../own-components/SignupCards';

async function signupCompletion(clData) {
  try {
    const response = await HTTP.post('/signup/signup-completion', JSON.stringify(clData));

    console.log(response.data);

    const anresult = {
      updated: response.data.rowCount === 1 ? true : false,
      signupstage: response.data.rows[0].signup_completion,
    };

    if (response.data.rowCount === 1) return anresult;
  } catch (error) {
    console.log('signupCompletion error', error);
  }
}

async function getCountries() {
  try {
    const response = await HTTP.get('/signup/countries');
    const countries = response.data.map(({ name_en }) => name_en);
    return countries;
  } catch (error) {
    console.log('getCountries error', error);
  }
}

async function getRiskLevels() {
  try {
    const response = await HTTP.get('/base/get-lookup-values/12');
    return response.data;
  } catch (error) {
    console.log('getCountries error', error);
  }
}

async function getFailedReasons() {
  try {
    const response = await HTTP.get('/base/get-lookup-values/15');
    return response.data;
  } catch (error) {
    console.log('getFailedReasons error', error);
  }
}

async function getProducts(anPartnerid) {
  try {
    const response = await HTTP.get(`/signup/products-of-partner/${anPartnerid}`);
    const products = response.data.map(({ productname }) => productname);
    return products;
  } catch (error) {
    console.log('getProducts error', error);
  }
}

async function getServices(anProductid) {
  try {
    const response = await HTTP.get(`/signup/product-services/${anProductid}`);

    const services = response.data.map(({ servicename }) => servicename);
    return services;
  } catch (error) {
    console.log('getServices error', error);
  }
}

async function getIndustrySectors() {
  try {
    const response = await HTTP.get('/base/get-lookup-values/31');
    return response.data;
  } catch (error) {
    console.log('getIndustrySectors error', error);
  }
}

const SignupMainPage = () => {
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const partnerid = user.partnerid;
  const userEmail = user.cl_email;
  const [signupStep, setSignupStep] = useState(user.signupstage);
  const [signupPathPerson, setSignupPathPerson] = useState(true);
  const [productAvailable, setProductAvailable] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);
  const [partnerRiskTerms, setPartnerRiskTerms] = useState(false);
  const [payBeforeKYC, setPayBeforeKYC] = useState(false);
  const [payBeforeKYCPaid, setPayBeforeKYCPaid] = useState(false);
  const [signupDocuments, setSignupDocuments] = useState(false);

  const nextSignupStep = (step) => setSignupStep(step);

  const selSignupPath = (personpath) => {
    setSignupPathPerson(personpath);
  };

  useEffect(() => {
    const fetchClientid = async () => {
      try {
        const response = await HTTP.get(`/signup/client-id/${userEmail}`);
        const rows = await response.data;

        const rspPortal = await HTTP.get(`/signup/client-portal/${partnerid}`);
        const portalIdent = await rspPortal.data;

        const rspDocs = await HTTP.get(`/signup/signup-doc/${portalIdent[0].portalid}`);
        const signupDocs = await rspDocs.data;
        setSignupDocuments(signupDocs);

        // console.log(signupDocs);

        if (rows.length > 0) {
          setSignupStep(rows[0].signupstage);
          setSignupPathPerson(rows[0].personal_account);
          setProductAvailable(rows[0].product_available);
          setPayBeforeKYC(rows[0].onboardingfee_before_kyc);
          setPayBeforeKYCPaid(rows[0].onboardingfee_paid);

          console.log(7575, rows[0]);

          const responsetwo = await HTTP.get(`/signup/partner-risk-terms/${partnerid}/${rows[0].risk_result_one}`);
          const rowstwo = await responsetwo.data;

          if (rowstwo.length > 0) {
            console.log(rowstwo[0].pay_before_kyc);

            setPartnerRiskTerms(rowstwo[0].pay_before_kyc);
          } else setPartnerRiskTerms(false);

          user.signupid = rows[0].signupid;
          user.email = rows[0].cl_email;
          user.partnerid = rows[0].partnerid;
          user.portalid = portalIdent[0].portalid;
          user.portalname = portalIdent[0].portalname;
          user.signupstage = rows[0].signupstage;
          user.personal_account = rows[0].personal_account;
          user.company_account = rows[0].company_account;
          user.product_available = rows[0].product_available;
          user.risk_result_one = rows[0].risk_result_one;
          user.riskterms = rowstwo.length > 0 ? rowstwo[0].pay_before_kyc : false;
          user.client_risklevelid = rows[0].client_risklevelid;
          user.onboardingfee_before_kyc = rows[0].onboardingfee_before_kyc;
          user.onboardingfee_paid = rows[0].onboardingfee_paid;
          user.onboardingfee = rows[0].onboardingfee;

          setReloadUser(false);
        } else {
          signOut();
        }
      } catch (error) {
        console.log('fetchClientid error ', error);
      }
    };

    if (!user || reloadUser) {
      fetchClientid();
    }
  }, [user, reloadUser, userEmail, partnerid]);

  const doReloadUser = () => {
    setReloadUser(true);
  };

  return (
    <Container>
      {signupStep === 20 && <VerifyEMail nextSignupStep={nextSignupStep} signupCompletion={signupCompletion} />}
      {signupStep === 30 && <VerifyMobile nextSignupStep={nextSignupStep} signupCompletion={signupCompletion} />}
      {signupStep === 40 && (
        <TermsOfUse signupStep={signupStep} nextSignupStep={nextSignupStep} signupCompletion={signupCompletion} />
      )}
      {signupStep === 50 && (
        <KYC
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          selSignupPath={selSignupPath}
        />
      )}

      {signupPathPerson && signupStep === 60 && (
        <PersonBasic
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          getCountries={getCountries}
          doReloadUser={doReloadUser}
        />
      )}

      {!signupPathPerson && signupStep === 60 && (
        <CompanyBasic
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          getCountries={getCountries}
          doReloadUser={doReloadUser}
        />
      )}

      {!productAvailable && signupStep === 70 && <NoProductAvailable signupStep={signupStep} />}

      {productAvailable && signupPathPerson && payBeforeKYC && !payBeforeKYCPaid && signupStep === 70 && (
        <PayOnboarding
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          doReloadUser={doReloadUser}
        />
      )}

      {productAvailable &&
        signupPathPerson &&
        (!payBeforeKYC || (payBeforeKYC && payBeforeKYCPaid)) &&
        signupStep === 70 && (
          <PersonRiskMatrix
            signupStep={signupStep}
            nextSignupStep={nextSignupStep}
            signupCompletion={signupCompletion}
          />
        )}

      {productAvailable && !signupPathPerson && !payBeforeKYCPaid && signupStep === 70 && (
        <PayOnboarding
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          doReloadUser={doReloadUser}
        />
      )}

      {productAvailable && !signupPathPerson && payBeforeKYCPaid && signupStep === 71 && (
        <CompanyRiskMatrix
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          getIndustrySectors={getIndustrySectors}
          doReloadUser={doReloadUser}
        />
      )}

      {productAvailable && signupPathPerson && (!payBeforeKYC || payBeforeKYCPaid) && signupStep === 80 && (
        <KYCCheckSumSub
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          userEmail={userEmail}
          getRiskLevels={getRiskLevels}
          getFailedReasons={getFailedReasons}
          doReloadUser={doReloadUser}
        />
      )}

      {productAvailable && !signupPathPerson && payBeforeKYCPaid && signupStep === 80 && (
        <KYCCheckSumSub
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          userEmail={userEmail}
          getRiskLevels={getRiskLevels}
          getFailedReasons={getFailedReasons}
          doReloadUser={doReloadUser}
        />
      )}

      {/* {productAvailable &&
        signupPathPerson &&
        (!payBeforeKYC || payBeforeKYCPaid) &&
        signupStep === 80 && (
          <KYCCheck
            nextSignupStep={nextSignupStep}
            signupCompletion={signupCompletion}
            getRiskLevels={getRiskLevels}
            getFailedReasons={getFailedReasons}
            doReloadUser={doReloadUser}
          />
        )}

      {productAvailable &&
        !signupPathPerson &&
        payBeforeKYCPaid &&
        signupStep === 80 && (
          <KYCCheck
            nextSignupStep={nextSignupStep}
            signupCompletion={signupCompletion}
            getRiskLevels={getRiskLevels}
            getFailedReasons={getFailedReasons}
            doReloadUser={doReloadUser}
          />
        )} */}

      {signupPathPerson && signupDocuments.signup_person_docs === false && signupStep === 110 && (
        <ProductSelection signupStep={signupStep} nextSignupStep={nextSignupStep} signupCompletion={signupCompletion} />
      )}

      {signupPathPerson && signupDocuments.signup_person_docs === true && signupStep === 110 && (
        <RequiresSignupDocuments
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          signupPathPerson={signupPathPerson}
          signupDocsPerson={signupDocuments.signup_person_docs}
          signupDocsCompany={signupDocuments.signup_company_docs}
        />
      )}

      {!signupPathPerson && signupDocuments.signup_company_docs === false && signupStep === 110 && (
        <ProductSelection signupStep={signupStep} nextSignupStep={nextSignupStep} signupCompletion={signupCompletion} />
      )}

      {!signupPathPerson && signupDocuments.signup_company_docs === true && signupStep === 110 && (
        <RequiresSignupDocuments
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          signupPathPerson={signupPathPerson}
          signupDocsPerson={signupDocuments.signup_person_docs}
          signupDocsCompany={signupDocuments.signup_company_docs}
        />
      )}

      {signupStep === 120 && (
        <AgreetoServices
          signupStep={signupStep}
          nextSignupStep={nextSignupStep}
          signupCompletion={signupCompletion}
          getServices={getServices}
          signupPathPerson={signupPathPerson}
          signupDocsPerson={signupDocuments.signup_person_docs}
          signupDocsCompany={signupDocuments.signup_company_docs}
        />
      )}
      {signupStep === 5000 && <KYCCheckFailed />}
      {signupStep === 10000 && <AlmostThere />}
    </Container>
  );
};

export default SignupMainPage;
