import { Button } from "devextreme-react";
import { sanitize } from "dompurify";
import { marked } from "marked";
import moment from "moment";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import MessagingHelper from "./MessagingHelper";
import { downloadFileFromBuffer } from "../../../utils/attach-helper";
import notify from "devextreme/ui/notify";

const IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
]

const MessagesList = (props) => {
  const {
    groupedMessagesList,
    user,
    selectedChatData,
    setMessageIdToReply,
    loadMore,
    hasMore,
    messagesNumber,
    unreadMessagesList,
    setUnreadMessagesList,
    readMessagesHandler,
  } = props;

  const MESSAGE_LIST_ID = "message_list_area";

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedMessageId, setSelectedMessageId] = useState(-1);

  useEffect(() => {
    window.addEventListener('click', () => setShowContextMenu(false));
    return window.removeEventListener('click', () => setShowContextMenu(false));
  }, []);

  useEffect(() => {
    handleUnreadMessages();

    const scrollComponent = document.getElementsByClassName('messages-container')[0];

    scrollComponent.addEventListener('scroll', handleUnreadMessages);
    return () => scrollComponent.removeEventListener('scroll', handleUnreadMessages);
  }, [unreadMessagesList]);

  const handleUnreadMessages = () => {
    unreadMessagesList.forEach(async msg => {
      if (Number(msg.customerId) !== Number(user.clientid) && isInViewPort(msg.id)) {
        await readMessagesHandler();
        setUnreadMessagesList([]);
      }
    });
  }

  const isInViewPort = (id) => {
    const element = document.getElementById(`message-${id}`);
    const elementParams = element.getBoundingClientRect();
    const chatContentWrapper = document.getElementsByClassName('chat-content')[0].getBoundingClientRect();

    return elementParams.top < chatContentWrapper.bottom;
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setShowContextMenu(true);

    let xPos, yPos;

    const contextMenu = document.getElementsByClassName('chat-context-menu')[0].getBoundingClientRect();
    const chatContentWrapper = document.getElementsByClassName('chat-content')[0].getBoundingClientRect();
    const messagesListWrapper = document.getElementsByClassName('messages-container-wrapper')[0].getBoundingClientRect();
    const parentElement = event.target.closest('.message');

    xPos = event.clientX - messagesListWrapper.x;
    yPos = event.clientY - messagesListWrapper.y;

    if (xPos + contextMenu.width > messagesListWrapper.width) {
      xPos = xPos - contextMenu.width;
    }

    if (yPos + contextMenu.height > chatContentWrapper.height) {
      yPos = yPos - contextMenu.height;
    }

    setContextMenuPosition({
      x: xPos,
      y: yPos,
    });

    setSelectedMessageId(parentElement.dataset.id);
  }

  const handleDownloadFile = (documentId) => {
    MessagingHelper.getFileContent(documentId)
      .then((data) => {
        downloadFileFromBuffer(data.fileContent.data, data.fileName);
      })
      .catch((e) => {
        notify('An error occurred while downloading the file', 'error', 2000);
      });
  };

  const getFontIconByExtension = (extension = 'other') => {
    const FILE_EXTENSIONS = {
      pdf: 'pdffile',
      doc: 'docfile',
      docx: 'docxfile',
      txt: 'txtfile',
      xls: 'xlsfile',
      jpg: 'image',
      jpeg: 'image',
      png: 'image',
      avi: 'video',
      zip: 'toolbox',
      rar: 'toolbox',
      other: 'file',
    };

    const foundExtension = FILE_EXTENSIONS[extension];
    if (!foundExtension) {
      return FILE_EXTENSIONS.other;
    }
    return FILE_EXTENSIONS[extension];
  };

  const getFileExtension = (fileName) => {
    if (fileName ?? undefined) {
      return fileName.split('.').pop().toLowerCase();
    }
  };

  const getFileIcon = (fileName) => {
    const ext = getFileExtension(fileName);
    return getFontIconByExtension(ext);
  };

  const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;

    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  }

  return (
    <>
      <div
        className="chat-context-menu"
        style={{
          top: contextMenuPosition.y,
          left: contextMenuPosition.x,
          visibility: showContextMenu ? 'visible' : 'hidden'
        }}
      >
        <Button
          onClick={() => setMessageIdToReply(selectedMessageId)}
          className='chat-context-menu-item'
          text='Reply'
          icon='revert'
          stylingMode='text'
        />
      </div>

      <div
        id={MESSAGE_LIST_ID}
        className='messages-container'
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          height: '100%',
        }}
      >
        <InfiniteScroll
          dataLength={messagesNumber + 3}
          next={() => loadMore(selectedChatData?.threadId)}
          hasMore={hasMore}
          inverse={true}
          scrollableTarget={MESSAGE_LIST_ID}
          loader={
            <p>
              Loading...
            </p>
          }
          style={{
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column-reverse'
          }}
        >
          {Object.keys(groupedMessagesList).map((date, index) => (
            <div
              key={`messages-group-${index}`}
              style={{
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column-reverse'
              }}
            >
              {groupedMessagesList[date].map((message, index) => (
                <div
                  key={`message-${message.id}`}
                  id={`message-${message.id}`}
                  data-id={message.id}
                  className={`
                    message 
                    ${message.customerId == user.clientid ? 'own' : ''} 
                    ${Number(groupedMessagesList[date][index - 1]?.customerId !== message.customerId) ? 'last-in-group' : ''}
                  `}
                  onContextMenu={handleContextMenu}
                >
                  <div
                    className="message-content-wrapper"
                  >
                    {(selectedChatData?.isGroup && message.customerId !== user.clientid) && (
                      <div className='message-user-name'>
                        {message.customerName}
                      </div>
                    )}
                    {message.repliedMessage && (
                      <div className='replied-message'>
                        <div className='replied-message-user'>
                          {message.repliedMessageUser ? message.repliedMessageUser : "Support"}
                        </div>
                        {message.repliedMessage}
                      </div>
                    )}
                    {message.files?.length > 0 && (
                      <div className='message-files'>
                        {message.files.map((file, index) => IMAGE_TYPES.includes(file.contentType) ? (
                          <div
                            key={`emoji-${message.id}-${index}`}
                            className='message-image-container'
                          >
                            <img
                              className='message-image'
                              src={`data:${file.contentType};base64,${arrayBufferToBase64(new Uint8Array(file.fileContent?.data))}`}
                            />
                            <div className='message-image-overlay'>
                              <Button
                                key={file.id}
                                onClick={() => handleDownloadFile(file.id)}
                                className='message-image-overlay-icon'
                                icon='download'
                              />
                            </div>
                          </div>
                        ) : (
                          <Button
                            key={file.id}
                            onClick={() => handleDownloadFile(file.id)}
                            className='file-item'
                            icon={getFileIcon(file.filename)}
                            text={file.filename}
                          />
                        ))}
                      </div>
                    )}
                    <div
                      className="message-text"
                      dangerouslySetInnerHTML={{ __html: sanitize(marked.parse(message.message)).replace('a href', 'a target="_blank" href') }}
                    />
                    <div className="message-info">
                      <div className={'message-time'}>
                        {moment(message.createdAt).format('HH:mm')}
                      </div>
                      {String(message.customerId) === String(user.clientid) && (
                        <div className={'message-delivery-status'}>
                          {message.unread ? '.' : '..'}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div
                key={date}
                className="message-date-group"
              >
                <div
                  className="group-date"
                >
                  {moment(date).format('DD MMMM YYYY')}
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
}

export default MessagesList;