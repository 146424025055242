import './scss/style.default.scss';
// import 'devextreme/dist/css/dx.common.css';
// import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './dx-styles.scss';
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';

import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import Content from './Content';
import Signup from './SignupContent';
import UnauthenticatedContent from './UnauthenticatedContent';
import { MesssagingProvider } from './contexts/messaging';

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }
  // console.log(3456, user);
  if (user) {
    if (user.signupid) {
      return <Signup />;
    } else {
      return <Content />;
    }
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <MesssagingProvider>
            <div>
              <App />
            </div>
          </MesssagingProvider>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
