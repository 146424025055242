import { Col, Container, Row } from "react-bootstrap";
import CustomButton from "../../components/ui/CustomButton";

const ExpertsSection = (props) => {
  return (
    <div
      {...props}
      className="position-relative experts-container-wrapper"
    >
      <Container className="experts-container">
        <img id="face-1" src="/img/face-1.png" className="position-absolute" />
        <img id="face-2" src="/img/face-2.png" className="position-absolute" />
        <img id="face-3" src="/img/face-3.png" className="position-absolute" />
        <div
          className="main-text light"
        >
          Team of Experts
        </div>
        <div
          className="secondary-text light"
          style={{
            marginTop: '40px'
          }}
        >
          Our talented team of professionals has the skills and expertise to help you achieve your financial goals and maximize your returns! Our talented team of professionals has the skills and expertise to help you achieve your financial goals and maximize your returns.
        </div>
        <div
          className="d-flex w-100 justify-content-center"
          style={{
            marginTop: '50px'
          }}
        >
          <CustomButton
            variant="main-dark"
            size="normal"
          >
            Join Watchlist
          </CustomButton>
        </div>

        <Row
          className="gx-3"
          style={{
            marginTop: '122px'
          }}
        >
          <Col
            xs={12}
            lg={4}
          >
            <div
              className="w-100 h-100 position-relative "
              style={{
                backgroundColor: '#0A96FF',
                borderRadius: '20px',
                padding: '40px'
              }}
            >
              <div
                className="d-block d-lg-none mt-sm-0"
                style={{
                  marginTop: '260px'
                }}
              >
                <img
                  src="/img/logo.svg"
                  style={{
                    width: '260px',
                  }}
                />
                <div
                  style={{
                    width: '250px',
                    marginTop: '20px',
                    fontSize: '24px',
                    lineHeight: '33px',
                    color: '#171616',
                  }}
                >
                  Unlock the power of your capital.
                </div>
                <CustomButton
                  style={{
                    marginTop: "45px"
                  }}
                  variant="main-dark"
                  size="normal"
                >
                  Join Watchlist
                </CustomButton>
              </div>
              <img
                src="/img/chess.png"
                className="position-absolute"
                style={{
                  width: '230px',
                  top: '-100px',
                  right: '40px'
                }}
              />
              <img
                src="/img/chess-shadow.png"
                className="position-absolute"
                style={{
                  top: '-60px',
                  right: '60px',
                  width: '230px'
                }}
              />
              <img
                src="/img/chess-stroke.svg"
                className="position-absolute d-none d-lg-block"
                style={{
                  width: '270px',
                  bottom: '30px',
                  right: '7px'
                }}
              />
            </div>
          </Col>
          <Col
            lg={8}
            className="d-lg-block d-none"
          >
            <div
              className="w-100 h-100 position-relative overflow-hidden"
              style={{
                padding: '40px',
                background: '#FFFFFF',
                borderRadius: '20px',
              }}
            >
              <img
                src="/img/dashboard.png"
                className="position-absolute w-100"
                style={{
                  top: '100px',
                  left: '360px'
                }}
              />
              <img
                src="/img/logo.svg"
                style={{
                  width: '260px',
                }}
              />
              <div
                style={{
                  width: '250px',
                  marginTop: '20px',
                  fontSize: '24px',
                  lineHeight: '33px',
                  color: '#171616',
                }}
              >
                Unlock the power of your capital.
              </div>
              <CustomButton
                style={{
                  marginTop: "45px"
                }}
                variant="main-light"
                size="normal"
              >
                Join Watchlist
              </CustomButton>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ExpertsSection;