import React from 'react';
import { Header, Footer } from '../../components';

import { Card, Col, Container, Row } from 'react-bootstrap';

import { merchantPartner } from '../../utils/global-const';

const WalletPage = ({ title, children }) => {
  return (
    <Container fluid className='px-lg-0 px-xl-0'>
      <div>
        <Container fluid className='px-lg-0 px-xl-0'>
          <Header imgSrc='/img/finbuilder_logo.png' imgAlt={merchantPartner + ' Logo'} imgHeight={40} imgWidth={184} />
        </Container>
      </div>
      <div className='d-flex align-items-stretch'>
        <div className='page-holder align-items-center'>
         
          {React.Children.map(children, (item) => {
            return item;
            // item.type === Footer &&
          })}

        </div>
      </div>
    </Container>
  );
};

export default WalletPage;
