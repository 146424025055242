import { useEffect, useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import HTTP from '../../api/HTTP';
import { useNavigate } from 'react-router-dom';

export default function ProductSelection(props) {
  const { user } = props;
  const navigate = useNavigate();

  const [productsList, setProductsList] = useState([]);
  const [selProductid, setSelProductid] = useState(-1);
  const [selProductName, setSelProductName] = useState('None');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await HTTP.get(`/signup/partner-products/${user.partnerid}/${user.client_risklevelid}/1`);
        setProductsList(response.data);
      } catch (error) {
        console.log('fetch prodducts error', error);
      }
    };

    if (user) {
      fetchProducts();
    }
  }, [user]);

  const saveProductSelection = async () => {
    // user.signup = {
    //  productid: selProductid,
    // };
    navigate('/agree-to-services-main', { state: { productid: selProductid } });
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      <h4 className='mb-4 card-heading'>Your Application has been approved. Please chose a Product</h4>

      <section>
        <Row className='mb-5'>
          {productsList &&
            productsList.map((item, rowindex) => (
              <Col lg={4} key={rowindex}>
                <Card
                  className={`mb-5 mb-lg-4 border-0  ${
                    item.row_to_json.productname ? 'card-highlight shadow-lg overflow-hidden' : 'shadow'
                  }`}
                >
                  {item.row_to_json.featured && <div className='card-status bg-primary' />}
                  <Card.Body>
                    <h2 className='card-heading text-primary fw-bold py-3'>{item.row_to_json.productname}</h2>
                    <p className='text-muted mb-3'>
                      <span className='h5 text-dark fw-bold'>
                        {item.row_to_json.currency} {item.row_to_json.recurringfee}
                      </span>
                      <span className='ms-2'>/ {item.row_to_json.period}</span>
                    </p>
                    <p className='text-muted mt-1'>
                      <span className='ms-0'>
                        Setup Fee {item.row_to_json.currency} {item.row_to_json.setupfee}
                      </span>
                    </p>
                    <hr className='text-muted' />

                    <ul className='fa-ul my-4'>
                      {item.row_to_json.services &&
                        item.row_to_json.services.map((serviceitem, rowindex) => (
                          <li className='mb-0' key={rowindex}>
                            {serviceitem.servicename}
                            <ul className='my-1'>
                              {serviceitem.serviceaccounts &&
                                serviceitem.serviceaccounts.map((srvaccounts, gindex) => (
                                  <li className='mb-0' key={gindex}>
                                    {srvaccounts.assetname}
                                  </li>
                                ))}
                            </ul>
                          </li>
                        ))}
                    </ul>
                    <p className='text-muted mt-0 mb-0'>
                      <span className='ms-2'>Usage incurs individual transaction fees.</span>{' '}
                    </p>
                    <p className='text-muted mt-0'>
                      <span className='ms-2'>For details please review full</span>{' '}
                      <a href={`/signup/productpricing/${item.row_to_json.productid}/${user.client_risklevelid}`}>
                        {' '}
                        Pricing
                      </a>
                    </p>
                    <hr className='text-muted' />

                    <div className='float-end'>
                      <Button
                        variant='outline-primary'
                        // href='#'
                        onClick={() => {
                          // console.log(1212,item.row_to_json.productid, item.row_to_json.productname)
                          setSelProductid(item.row_to_json.productid);
                          setSelProductName(item.row_to_json.productname);
                        }}
                      >
                        Select
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        {productsList && (
          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <div className='mb-0'>{'Selected Product: '} </div>
                <div className='mb-1 fw-bold'>
                  <span className='fw-bold'> {selProductName} </span>{' '}
                </div>
                <Button
                  variant='primary'
                  size='lg'
                  disabled={!(selProductid === -1) ? false : true}
                  onClick={saveProductSelection}
                >
                  Save & Continue
                </Button>{' '}
              </div>
            </Col>
          </Row>
        )}
      </section>
    </Container>
  );
}
