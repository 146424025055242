import React, { useEffect, useRef } from 'react';
import { useAuth } from '../../../contexts/auth';

import { useFormik } from 'formik';
import { Form, Button, Col, Row, InputGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Execute from './ModalForms/changeEMail/Execute';

const ChangeEMailForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      newemail: '',
    },
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 500));
      // alert(JSON.stringify(values, null, 2))
      // navigate('/', { replace: true })
    },
    validationSchema: Yup.object().shape({
      newemail: Yup.string().required('Required'),
    }),
  });

  const cancelExecution = () => {
    navigate(-1);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className='mb-3'>
        <Col md={4}>
          <Form.Label className='text-uppercase mt-1' htmlFor='newemail'>
            New E-Mail
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            id='newemail'
            name='newemail'
            type='newemail'
            placeholder='E-Mail'
            value={formik.values.newemail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.newemail && formik.touched.newemail && 'error'}`}
          />
          {formik.errors.newemail && formik.touched.newemail && (
            <div className='input-feedback'>{formik.errors.newemail}</div>
          )}
        </Col>
      </Row>
      <div className='mb-3'>
        <div className='float-end'>
          <Execute newemail={formik.values.newemail} newemailError={formik.errors.newemail} user={user} />{' '}
          <Button variant='secondary' onClick={cancelExecution}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ChangeEMailForm;
