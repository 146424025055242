import { useEffect, useState } from 'react';
import ClientContext from '../../store/client-context';
import { Button, Card, Col, Container, Form, Row, ListGroup, Toast, ToastContainer } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import HTTP from '../../api/HTTP';

async function getProviderIdent(categoryid) {
  const response = await fetch('/api/providers/category-ident', {
    method: 'PATCH',
    body: JSON.stringify({ categoryid: categoryid }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  return data;
}

async function getProviderIds(servicename) {
  const response = await fetch('/api/providers/id', {
    method: 'PATCH',
    body: JSON.stringify({ servicename: servicename }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  return data;
}

async function getServiceProviders(services) {
  // Get providerids
  let providers = [];
  for (let i = 0; i < services.length; i++) {
    providers.push(await getProviderIds(services[i]));
  }

  // console.log(providers)

  // Get category_idents
  for (let i = 0; i < providers.length; i++) {
    // console.log(providers[i])
    let ident = await getProviderIdent(providers[i].categoryid);
    // console.log(ident)

    providers[i].categoryident = ident.categoryident;
  }

  // console.log(providers)

  return providers;
}

async function createSignupInquiry(userData) {
  const response = await HTTP.post('/signup/create-signup-inquiry', JSON.stringify({ userData: userData }));
  return response.data;
}

export default function RequiresSignupDocuments(props) {
  const {
    signupStep,
    nextSignupStep,
    signupCompletion,
    getServices,
    signupPathPerson,
    signupDocsPerson,
    signupDocsCompany,
  } = props;
  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const acceptSignUp = async () => {
    const clientDataOne = {
      signupstage: 110,
      signupid: user.signupid,
      productid: -10, // values.productId,
    };

    await signupCompletion(clientDataOne);

    const clientData = {
      signupstage: 120,
      signupid: user.signupid,
      producttouid_agreed: true,
      signup_120_id: -10,
    };

    // console.log(1234, user);

    // Create signup Inquiry
    if ((signupPathPerson && signupDocsPerson) || (!signupPathPerson && signupDocsCompany)) {
      const signupInquiry = await createSignupInquiry(user);
    }

    const updated = await signupCompletion(clientData);
    if (updated.updated) {
      signOut();
    }
  };

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Comment: Signup continues in the Wallet with the request to upload the Signup Documents
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Signup Completion</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <Row className='g-2 mb-0'>
          <Col className='form-floating mb-3'>
            <p>
              Thanks very much for your application so far. Your application requires the provision and exchange of
              several documents. This exchange and the subsequent finalization of your application will be handled after
              a login to the Banking App. This way we can guarantee the secure exchange of documents. To proceed please
              click on "Login Again" and use your Login Credentials.
            </p>
          </Col>
        </Row>
        <Row className='g-2 mt-0 mb-3 '>
          <Col md>
            <div className='float-end'>
              <Button variant='primary' size='lg' onClick={acceptSignUp}>
                Continue and Login Again
              </Button>{' '}
              <Button variant='outline-primary' size='lg' onClick={logoutHandler}>
                Leave
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
