import React, { useEffect, useContext, useState } from 'react';
import { useAuth } from '../../../../contexts/auth';
import HTTP from '../../../../api/HTTP';

import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import PageHeader from '../../../../components/PageHeader';
// import Breadcrumbs from "../../../components/Breadcrumbs"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import CancelInvite from '../ModalForms/CancelInvite/Execute';

const AccountUsers = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [productServicesList, SetProductServicesList] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [inviteDeleted, setInviteDeleted] = useState(false);
  const [invitedUserCount, setInvitedUserCount] = useState(-1);
  const [connectedUsers, setConnectedUsers] = useState([]);

  useEffect(() => {
    if (!user) {
      navigate('/home', { replace: true });
    }
  }, [user]);

  useEffect(() => {
    const fetchInvitedUsers = async () => {
      await HTTP.get(`/client-user/invited-user-and-rights/${user.clientid}`)
        .then((response) => {
          setInvitedUsers(response.data);
          setInvitedUserCount(response.data.length);
          setInviteDeleted(false);
        })
        .catch((e) => {
          console.log('fetchInvitedUsers error occurred', e.message);
        });
    };
    if ((user && user.clientid) || inviteDeleted) {
      fetchInvitedUsers();
    }
  }, [user, inviteDeleted]);

  useEffect(() => {
    const fetchConnectedUsers = async () => {
      await HTTP.get(`/client-user/account-user-and-rights/${user.clientid}`)
        .then((response) => {
          setConnectedUsers(response.data);
        })
        .catch((e) => {
          console.log('fetchConnectedUsers error occurred', e.message);
        });
    };
    if (user && user.clientid) {
      fetchConnectedUsers();
    }
  }, [user]);

  const FormatDateString = (anDateString) => {
    const anDate = anDateString
      .slice(0, 16)
      // .replace(/-/g, "/")
      .replace('T', ' ');
    return anDate;
  };

  const openInviteDialog = () => {
    navigate('/accountdetails/account-users/inviteuser');
  };

  const closeProductPage = () => {
    navigate('/account-details');
  };

  const deletedInvite = async () => {
    if (invitedUserCount === 1) {
      await setInvitedUsers([]);
    } else {
      await setInviteDeleted(true);
    }
  };

  const OpenUserRights = (anUserid) => {
    navigate(`/accountdetails/account-users/userrights`);
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/* 
      <Breadcrumbs pages={[{ link: '/account-details', name: 'Account Details' }]} title={'Account Users'} />
      */}
      {/*  <PageHeader title={"Product"} /> */}

      <section className='mb-4 mb-lg-4'>
        <Card className='h-100'>
          <Card.Header>
            <h4 className='card-heading'>Account Users</h4>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Button variant='outline-primary' size='sm' onClick={openInviteDialog}>
                  Invite...
                </Button>{' '}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </section>
      {/*
      <section>
 <Row className="mb-2">
          <Col lg={4}>
            <Card
              className="mb-5 mb-lg-4 border-0 
                      card-highlight shadow-lg overflow-hidden                       
                    "
            >
              <div className="card-status bg-primary" />
              <Card.Body>
                <h2 className="card-heading text-primary fw-bold py-3">User</h2>
                <p className="text-muted mb-1">Frank Meyer</p>
                <hr className="text-muted" />

                <ul className="fa-ul my-4">
                  <li className="mb-3">Administrator</li>
                  <li className="mb-3">Read Only</li>
                </ul>
                <hr className="text-muted" />

                <div className="float-end">
                  <Button variant="outline-primary" size="sm" href="#">
                    Set Rights
                  </Button>{" "}
                  <Button variant="outline-primary" size="sm" href="#">
                    Remove User
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
*/}

      <section>
        <Row className='mb-5'>
          {connectedUsers &&
            (connectedUsers.length > 0 ? (
              connectedUsers.map((item, rowindex) => (
                <Col lg={4}>
                  <div key={rowindex}>
                    <Card
                      className='mb-2 mb-lg-2 border-0 
                      card-highlight shadow-lg overflow-hidden                       
                    '
                    >
                      <div className='card-status bg-primary' />
                      <Card.Body>
                        <h2 className='card-heading text-primary fw-bold py-3'>User</h2>
                        <p className='text-muted mb-1'>
                          {item.row_to_json.cl_firstname} {item.row_to_json.cl_lastname}
                        </p>
                        <p className='text-uppercase text-gray-500 fw-bold'>With the following Rights</p>

                        <ul className='fa-ul my-4 mt-0'>
                          {item.row_to_json.rights &&
                            item.row_to_json.rights.map((userrights, rowindex) => (
                              <li className='mb-0' key={rowindex}>
                                <span className='text-muted mb-1'> {userrights.rightname} </span>
                              </li>
                            ))}
                        </ul>

                        <p className='text-uppercase text-gray-500 fw-bold'>For (limited to)</p>

                        <ul className='fa-ul my-4 mt-0'>
                          {item.row_to_json.limits ? (
                            item.row_to_json.limits.map((userlimits, rowindex) => (
                              <li className='mb-0' key={rowindex}>
                                <span className='text-muted mb-1'> {userlimits.accountname} </span>
                              </li>
                            ))
                          ) : (
                            <span className='text-muted mb-1'>All Accounts</span>
                          )}
                        </ul>

                        <div className='float-end'>
                          <Button
                            variant='outline-primary'
                            size='sm'
                            href='#'
                            onClick={() => {
                              OpenUserRights(item.row_to_json.u_clientid);
                            }}
                          >
                            Set Rights
                          </Button>{' '}
                          <Button variant='outline-primary' size='sm' href='#'>
                            Remove User
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              ))
            ) : (
              <small className='text-gray-500'>None</small>
            ))}
        </Row>
      </section>

      <section>
        <Row className='mb-5'>
          {invitedUsers &&
            (invitedUsers.length > 0 ? (
              invitedUsers.map((item, rowindex) => (
                <Col lg={4}>
                  <div key={rowindex}>
                    <Card
                      className='mb-5 mb-lg-4 border-0 
                      card-highlight shadow-lg overflow-hidden                       
                    '
                    >
                      <div className='card-status bg-warning' />
                      <Card.Body>
                        <h2 className='card-heading text-primary fw-bold py-3'>Invited User</h2>
                        <p className='text-muted mb-1'>{item.row_to_json.u_cl_email}</p>
                        <p className='text-muted mb-1 mb-4'>
                          Invited on {FormatDateString(item.row_to_json.invitedate)}
                        </p>
                        <p className='text-uppercase text-gray-500 fw-bold'>With the following Rights</p>

                        <ul className='fa-ul my-4 mt-0'>
                          {item.row_to_json.rights &&
                            item.row_to_json.rights.map((userrights, rowindex) => (
                              <li className='mb-0' key={rowindex}>
                                <span className='text-muted mb-1'> {userrights.rightname} </span>
                              </li>
                            ))}
                        </ul>

                        <p className='text-uppercase text-gray-500 fw-bold'>For (limited to)</p>

                        <ul className='fa-ul my-4 mt-0'>
                          {item.row_to_json.limits ? (
                            item.row_to_json.limits.map((userlimits, rowindex) => (
                              <li className='mb-0' key={rowindex}>
                                <span className='text-muted mb-1'> {userlimits.accountname} </span>
                              </li>
                            ))
                          ) : (
                            <span className='text-muted mb-1'>All Accounts</span>
                          )}
                        </ul>

                        <div className='float-end'>
                          <CancelInvite
                            invitedEmail={item.row_to_json.u_cl_email}
                            inviteid={item.row_to_json.inviteid}
                            user={user}
                            deletedInvite={deletedInvite}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              ))
            ) : (
              <small className='text-gray-500'>None</small>
            ))}
        </Row>
      </section>

      <section>
        <Row className='mb-5'>
          <Col>
            <div className='float-end'>
              <Button variant='outline-secondary' onClick={closeProductPage}>
                Close
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default AccountUsers;
