import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';
import { merchantPartner } from '../../../utils/global-const';

// import Breadcrumbs from "../../components/Breadcrumbs"
import { useAuth } from '../../../contexts/auth';

export default function Index(props) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate('/home', { replace: true });
    }
  }, [user]);

  const closeDialog = () => {
    navigate(-1);
  };

  const clientChatid = user && parseInt(user.clientchatid);

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/*
      <Breadcrumbs title={'Chat'} />
       */}
      <section>
        <Row className='justify-content-md-center'>
          <Col lg={10}>
            <Card className='h-100'>
              <Card.Header>
                <h4 className='card-heading'>Recent Communications</h4>
              </Card.Header>
              <Card.Body>
                <div className='div-size'></div>
              </Card.Body>
              <Card.Footer>
                <div className='float-end'>
                  <Button variant='outline-secondary' onClick={closeDialog}>
                    Close
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
