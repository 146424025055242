import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, logOut } from '../api/auth';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {

      const result = await getUser();

      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();

    const handleStorageChange = () => {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        signOut();
      }
    }

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk && result.user !== undefined) {
      setUser(result.user);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    logOut();
  }, []);

  const setCurrentUser = useCallback((currentUser) => {
    setUser(currentUser);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        setCurrentUser,
        loading
      }}
      {...props}
    />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };
