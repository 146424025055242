import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.scss';

import { useAuth } from '../../contexts/auth';

import { Form, Container, Row, Col, Card, Button } from 'react-bootstrap';

import AssetCards from './Assets';

import {
  faCcMastercard,
  faBitcoin,
  faCreativeCommonsShare,
  faCreativeCommonsRemix,
} from '@fortawesome/free-brands-svg-icons';

import ContactInquiries from '../../own-components/dashboard-inquiries';

import InviteRequest from '../../own-components/InviteRequest';
import SignupDocuments from '../../own-components/SignupDocuments';
import ProductSelection from '../../own-components/SignupProduct/ProductSelection';
import { merchantPartner } from '../../utils/global-const';
import HTTP from '../../api/HTTP';

const Home = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [homeSummary, setHomeSummary] = useState([]);

  const clientid = user.clientid;
  const limitedaccounts = user.limitedaccounts;
  const userName = user.userName;
  const userFirstName = user.userFirstName;
  const productid = user.productid;
  const signupInquiryid = user.signupInquiryid;

  const inviteid = user.inviteid;
  const rightAdmin = user.rightAdmin;
  const rightAccounts = user.rightAccounts;

  useEffect(() => {
    const getClientHomeSummary = async () => {
      try {
        const response = await HTTP.get(
          `/client/home-summary/${clientid}/${limitedaccounts}/${user.loggedinclientid}/${user.assignedconnected}`
        );
        // console.log(response.data)
        setHomeSummary(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    getClientHomeSummary();
  }, []);

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format;

  function showAccountStatements() {
    navigate('/accountstatements');
  }

  function showAccountSetup() {
    navigate('/accountdetails');
  }

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {productid === -10 && <SignupDocuments inquiryId={signupInquiryid} />}
      {productid === -5 && <ProductSelection user={user} />}
      {productid > 0 && <ContactInquiries clientId={clientid} />}
      {inviteid && productid > 0 && (
        <InviteRequest clientid={clientid} userfirstname={userFirstName} userlastname={userName} inviteid={inviteid} />
      )}
      {productid > 0 && (
        <section className='mb-3 mb-lg-5'>
          <Row>
            <Col lg={6} className='mb-4 mb-lg-0'>
              <Card className='h-100'>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                        <div className='fw-bold'>Accounts Summary</div>
                      </div>{' '}
                    </Col>
                  </Row>

                  {homeSummary.accountSummary &&
                    homeSummary.accountSummary.map((summary, rowindex) => {
                      return (
                        <Row className='mb-0' key={rowindex}>
                          <Col xs={5} sm={4} md={4} lg={4} xl={3} xxl={3}>
                            <Form.Label className='text-gray-500 mt-0'>{summary.category_name}</Form.Label>
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={2} xl={2} xxl={1}>
                            <Form.Label className='text-gray-500 mt-0'>{summary.currency}</Form.Label>
                          </Col>
                          <Col xs={6} sm={3} md={2} lg={2} xl={2} xxl={2}>
                            <Form.Label className='text-gray-500 mt-0 float-end'>
                              {formatCurrency(parseFloat(summary.account_value).toFixed(2)).replace('EUR', '')}
                            </Form.Label>
                          </Col>
                        </Row>
                      );
                    })}

                  <Row className='mt-1'>
                    <Col xs={5} sm={4} md={4} lg={4} xl={3} xxl={3}>
                      <Form.Label className='fw-bold mt-0'>Total</Form.Label>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={2} xl={2} xxl={1}>
                      <Form.Label className='fw-bold mt-0'>{' EUR'}</Form.Label>
                    </Col>
                    <Col xs={6} sm={3} md={2} lg={2} xl={2} xxl={2}>
                      <Form.Label className='fw-bold mt-0 float-end'>
                        {homeSummary.totalAmount &&
                          formatCurrency(parseFloat(homeSummary.totalAmount).toFixed(2)).replace('EUR', '')}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} className='mt-2'>
                      {rightAdmin && !limitedaccounts && (
                        <div className='float-end'>
                          <Button variant='outline-secondary' size='sm' onClick={showAccountSetup}>
                            Account
                          </Button>{' '}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} className='mb-4 mb-lg-0'>
              <Card className='h-100'>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                        <div className='fw-bold'>Recent Activities</div>
                      </div>{' '}
                      {homeSummary.transactions && (
                        <Row>
                          <Col lg='12' className='mb-0 mb-lg-0'>
                            {homeSummary.transactions.map((transaction, rowindex) => {
                              let color;
                              let icon;

                              switch (transaction.feature) {
                                case 'Crypto Wallet':
                                  icon = faBitcoin;
                                  break;
                                case 'Metal Account':
                                  icon = faCreativeCommonsRemix;
                                  break;
                                case 'Virtual Prepaid Card Order':
                                  icon = faCcMastercard;
                                  break;
                                case 'Virtual Prepaid Card Order':
                                  icon = faCreativeCommonsShare;
                                  break;
                                default:
                                  icon = faCreativeCommonsShare;
                              }
                              switch (transaction.operation) {
                                case 'Buy':
                                case 'Deposit':
                                  color = 'green';
                                  break;
                                case 'Sell':
                                case 'Withdraw':
                                  color = 'black';
                                  break;
                                case 'Send':
                                case 'Receive':
                                  color = 'blue';
                                  break;
                                default:
                                  color = 'black';
                              }
                              return (
                                <Row className='mb-2' key={rowindex}>
                                  <Col xs={4} sm={3} md={3} lg={3} xl={2} xxl={2}>
                                    <div className='text'>
                                      <div className='text-gray-500 mb-0 '>
                                        <span>{transaction.transferdate.split('T', 1)}</span>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={3} md={2}>
                                    <div className='text-gray-500 mb-0 '>
                                      <div>{transaction.assetname}</div>
                                    </div>
                                  </Col>
                                  {/*
                                <Col xs={3} md={4}>
                                  <div className="text">
                                    <div className="text-gray-500 mb-0 ">
                                      <span>{transaction.servicename}</span>
                                    </div>
                                  </div>
                                </Col>
                                */}
                                  <Col xs={3} md={2}>
                                    <div className={`mb-0 text-${color}`}>
                                      <div>{transaction.operation}</div>
                                    </div>
                                  </Col>
                                  <Col xs={2} md={1}>
                                    <div className={`float-end mb-0 text-${color}`}>
                                      <div>
                                        {formatCurrency(parseFloat(transaction.currencyamount).toFixed(2)).replace(
                                          'EUR',
                                          ''
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} className='mt-2'>
                      <div className='float-end'>
                        <Button variant='outline-secondary' size='sm' onClick={showAccountStatements}>
                          Statement
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
      )}

      {homeSummary.assets && rightAccounts && <AssetCards data={homeSummary.assets} clientid={user.clientid} />}
    </Container>
  );
};

export default Home;
