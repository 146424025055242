import React, { useContext } from "react"
import { Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom";

const InquiryList = (props) => {
  const { requestList, setSelectedRequestId, clientId } = props

 const navigate = useNavigate();

  // Reverse request list
  // requestList.reverse()

  const forwardInquiry = async (inquiryId) => {
    if (inquiryId) {
      navigate(`inquiries/${inquiryId}`)
    }
  }

  return requestList ? (
    <>
      {requestList.map((request) => (
        <Row
          key={request.inquiryid}
          className={"mb-2 notification-row"}
          onClick={() => {
            setSelectedRequestId(request.inquiryid)
            forwardInquiry(request.inquiryid)
          }}
        >
          <Col md={8} lg={8}>
            <div>{request.subject}</div>
          </Col>
        </Row>
      ))}
    </>
  ) : (
    <></>
  )
}

export default InquiryList
