import { useEffect } from 'react';

import { useAuth } from '../../../contexts/auth';

import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
// import Breadcrumbs from '../../../components/Breadcrumbs';
import AssetBuy from '../AssetCards/AssetBuy';

const AssetBuyDialog = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user]);

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/* 
      <Breadcrumbs title='Buy Asset' />
*/}
      <section>
        {user.selCard && (
          <Row>
            <Col lg={6} className='mx-auto mb-5'>
              <Card>
                <Card.Header>
                  <h4 className='card-heading'>{user.selCard.servicename}</h4>
                </Card.Header>
                <Card.Body>
                  <p className='text-muted mb-4'>Buy Asset</p>
                  {user && (
                    <AssetBuy
                      user={user}
                      selCard={user.selCard}
                      acctid={user.selCard.assetaccountid}
                      assetid={user.selCard.svassetid}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </section>
    </Container>
  );
};

export default AssetBuyDialog;
