import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Modal } from 'react-bootstrap';
import HTTP from '../../../../../api/HTTP';

export default function Execute(props) {
  const { newemail, newemailError, user, inviteid } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const submitButtonRef = useRef(false);

  const toggleModal = () => {
    if (!newemailError && newemail !== '') {
      setShow(!show);
    }
  };

  async function inviteUser() {
    await HTTP.post(
      `/client-user/inviteuser`,
      JSON.stringify({
        inviteid: inviteid,
        user_email: newemail,
      })
    )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSubmit = async (values) => {
    await inviteUser();
    navigate('/accountdetails/account-users/accountusers');
  };

  return (
    <React.Fragment>
      <Button variant='primary' type='submit' onClick={toggleModal}>
        Invite
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Invite User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please confirm that you intend to invite the User with the following E-Mail?</p>
          <Form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <p>{newemail}</p>
            </div>
            <Button variant='primary' className='d-none' type='submit' ref={submitButtonRef}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitButtonRef.current.click()}>
            Invite
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
