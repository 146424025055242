import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
// import './single-card.scss';

export default function SingleCard({ title, description, children }) {
  return (
    <Container fluid className='px-lg-0 px-xl-0'>
      <div className='d-flex align-items-stretch'>
        <div className='page-holder align-items-center'>
          {React.Children.map(children, (item) => {
            return item;
            // item.type === Footer &&
          })}
        </div>
      </div>
    </Container>
  );
}
