import { Button } from "react-bootstrap";

/**
 * Custom Button
 * @param {*} param0 
 * @returns {Button}
 */

const CustomButton = (props) => {
  console.log(props);
  return (
    <>
      <style type="text/css">
        {`
          .btn {
            border: none;
            font-weight: 400;
            border-radius: 25px;
            width: fit-content;
          }

          .btn-normal {
            padding: 15px 40px;
            font-size: 18px;
            line-height: 21px;
          }

          .btn-normal-full {
            width: 100%;
            padding: 15px 40px;
            font-size: 18px;
            line-height: 21px;
          }

          .btn-small {
            padding: 8px 20px;
            font-size: 16px;
            line-height: 18px;
          }

          .btn-small-full {
            width: 100%;
            padding: 8px 20px;
            font-size: 16px;
            line-height: 18px;
          }

          .btn-main-light {
            color: #FFFFFF;
            background: #0A96FF;
            box-shadow: 0px 4px 50px rgba(55, 66, 250, 0.25);
          }

          .btn-main-light:hover {
            color: #FFFFFF;
            background: #0B89E8;
          }

          .btn-main-light:focus,
          .btn-main-light:active {
            color: #FFFFFF;
            background: #0A96FF;
            box-shadow: 0px 4px 50px rgba(55, 66, 250, 0.25);
          }

          .btn-secondary-light {
            color: #FFFFFF;
            background: #171616;
            box-shadow: 0px 4px 50px rgba(55, 66, 250, 0.25);
          }

          .btn-secondary-light:hover {
            color: #FFFFFF;
            background: #2d2b2b;
          }

          .btn-secondary-light:focus,
          .btn-secondary-light:active {
            color: #FFFFFF;
            background: #171616;
            box-shadow: 0px 4px 50px rgba(55, 66, 250, 0.25);
          }

          .btn-main-dark {
            color: #171616;
            background: #FFFFFF;
            box-shadow: 0px 4px 50px rgba(55, 66, 250, 0.25);
          }

          .btn-main-dark:hover {
            color: #171616;
            background: #F7F7F7;
          }

          .btn-main-dark:focus,
          .btn-main-dark:active {
            color: #171616;
            background: #FFFFFF;
            box-shadow: 0px 4px 50px rgba(55, 66, 250, 0.25);
          }

          .btn-secondary-dark {
            color: #171616;
            background: #0A96FF;
            box-shadow: 0px 4px 50px rgba(255, 255, 255, 0.25);
          }

          .btn-secondary-dark:hover {
            color: #171616;
            background: #098AEA;
          }

          .btn-secondary-dark:focus,
          .btn-secondary-dark:active {
            color: #171616;
            background: #0A96FF;
            box-shadow: 0px 4px 50px rgba(255, 255, 255, 0.25);
          }
        `}
      </style>

      <Button
        {...props}
      >
        {props.children}
      </Button>
    </>
  );
}

export default CustomButton;