import React, { useState, useEffect } from 'react';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash } from '@fortawesome/pro-thin-svg-icons';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../api/HTTP';
import Draggable from 'devextreme-react/draggable';
import Resizable from 'devextreme-react/resizable';
import Button from 'devextreme-react/button';
import Call from '../call/Call';
import './ClientCall.css';

const ClientCall = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const [alertCount, setAlertCount] = useState(0);
  const [pendingCallsData, setPendingCallsData] = useState([]);
  const [pendingCall, setPendingCall] = useState(false);

  const [roomId, setRoomId] = useState('');
  const [callVisible, setCallVisible] = useState(false);
  const [callNo, setCallNo] = useState();

  useEffect(() => {
    if (user && user.clientid) {
      fetchPendingCalls();

      // const interval = setInterval(() => {
      //   if (user && user.clientid) {
      //     fetchPendingCalls();
      //   }
      // }, 1000);

      // return () => clearInterval(interval);
    }
  }, [user]);

  const fetchPendingCalls = async () => {
    try {
      const response = await HTTP.get(`/client/client-pending-call/${user.clientid}`);
      setPendingCallsData(response.data);
      if (response.data.length > 0) {
        setPendingCall(true);
      } else {
        setPendingCall(false);
      }
    } catch (err) { }
  };

  const saveCallState = async (anActivityNo, anCallState) => {
    try {
      await HTTP.get(`/client/set-client-callstate/${anActivityNo}/${anCallState}`);
      // console.log(dataArray.data);
      //  refreshActivity();
    } catch (err) { }
  };

  const openCall = (an_room) => {
    setRoomId(an_room);
    setCallVisible(true);
  };

  const endCall = async () => {
    // Stop video stream
    // const video = document.querySelector('video');
    // const mediaStream = video.srcObject;
    // const tracks = mediaStream.getTracks();
    // tracks[0].stop();
    // tracks.forEach((track) => track.stop());

    // Hide call
    setCallVisible(false);
    console.log(1234, callNo);
    saveCallState(callNo, 3);
    window.location.reload();
  };

  const onCallClicked = () => {
    setPendingCall(false);
    setCallNo(pendingCallsData[0].call_no);
    openCall(pendingCallsData[0].call_roomid);
    saveCallState(pendingCallsData[0].call_no, 2);

  };

  return (
    <React.Fragment>
      {callVisible && (
        <Draggable id='callDraggable'>
          <Resizable id='callOverlay'>
            <Call roomId={roomId} />
            <Button id='endCall' text='End call' onClick={endCall}>
              <FontAwesomeIcon icon={faPhoneSlash} />
            </Button>
          </Resizable>
        </Draggable>
      )}

      {pendingCall && pendingCall === true && (
        <Dropdown as={NavItem} className='me-2 me-lg-3' align='end'>
          <Dropdown.Toggle
            as={NavLink}
            className='text-gray-400 px-1 nav-link-icon'
            id='inquiries'
            title='Call'
            onClick={onCallClicked}
          >
            <FontAwesomeIcon icon={faPhone} size='xl' color='blue' cursor='pointer' beat />
          </Dropdown.Toggle>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

export default ClientCall;
