import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';
import HTTP from '../../api/HTTP';

export default function TermsOfUse(props) {
  const { signupStep, nextSignupStep, signupCompletion } = props;
  const { signOut, user } = useAuth();
  const [termsOfUseData, setTermsOfUseData] = useState([]);
  const [acceptTOU, setAcceptTOU] = useState(false);
  const [showInvalidEntry, setShowInvalidEntry] = useState(false);

  useEffect(() => {
    // console.log(signupStep);

    const loadTermsofUse = async () => {
      await HTTP.get(`/signup/tou/${user.partnerid}`)
        .then((response) => {
          setTermsOfUseData(response.data[0]);
        })
        .catch((e) => {
          console.log('loadTermsofUse error occurred', e.message);
        });
    };
    if (user) {
      loadTermsofUse();
    }
  }, [user]);

  const acceptTermsofUse = async () => {
    console.log(termsOfUseData.partnertouid);

    if (acceptTOU === true) {
      const clientData = {
        signupstage: 50,
        signupid: user.signupid,
        producttouid: termsOfUseData.partnertouid,
        producttouid_agreed: acceptTOU,
      };

      const updated = await signupCompletion(clientData);
      if (updated.updated) {
        nextSignupStep(updated.signupstage); // 50
      }
    } else {
      setShowInvalidEntry(true);
    }
  };

  function logoutHandler() {
    signOut();
  }

  return (
    <Card>
      <ToastContainer className='p-3' position={'bottom-start'}>
        <Toast show={true} bg='warning'>
          <Toast.Body>
            Comment: These are global Terms of Use the Customer has to accept. It may be required that we add Privacy
            Terms for acceptance at this stage as well.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Card.Header className='px-lg-5'>
        <div className='card-heading text-primary'>Terms Of Use</div>
      </Card.Header>
      <Card.Body className='p-lg-5'>
        <ToastContainer className='p-3' position={'bottom-start'}>
          <Toast show={showInvalidEntry} bg='warning'>
            <Toast.Body>It is required to accept the Terms of Use before you may continue</Toast.Body>
          </Toast>
        </ToastContainer>
        <Form id='termsOfUseForm'>
          <Row>
            <Col>
              <p>Please read the following Terms of Use carefully and let us know whether you accept these or not</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='form-floating mb-3'>
                <Form.Control
                  as='textarea'
                  id='termsOfUse'
                  name='termsOfUse'
                  type='termsOfUse'
                  placeholder='Terms Of Use'
                  style={{ height: '400px' }}
                  value={termsOfUseData.doc_content}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='form-floating mb-3'>
                <Form.Group>
                  <Form.Check
                    label='I accept the Terms Of Use'
                    type='checkbox'
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setAcceptTOU(e.target.checked);
                      setShowInvalidEntry(false);
                    }}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row className='g-2 mt-0 mb-3 '>
            <Col md>
              <div className='float-end'>
                <Button
                  variant='primary'
                  size='lg'
                  // type="submit"
                  disabled={!acceptTOU}
                  onClick={acceptTermsofUse}
                >
                  Continue
                </Button>{' '}
                <Button
                  variant='outline-primary'
                  size='lg'
                  // type="submit"
                  // disabled={isSubmitting}
                  onClick={logoutHandler}
                >
                  Leave
                </Button>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </Form>
      </Card.Body>
    </Card>
  );
}
