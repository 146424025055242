import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../../../contexts/auth';
import HTTP from '../../../../api/HTTP';

import { useNavigate } from 'react-router-dom';
import { Form, Button, Card, Col, Container, Row } from 'react-bootstrap';
// import Breadcrumbs from "../../../components/Breadcrumbs"

import Execute from '../ModalForms/InviteUser/Execute';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const InviteUser = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [inviteID, setInviteID] = useState();
  const [inviteRights, setInviteRights] = useState([]);
  const [lpRightsData, setLpRightsData] = useState([]);
  const [lpClientServicesData, setlpClientServicesData] = useState([]);
  const [invitelimits, setInvitelimits] = useState([]);
  const [rightsCount, setRightsCount] = useState(0);

  const formik = useFormik({
    initialValues: { newemail: '', lpright: 1, lpserviceid: 1 },
    // onSubmit={async (values) => alert(JSON.stringify(values, null, 2))}
    validationSchema: Yup.object().shape({
      newemail: Yup.string().required('Required'),
    }),
  });

  useEffect(() => {
    {
      const invitUserInit = async () => {
        try {
          const response = await HTTP.get(`/client-user/invite-user-init/${user.clientid}`);
          setInviteID(response.data[0].invite_user_init);

          const rightsresponse = await HTTP.get(`/client-user/invite-user-rights/${response.data[0].invite_user_init}`);
          setInviteRights(rightsresponse.data);

          const limitsresponse = await HTTP.get(`/client-user/invite-user-limits/${response.data[0].invite_user_init}`);
          setInvitelimits(limitsresponse.data);
        } catch (err) {}
      };

      const loadlpRights = async () => {
        await HTTP.get(`/client-user/lp-rights`)
          .then((response) => {
            setLpRightsData(response.data);
          })
          .catch((e) => {
            console.log('loadlpRights error occurred', e.message);
          });
      };

      const loadClientServices = async () => {
        await HTTP.get(`/client-user/client-services/${user.clientid}`)
          .then((response) => {
            setlpClientServicesData(response.data);
          })
          .catch((e) => {
            console.log('loadlpRights error occurred', e.message);
          });
      };

      if (user && user.clientid) {
        invitUserInit();
        loadlpRights();
        loadClientServices();
      }
    }
  }, [user]);

  const cancelExecution = async () => {
    try {
      const Deleteresponse = await HTTP.get(`/client-user/invite-user-delete/${inviteID}`);
      navigate('/accountdetails/account-users/');
    } catch (err) {
      console.log(err);
    }
  };

  const deleteRight = async (anRightid) => {
    await HTTP.get(`/client-user/delete-right-invite/${inviteID}/${anRightid}`)
      .then((response) => {
        setInviteRights(response.data);
      })
      .catch((e) => {
        console.log('deleteRight error occurred', e.message);
      });
  };

  const deleteLimit = async (anlimitid) => {
    await HTTP.get(`/client-user/delete-limit-invite/${inviteID}/${anlimitid}`)
      .then((response) => {
        setInvitelimits(response.data);
      })
      .catch((e) => {
        console.log('deleteLimit error occurred', e.message);
      });
  };

  const addRighttoUser = async (anRightid) => {
    // console.log(anRightid)

    await HTTP.post(
      `/client-user/add-right-to-invite-user`,
      JSON.stringify({
        clientid: user.clientid,
        inviteid: inviteID,
        rightid: anRightid,
      })
    )
      .then((response) => {
        setInviteRights(response.data);
        setRightsCount(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addLimittoUser = async (anAssetAccountid) => {
    const object = lpClientServicesData.filter((x) => {
      return x.assetaccountid === parseInt(anAssetAccountid);
    });

    const selservice = object[0].service;

    await HTTP.post(
      `/client-user/add-limit-to-invite-user`,
      JSON.stringify({
        clientid: user.clientid,
        inviteid: inviteID,
        assetaccountid: anAssetAccountid,
        accountname: selservice,
      })
    )
      .then((response) => {
        setInvitelimits(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/** 
          <Breadcrumbs
            pages={[
              { link: "/account-details", name: "Account Details" },
              {
                link: "/accountdetails/account-users/",
                name: "Account User",
              },
            ]}
            title="Invite User"
          />
*/}
      <section>
        <Row>
          <Col lg={5} className='mx-auto mt-5 mb-5'>
            <Card>
              <Card.Header>
                <h4 className='card-heading'>Invite User</h4>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row className='mb-3'>
                    <Col md={12}>
                      <p className='text-muted mb-0'>Please enter the E-Mail of the User you intend to invite</p>
                    </Col>
                  </Row>
                  <Row className='mb-3 mt-0'>
                    <Col md={12}>
                      <Form.Control
                        id='newemail'
                        name='newemail'
                        type='newemail'
                        placeholder='User E-Mail'
                        value={formik.values.newemail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.newemail && formik.touched.newemail && 'error'}`}
                      />
                      {formik.errors.newemail && formik.touched.newemail && (
                        <div className='input-feedback'>{formik.errors.newemail}</div>
                      )}
                    </Col>
                  </Row>

                  <p className='mb-1 mt-4'>
                    <span className='text-uppercase text-gray-500 fw-bold'>Rights </span>{' '}
                  </p>
                  <Row>
                    <Col md={7}>
                      <Form.Select id='lpright' name='lpright' as='select' placeholder='' className='form-select'>
                        {lpRightsData.map((item, rowindex) => (
                          <option value={item.rightid} key={rowindex}>
                            {item.rightname}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md={2} className='d-grid'>
                      <Button
                        variant='light'
                        size='sm'
                        className='text-gray-500'
                        onClick={() => {
                          addRighttoUser(formik.values.lpright);
                        }}
                      >
                        Add...
                      </Button>
                    </Col>
                  </Row>
                  <hr className='text-muted mt-2' />
                  {inviteRights &&
                    inviteRights.map((rightsitem, rowindex) => {
                      return (
                        <Row className='mb-2' key={rowindex}>
                          <Col md={7}>
                            <span className='text-muted mb-2'>{rightsitem.rightname} </span>
                          </Col>
                          <Col md={3} className='d-grid'>
                            <Button
                              variant='light'
                              size='sm'
                              className='text-gray-500'
                              onClick={() => {
                                deleteRight(rightsitem.rightid);
                              }}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  <hr className='text-muted' />

                  <p className='mb-1 mt-4'>
                    <span className='text-uppercase text-gray-500 fw-bold'>Limit Access to </span>{' '}
                  </p>
                  <Row>
                    <Col md={7}>
                      <Form.Select
                        id='lpserviceid'
                        name='lpserviceid'
                        as='select'
                        placeholder=''
                        className='form-select'
                      >
                        {lpClientServicesData.map((item, rowindex) => (
                          <option value={item.assetaccountid} key={rowindex}>
                            {item.service}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md={2} className='d-grid'>
                      <Button
                        variant='light'
                        size='sm'
                        className='text-gray-500'
                        onClick={() => {
                          addLimittoUser(formik.values.lpserviceid);
                        }}
                      >
                        Add...
                      </Button>
                    </Col>
                  </Row>
                  <hr className='text-muted mt-2' />
                  {invitelimits &&
                    invitelimits.map((limitsitem, rowindex) => {
                      return (
                        <Row className='mb-2' key={rowindex}>
                          <Col md={7}>
                            <span className='text-muted mb-2'>{limitsitem.accountname} </span>
                          </Col>
                          <Col md={3} className='d-grid'>
                            <Button
                              variant='light'
                              size='sm'
                              className='text-gray-500'
                              onClick={() => {
                                deleteLimit(limitsitem.assetaccountid);
                              }}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  <hr className='text-muted' />

                  <Row className='mb-3'>
                    <Col md={12}>
                      <div className='mb-3'>
                        <div className='float-end'>
                          <Execute
                            newemail={formik.values.newemail}
                            newemailError={formik.errors.newemail}
                            inviteid={inviteID}
                            user={user}
                          />{' '}
                          <Button variant='secondary' onClick={cancelExecution}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default InviteUser;
