import React, { useEffect, useContext, useState } from 'react';
import { useAuth } from '../../../contexts/auth';
import HTTP from '../../../api/HTTP';

import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import PageHeader from '../../../components/PageHeader';
// import Breadcrumbs from "../../components/Breadcrumbs"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ProductServicesDialog = () => {
  const { user } = useAuth();
  const [productServicesList, SetProductServicesList] = useState([]);
  const [productData, SetProductData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      await HTTP.get(`/account/product/${user.productid}/${user.client_risklevelid}`)
      .then((response) => {
        SetProductData(response.data[0])
    })
      .catch((e) => {
        console.log('fetchProduct error occurred', e.message);
      });
    };

    const fetchProductServices = async () => {
      await HTTP.get(`/account/product-services/${user.productid}/${user.client_risklevelid}`)
      .then((response) => {
        SetProductServicesList(response.data);
    })
      .catch((e) => {
        console.log('fetchProductServices error occurred', e.message);
      });
    }


    if (user && user.clientid) {
      fetchProduct();
      fetchProductServices();
    }
  }, [user]);

  const closeProductPage = () => {
    navigate(-1);
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      {/* 
      <Breadcrumbs
        pages={[{ link: "/account-details", name: "Account Details" }]}
        title={"Main Product"}
      />
      */}

      {/*  <PageHeader title={"Product"} /> */}

      <section className='mb-4 mb-lg-4'>
        {productData.productname && (
          <Card className='h-100'>
            <Card.Header>
              <h4 className='card-heading'>{productData.productname}</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                    <div className='fw-bold'>Setup Fee</div>
                    <div>
                      <small className='text-gray-500'>{productData.currency + ' ' + productData.setupfee}</small>
                    </div>
                  </div>{' '}
                  <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                    <div className='fw-bold'>Recurring Fee</div>
                    <div>
                      <small className='text-gray-500'>
                        {productData.currency + ' ' + productData.recurringfee + ' per ' + productData.period}
                      </small>
                    </div>
                  </div>{' '}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </section>

      <section>
        {productData.productname && (
          <Row className='mb-5'>
            {productServicesList &&
              productServicesList.map((item, rowindex) => (
                <Col lg={4} key={rowindex}>
                  <Card
                    className={`mb-5 mb-lg-4 border-0  ${
                      item.row_to_json.servicename ? 'card-highlight shadow-lg overflow-hidden' : 'shadow'
                    }`}
                  >
                    {item.row_to_json.featured && <div className='card-status bg-primary' />}
                    <Card.Body>
                      <h2 className='card-heading text-primary text-center fw-bold py-3'>
                        {item.row_to_json.servicename}
                      </h2>
                      {/*  <p className="text-muted text-center mb-4">
                      <span className="h1 text-dark fw-bold">
                        €{item.row_to_json.categoryid}
                      </span>
                      <span className="ms-2">/ month</span>
                    </p>
                  */}
                      <hr className='text-muted' />

                      <ul className='fa-ul my-4'>
                        {item.row_to_json.service_fees &&
                          item.row_to_json.service_fees.map((serviceitem, rowindex) => (
                            <li className='mb-3' key={rowindex}>
                              <span className={`fa-li ${serviceitem.feature ? 'text-muted' : 'text-primary'}`}>
                                {/*   <FontAwesomeIcon
                              icon={serviceitem.feature ? faTimes : faCheck}
                            />
                          */}
                              </span>
                              {(serviceitem.costvalue > 0 || serviceitem.costperc > 0) &&
                              serviceitem.feature_group_position > 1
                                ? serviceitem.feature_group +
                                  ' - ' +
                                  (serviceitem.costvalue || serviceitem.costperc
                                    ? serviceitem.costvalue
                                      ? ' ' + productData.currency + ' ' + serviceitem.costvalue
                                      : serviceitem.costperc_minvalue
                                      ? ' ' +
                                        serviceitem.costperc +
                                        '% min. ' +
                                        ' ' +
                                        productData.currency +
                                        ' ' +
                                        serviceitem.costperc_minvalue +
                                        ' max. ' +
                                        ' ' +
                                        serviceitem.costperc_maxvalue
                                      : ' ' + serviceitem.costperc + '%'
                                    : '')
                                : ''}
                            </li>
                          ))}
                      </ul>

                      {/*     <div className="text-center">
                      <Button variant="outline-primary" href="#">
                        Status
                      </Button>
                    </div>
                */}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        )}
      </section>
      <section>
        {productData.productname && (
          <Row className='mb-5'>
            <Col>
              <div className='float-end'>
                <Button variant='outline-secondary' onClick={closeProductPage}>
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </section>
    </Container>
  );
};

export default ProductServicesDialog;
