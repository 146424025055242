import React, { useEffect, useRef } from 'react';
import { useAuth } from '../../../contexts/auth';

import { useFormik } from 'formik';
import { Form, Button, Col, Row, InputGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Execute from './ModalForms/ChangeAddress/Execute';

const ChangeAddressForm = () => {
  const { user } = useAuth();
  const formikRef = useRef();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      street: user.cl_street,
      cityplc: user.cl_plc,
      cityname: user.cl_city,
      country: user.cl_countryofresidence,
    },
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 500));
      // alert(JSON.stringify(values, null, 2))
      // navigate('/', { replace: true })
    },
    validationSchema: Yup.object().shape({
      street: Yup.string().required('Required'),
      cityplc: Yup.string().required('Required'),
      cityname: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
    }),
  });

  const cancelExecution = () => {
    navigate(-1);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className='mb-3'>
        <Col md={4}>
          <Form.Label className='text-uppercase mt-1' htmlFor='street'>
            Street
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            id='street'
            name='street'
            type='street'
            placeholder='Street'
            value={formik.values.street}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.street && formik.touched.street && 'error'}`}
          />
          {formik.errors.street && formik.touched.street && (
            <div className='input-feedback'>{formik.errors.street}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={4}>
          <Form.Label className='text-uppercase mt-1' htmlFor='cityname'>
            City
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            id='cityname'
            name='cityname'
            type='cityname'
            placeholder='City'
            value={formik.values.cityname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.cityname && formik.touched.v && 'error'}`}
          />
          {formik.errors.cityname && formik.touched.cityname && (
            <div className='input-feedback'>{formik.errors.cityname}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={4}>
          <Form.Label className='text-uppercase mt-1' htmlFor='cityplc'>
            City PLC
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            id='cityplc'
            name='cityplc'
            type='cityplc'
            placeholder='City PLC'
            value={formik.values.cityplc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.cityplc && formik.touched.v && 'error'}`}
          />
          {formik.errors.cityplc && formik.touched.cityplc && (
            <div className='input-feedback'>{formik.errors.cityplc}</div>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={4}>
          <Form.Label className='text-uppercase mt-1' htmlFor='country'>
            Country
          </Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            id='country'
            name='country'
            type='country'
            placeholder='Country'
            value={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${formik.errors.country && formik.touched.v && 'error'}`}
          />
          {formik.errors.country && formik.touched.country && (
            <div className='input-feedback'>{formik.errors.country}</div>
          )}
        </Col>
      </Row>
      <div className='mb-3'>
        <div className='float-end'>
          <Execute
            street={formik.values.street}
            streetError={formik.errors.account}
            cityname={formik.values.cityname}
            citynameError={formik.errors.cityname}
            cityplc={formik.values.cityplc}
            cityplcError={formik.errors.cityplc}
            country={formik.values.country}
            countryError={formik.errors.country}
            user={user}
          />{' '}
          <Button variant='secondary' onClick={cancelExecution}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ChangeAddressForm;
