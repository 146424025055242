import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../../../../api/HTTP';
import { Form, Button, Modal } from 'react-bootstrap';

export default function Execute(props) {
  const { productid, productname, recurringfee, setupfee, currency, user } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const submitButtonRef = useRef(false);

  const toggleModal = () => {
    if (productid !== '' && productname) {
      setShow(!show);
    }
  };

  async function OrderProduct() {
    const response = await HTTP.post(
      '/client/order-additional-product',
      JSON.stringify({
        clientid: user.clientid,
        productid: productid,
      })
    );
    console.log(response.data);
    setShow(false);
  }

  const handleSubmit = async (values) => {
    await OrderProduct();
  };

  return (
    <React.Fragment>
      <Button variant='primary' type='submit' onClick={toggleModal}>
        Order
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header className='border-0 bg-gray-100' closeButton>
          <Modal.Title as='h5' className='text-uppercase'>
            Order Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please confirm that you intend to order the following Product?</p>
          <Form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <p>
                {productname} <br /> Fee per Month: {currency + ' '} {recurringfee} <br /> Setup Fee: {currency + ' '}{' '}
                {setupfee}{' '}
              </p>
            </div>
            <Button variant='primary' className='d-none' type='submit' ref={submitButtonRef}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitButtonRef.current.click()}>
            Order
          </Button>
          <Button variant='secondary' onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
