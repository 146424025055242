import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Container, Form, Button, Row, Col, InputGroup, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HTTP from '../../../api/HTTP';
import * as Yup from 'yup';
import Execute from './ModalForms/AssetBuy/Execute';

export default function AssetBuyForm(props) {
  const { user, selCard, acctid, assetid } = props;
  const navigate = useNavigate();
  const [lpExecutionType, setLpExecutionType] = useState([]);
  const [clientBankAccounts, setClientBankAccounts] = useState([]);
  const [counter, setCounter] = useState(0);
  const [showCounter, setShowCounter] = useState(false);

  // useEffect(() => {
  //   console.log(44444, selCard);
  // }, [selCard]);

  const formik = useFormik({
    initialValues: {
      buyAsset: selCard.assetname,
      buyAmount: '',
      buyValue: '',
      buyFrom: 1,
      buyExecution: 1,
    },
    onSubmit: async (values) => {
      await new Promise((r) => setTimeout(r, 500));
      // alert(JSON.stringify(values, null, 2))
      // navigate('/', { replace: true })
    },
    validationSchema: Yup.object().shape({
      buyAsset: Yup.string().required('Required'),
      buyAmount: Yup.number().required('Required').positive(),
      buyValue: Yup.number().required('Required').positive(),
    }),
  });

  useEffect(() => {
    const fetcExecutionType = async () => {
      await HTTP.get(`/base/get-lookup-values/19`)
        .then((response) => {
          setLpExecutionType(response.data);
        })
        .catch((e) => {
          console.log('fetcExecutionType error occurred', e.message);
        });
    };

    const fetchClientBankAccounts = async () => {
      await HTTP.get(`/client/client-bank-accounts/${user.clientid}/${user.loggedinclientid}/${user.limitedaccounts}`)
        .then((response) => {
          //  console.log(55555, response.data);
          formik.values.buyFrom = response.data[0].assetaccountid;
          setClientBankAccounts(response.data);
        })
        .catch((e) => {
          console.log('fetchClientBankAccounts error occurred', e.message);
        });
    };

    if (user && user.clientid) {
      fetcExecutionType();
      fetchClientBankAccounts();
    }
  }, [user]);

  const cancelExecution = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setShowCounter(false);
    }
  }, [counter]);
  const offerRequest = async (offertype) => {
    try {
      const response = await HTTP.post(
        '/ops/asset-offer-request',
        JSON.stringify({
          assetofferrequest: 1,
          buysellop: 1,
          offertype: offertype,
          clientid: user.clientid,
          serviceid: selCard.serviceid,
          providerid: selCard.providerid,
          partnerid: user.partnerid,
          productid: user.productid,
          routingid: selCard.routingid,
          assetid: selCard.svassetid,
          assetamount: formik.values.buyAmount,
          currencyamount: formik.values.buyValue,
          currency: selCard.currency,
          categoryident: selCard.category_ident,
        })
      );

      const data = response.data;
      console.log(data);
      if (offertype === 1) {
        formik.setFieldValue('buyValue', data.offer_currencyamount);
      } else {
        formik.setFieldValue('buyAmount', data.offer_assetamount);
      }

      setCounter(data.offer_valid_count);
      setShowCounter(true);
    } catch (error) {
      console.log('asset-offer-error', error);
    }
  };

  return (
    <Container fluid className='px-lg-4 px-xl-5'>
      <Form onSubmit={formik.handleSubmit}>
        <ToastContainer className='p-3' position={'bottom-start'}>
          <Toast show={showCounter}>
            <Toast.Body>Offer valid for {counter} seconds</Toast.Body>
          </Toast>
        </ToastContainer>
        <Row className='mb-3'>
          <Col md={3}>
            <Form.Label className='text-uppercase mt-1' htmlFor='buyAsset'>
              Buy
            </Form.Label>
          </Col>
          <Col md={9}>
            <Form.Control
              id='buyAsset'
              name='buyAsset'
              type='buyAsset'
              placeholder='Asset'
              value={formik.values.buyAsset}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`form-control ${formik.errors.buyAsset && formik.touched.buyAsset && 'error'}`}
            />
            {formik.errors.buyAsset && formik.touched.buyAsset && (
              <div className='input-feedback'>{formik.errors.buyAsset}</div>
            )}
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={3}>
            <Form.Label className='text-uppercase mt-1' htmlFor='buyAmount'>
              Amount
            </Form.Label>
          </Col>
          <Col md={4}>
            <InputGroup>
              <InputGroup.Text>{selCard.assetcalcunit}</InputGroup.Text>
              <Form.Control
                id='buyAmount'
                name='buyAmount'
                type='buyAmount'
                // placeholder="Amount"
                aria-label='Amount (with dot and two decimal places)'
                value={formik.values.buyAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`form-control ${formik.errors.buyAmount && formik.touched.buyAmount && 'error'}`}
              />
            </InputGroup>
          </Col>
          <Col md={3} className='d-grid'>
            <Button
              variant='secondary'
              size='sm'
              onClick={() => {
                offerRequest(1);
              }}
            >
              Request Offer
            </Button>
          </Col>
          <Col md={3} />
          <Col md={7}>
            {formik.errors.buyAmount && formik.touched.buyAmount && (
              <div className='input-feedback'>{formik.errors.buyAmount}</div>
            )}
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={3}>
            <Form.Label className='text-uppercase mt-1' htmlFor='buyValue'>
              For
            </Form.Label>
          </Col>
          <Col md={4}>
            <InputGroup>
              <InputGroup.Text>{selCard.currency}</InputGroup.Text>
              <Form.Control
                id='buyValue'
                name='buyValue'
                type='buyValue'
                // For"
                aria-label='Amount (with dot and two decimal places)'
                value={formik.values.buyValue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`form-control ${formik.errors.buyValue && formik.touched.buyValue && 'error'}`}
              />
            </InputGroup>
          </Col>
          <Col md={3} className='d-grid'>
            <Button
              variant='secondary'
              size='sm'
              onClick={() => {
                offerRequest(2);
              }}
            >
              Request Offer
            </Button>
          </Col>
          <Col md={3} />
          <Col md={7}>
            {formik.errors.buyValue && formik.touched.buyValue && (
              <div className='input-feedback'>{formik.errors.buyValue}</div>
            )}
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={3}>
            <Form.Label className='text-uppercase mt-1' htmlFor='buyFrom'>
              From
            </Form.Label>
          </Col>
          <Col md={9}>
            <Form.Select
              id='buyFrom'
              name='buyFrom'
              as='select'
              placeholder='Bank Account'
              className='form-select'
              value={formik.values.buyFrom}
              onChange={formik.handleChange}
            >
              {clientBankAccounts.map((item, rowindex) => (
                <option value={item.assetaccountid} key={rowindex}>
                  {item.bank}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={3}>
            <Form.Label className='text-uppercase mt-1' htmlFor='buyExecution'>
              Execution
            </Form.Label>
          </Col>
          <Col md={9}>
            <Form.Select
              id='buyExecution'
              name='buyExecution'
              type='buyExecution'
              as='select'
              placeholder='Execution'
              className='form-select'
              value={formik.values.buyExecution}
              onChange={formik.handleChange}
            >
              {lpExecutionType.map((item, rowindex) => (
                <option value={item.lp_id} key={rowindex}>
                  {item.lp_value}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <div className='mb-3'>
          <div className='float-end'>
            <Execute
              buyAsset={formik.values.buyAsset}
              buyAssetError={formik.errors.buyAsset}
              buyAmount={formik.values.buyAmount}
              buyAmountError={formik.errors.buyAmount}
              buyValue={formik.values.buyValue}
              buyValueError={formik.errors.buyValue}
              buyFrom={formik.values.buyFrom}
              user={user}
              selCard={selCard}
              accountid={acctid}
              assetid={assetid}
            />{' '}
            <Button variant='secondary' onClick={cancelExecution}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
}
