import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import HTTP from '../../api/HTTP';

import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';

import { Container, Row, Col, Card, Button, Modal, ToggleButton } from 'react-bootstrap';

// import Breadcrumbs from "../components/Breadcrumbs"
import { useAuth } from '../../contexts/auth';
import { date } from 'yup';
import { useReactToPrint } from 'react-to-print';

export default function AccountDetails() {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [clientAccountServices, setClientAccountServices] = useState([]);
  const [clientAccountServicesAdditional, setClientAccountServicesAdditional] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [termsOfUse, setTermsOfUse] = useState([]);
  const [geolocationData, setGeolocationData] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const printRef = useRef(null);

  useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user]);

  useEffect(() => {
    console.log('--yyy--');
    console.log(user);
    console.log('--yyyy--');
  }, []);

  const closeAccount = () => {
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const fetchClientAccountServices = async () => {
      await HTTP.get(`/account/client-account-services/${user.clientid}/1`)
        .then((response) => {
          setClientAccountServices(response.data);
        })
        .catch((e) => {
          console.log('fetchClientAccountServices error', e.message);
        });
    };

    const fetchClientAccountServicesAdditional = async () => {
      await HTTP.get(`/account/client-account-services/${user.clientid}/2`)
        .then((response) => {
          setClientAccountServicesAdditional(response.data);
        })
        .catch((e) => {
          console.log('fetchClientAccountServicesAdditional error', e.message);
        });
    };

    const fetchClientUsers = async () => {
      await HTTP.get(`/client-user/account-users/${user.clientid}`)
        .then((response) => {
          setClientUsers(response.data);
        })
        .catch((e) => {
          console.log('fetchClientUsers error', e.message);
        });
    };

    const fetchInvitedUsers = async () => {
      await HTTP.get(`/client-user/invited-users/${user.clientid}`)
        .then((response) => {
          setInvitedUsers(response.data);
        })
        .catch((e) => {
          console.log('fetchInvitedUsers error', e.message);
        });
    };

    const getTOU = async (serviceid) => {
      try {
        const response = await HTTP.get(`/tou/tou-service/${serviceid}`);
        return response.data[0];
      } catch (err) {}
    };

    const getServicesTOU = async (clientid) => {
      try {
        const response = await HTTP.get(`/tou/tou-client/${clientid}`);
        const tou = [];
        for (let i = 0; i < response.data.length; i++) {
          tou.push(await getTOU(response.data[i].serviceid));
        }
        // console.log(tou)
        setTermsOfUse(tou);
      } catch (err) {}
    };

    const fetchGeolocationData = async (clientid) => {
      await HTTP.get(`/geoip/client-geo-data/${clientid}`)
        .then((response) => {
          setGeolocationData(response.data);
        })
        .catch((e) => {
          console.log('fetchInvitedUsers error', e.message);
        });
    };

    if (user && user.clientid) {
      fetchClientAccountServices();
      fetchClientAccountServicesAdditional();
      fetchClientUsers();
      fetchInvitedUsers();
      getServicesTOU(user.clientid);
      fetchGeolocationData(user.clientid);
    }
  }, [user]);

  const openChangeAddressDialog = () => {
    navigate('/accountdetails/changeaddress');
  };

  const openChangeEMailDialog = () => {
    navigate('/accountdetails/changeemail');
  };

  const openChangePasswordDialog = () => {
    navigate('/accountdetails/changepassword');
  };

  const openMainProductFees = () => {
    navigate('/accountdetails/productservices');
  };

  const changeMainProduct = () => {
    navigate('/accountdetails/products');
  };

  const changeSingleServiceProducts = () => {
    navigate('/accountdetails/productssingle');
  };

  const openAdditionalServices = () => {
    navigate('/accountdetails/additional-services');
  };

  const openAccountUsers = () => {
    navigate('/accountdetails/account-users');
  };

  const setModal = (title, content) => {
    setTitle(title);
    setContent(content);
    handleShow();
  };

  const handleLocationWhitelist = async (whitelisted, ip) => {
    if (!whitelisted) {
      if (window.confirm('Set IP location on whitelist?')) {
        await updateIPWhitelist(true, ip);
        window.location.reload();
      }
    } else {
      if (window.confirm('Remove IP location from whitelist?')) {
        await updateIPWhitelist(false, ip);
        window.location.reload();
      }
    }
  };

  const updateIPWhitelist = async (whitelisted, ip) => {
    const response = await fetch('/api/geoip/update-ip-whitelist', {
      method: 'POST',
      body: JSON.stringify({
        whitelisted: whitelisted,
        clientid: user.clientid,
        ip: ip,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (data.status === 'success') return true;
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'TermsOfUse',
  });

  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={printRef}>{content}</Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handlePrint}>
            Print
          </Button>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className='px-lg-4 px-xl-5'>
        {/* 
      <Breadcrumbs title='Account Details' />
*/}
        <section className='mb-4 mb-lg-5'>
          <Row>
            <Col lg={6} className='mb-4 mb-lg-0'>
              <Card className='h-100'>
                <Card.Header>
                  <h4 className='card-heading'>{'Account Owner'}</h4>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='text-uppercase flex-shrink-0 me-1 mb-4'>
                        <div className='fw-bold'>
                          {user && user.cl_salutation} {user && user.cl_firstname} {user && user.cl_lastname}
                        </div>
                      </div>
                      <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                        <div className='fw-bold'>Address</div>
                        <div>
                          <small className='text-gray-500'>{user && user.cl_street}</small>
                        </div>
                        <div>
                          <small className='text-gray-500'>
                            {user && user.cl_plc} {user && user.cl_city}
                          </small>
                        </div>
                        <div>
                          <small className='text-gray-500'>{user && user.cl_countryofresidence}</small>
                        </div>
                        <div className='mt-1'>
                          <Button variant='light' size='sm' className='text-gray-500' onClick={openChangeAddressDialog}>
                            Change...
                          </Button>{' '}
                        </div>
                      </div>{' '}
                      <div className='flex-shrink-0 me-1 mb-4'>
                        <div className='text-uppercase fw-bold'>E-Mail</div>
                        <div>
                          <small className='text-gray-500'>{user && user.cl_email}</small>
                        </div>
                        <div className='mt-1'>
                          <Button variant='light' size='sm' className='text-gray-500' onClick={openChangeEMailDialog}>
                            Change...
                          </Button>{' '}
                        </div>
                      </div>{' '}
                      <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                        <div className='fw-bold'>Password </div>
                        <div>
                          <small className='text-gray-500'>{'******** '}</small>
                        </div>
                        <div className='mt-1'>
                          <Button
                            variant='light'
                            size='sm'
                            className='text-gray-500'
                            onClick={openChangePasswordDialog}
                          >
                            Change...
                          </Button>{' '}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} className='mb-4 mb-lg-0'>
              <Card className='h-100'>
                <Card.Header>
                  <h4 className='card-heading'>Account Services</h4>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                        <div className='fw-bold'>Main Product</div>
                        {clientAccountServices &&
                          clientAccountServices.map((item, rowindex) => (
                            <div key={rowindex}>
                              <small className='text-gray-500'>{item.srvceassets}</small>
                            </div>
                          ))}

                        <div className='mt-1'>
                          <Button variant='light' size='sm' className='text-gray-500' onClick={openMainProductFees}>
                            Details...
                          </Button>{' '}
                        </div>
                      </div>{' '}
                      <div className='text-uppercase flex-shrink-0 me-1 mb-4'>
                        <div className='fw-bold'>Additional Services</div>
                        {clientAccountServicesAdditional &&
                          (clientAccountServicesAdditional.length > 0 ? (
                            clientAccountServicesAdditional.map((item, rowindex) => (
                              <div key={rowindex}>
                                <small className='text-gray-500'>{item.srvceassets}</small>
                              </div>
                            ))
                          ) : (
                            <small className='text-gray-500'>None</small>
                          ))}
                        <div className='mt-1'>
                          <Button variant='light' size='sm' className='text-gray-500' onClick={openAdditionalServices}>
                            Details...
                          </Button>{' '}
                          {/* 
                        <Button
                          variant="light"
                          size="sm"
                          className="text-gray-500"
                          onClick={changeSingleServiceProducts}
                        >
                          Change...
                        </Button>{" "}
                        */}
                        </div>
                      </div>{' '}
                      <div className='text-uppercase flex-shrink-0 me-1 mb-4'>
                        <div className='fw-bold'>Terms Of Use</div>
                        {termsOfUse &&
                          (termsOfUse.length > 0 ? (
                            termsOfUse.map((item, rowindex) => (
                              <div className='mb-1' key={rowindex}>
                                <Button
                                  variant='light'
                                  size='sm'
                                  className='text-gray-500'
                                  onClick={() => setModal(item.doc_title, item.doc_content)}
                                >
                                  {item && item.doc_title}
                                </Button>{' '}
                              </div>
                            ))
                          ) : (
                            <small className='text-gray-500'>None</small>
                          ))}
                      </div>{' '}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
        <section className='mb-4 mb-lg-5'>
          <Row>
            {user.client_account_user_security && (
              <Col lg={6} className='mb-4 mb-lg-0'>
                <Card className='h-100'>
                  <Card.Header>
                    <h4 className='card-heading'>Account Security</h4>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                          <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                            <div className='fw-bold'>Registered Locations</div>
                            {geolocationData &&
                              (geolocationData.length > 0 ? (
                                geolocationData.map((item, rowindex) => (
                                  <div key={rowindex}>
                                    <small className='text-gray-500'>
                                      {rowindex + 1}. {item.ip_address} - {item.country} - {item.city} - Whitelist:{' '}
                                      <input
                                        type='checkbox'
                                        checked={item.ip_whitelisted}
                                        onChange={() => handleLocationWhitelist(item.ip_whitelisted, item.ip_address)}
                                      />
                                    </small>
                                  </div>
                                ))
                              ) : (
                                <small className='text-gray-500'>None</small>
                              ))}
                          </div>
                        </div>{' '}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )}

            {user.client_multi_account_users && (
              <Col lg={6} className='mb-4 mb-lg-0'>
                <Card className='h-100'>
                  <Card.Header>
                    <h4 className='card-heading'>Account Users</h4>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                          <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                            <div className='fw-bold'>Additional Users</div>
                            {clientUsers &&
                              (clientUsers.length > 0 ? (
                                clientUsers.map((item, rowindex) => (
                                  <div key={rowindex}>
                                    <small className='text-gray-500'>
                                      {item.cl_lastname + ' ' + item.cl_firstname}
                                    </small>
                                  </div>
                                ))
                              ) : (
                                <small className='text-gray-500'>None</small>
                              ))}
                          </div>
                          <div className='text-uppercase flex-shrink-0 me-1 mb-3'>
                            <div className='fw-bold'>Invited Users</div>
                            {invitedUsers &&
                              (invitedUsers.length > 0 ? (
                                invitedUsers.map((item, rowindex) => (
                                  <div key={rowindex}>
                                    <small className='text-gray-500'>{item.u_cl_email}</small>
                                  </div>
                                ))
                              ) : (
                                <small className='text-gray-500'>None</small>
                              ))}
                          </div>
                          <div className='mt-1'>
                            <Button variant='light' size='sm' className='text-gray-500' onClick={openAccountUsers}>
                              Details...
                            </Button>{' '}
                          </div>
                        </div>{' '}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </section>

        <Row className='mb-5'>
          <Col>
            <div className='float-end'>
              <Button variant='outline-secondary' onClick={closeAccount}>
                Close
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
