import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HTTP from '../../api/HTTP';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IMaskInput } from 'react-imask';

import { Button, Card, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';

async function validateEmail(email) {
  try {
    const response = await HTTP.post('/signup/validate-email', JSON.stringify(email));
    return response.data.isUsed;
  } catch (error) {
    console.log('validateEmail error', error);
  }
}

async function signupClient(clData) {
  try {
    const response = await HTTP.post('/signup/new-client', JSON.stringify(clData));
    if (response.data.signedUp === true) return true;
  } catch (error) {
    console.log('SignupClientData error', error);
  }
}

const SignupStart = () => {
  const location = useLocation();
  const selPartnerid = location.state.partnerid;
  const navigate = useNavigate();

  const [showEmailUsed, setShowEmailUsed] = useState(false);

  const cancelExecution = () => {
    // navigate.("login")
  };

  const formik = useFormik({
    initialValues: {
      lastName: '',
      firstName: '',
      salutation: '',
      email: '',
      password: '',
      mobilePhone: '',
      passwordTwo: '',
    },
    validationSchema: Yup.object().shape({
      lastName: Yup.string().required('Required'),
      firstName: Yup.string().required('Required'),
      email: Yup.string().email().required('Required'),
      password: Yup.string()
        .required('No password provided.')
        .min(4, 'Password is too short - should be 12 chars minimum.')
        .matches(/(?=.*[0-9])/, 'Password must contain a number.'),
      mobilePhone: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      console.log('Signing up', values);

      const emailUsed = await validateEmail({
        email: values.email,
      });

      if (!emailUsed) {
        const clientData = {
          operation: 1,
          partnerid: selPartnerid,
          clientid: 1,
          firstname: values.firstName,
          lastname: values.lastName,
          salutation: values.salutation,
          email: values.email,
          mobilephone: values.mobilePhone,
          login_pw: values.password,
        };

        const signedUp = await signupClient(clientData);

        if (signedUp) {
          navigate('/login', { replace: true });
        }
      } else {
        setShowEmailUsed(true);
      }
    },
  });

  return (
    <Container>
      <Row className='align-items-center'>
        <Card>
          <Card.Header className='px-lg-5'>
            <div className='card-heading text-primary'>Sign Up</div>
          </Card.Header>
          <Card.Body className='p-lg-5'>
            <ToastContainer className='p-3' position={'bottom-end'}>
              <Toast show={showEmailUsed} bg='warning'>
                <Toast.Body>Email is used</Toast.Body>
              </Toast>
            </ToastContainer>
            <Row className='g-2 mt-0 mb-3'>
              <Col md>
                {/* <h3 className="mb-4">Login</h3> */}
                <p className='text-muted'>
                  <span className='fw-bold'>Starting the Sign up Process</span> with FinBuilder requires that you
                  provide the details below, verify your E-Mail and Mobile Phone and that you accept the terms of use.{' '}
                  <br /> This will be done during the next steps
                </p>
              </Col>
            </Row>
            <Form id='loginForm' onSubmit={formik.handleSubmit}>
              <Row className='g-2'>
                <Col md>
                  <div className='form-floating mb-3'>
                    <Form.Control
                      id='lastName'
                      name='lastName'
                      type='lastName'
                      placeholder='Last Name'
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.errors.lastName && formik.touched.lastName && 'error'}
                    />
                    <Form.Label htmlFor='lastName'>Last Name</Form.Label>
                    {formik.errors.lastName && formik.touched.lastName && (
                      <div className='input-feedback'>{formik.errors.lastName}</div>
                    )}
                  </div>
                </Col>
                <Col md>
                  <div className='form-floating mb-3'>
                    <Form.Control
                      id='firstName'
                      name='firstName'
                      type='firstName'
                      placeholder='First Name'
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.errors.firstName && formik.touched.firstName && 'error'}
                    />
                    <Form.Label htmlFor='firstName'>First Name</Form.Label>
                    {formik.errors.firstName && formik.touched.firstName && (
                      <div className='input-feedback'>{formik.errors.firstName}</div>
                    )}
                  </div>
                </Col>

                <Col md>
                  <div className='form-floating mb-3'>
                    <Form.Select
                      id='salutation'
                      name='salutation'
                      type='salutation'
                      // placeholder="Salutation"
                      value={formik.values.salutation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.errors.salutation && formik.touched.salutation && 'error'}
                    >
                      <option></option>
                      <option value='Mr.'>Mr.</option>
                      <option value='Mrs.'>Mrs.</option>
                    </Form.Select>
                    <Form.Label htmlFor='salutation'>Salutation</Form.Label>
                    {formik.errors.salutation && formik.touched.salutation && (
                      <div className='input-feedback'>{formik.errors.salutation}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='g-2'>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <Form.Control
                      id='email'
                      name='email'
                      type='email'
                      placeholder='name@example.com'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.errors.email && formik.touched.email && 'error'}
                    />
                    <Form.Label htmlFor='email'>Email address</Form.Label>
                    {formik.errors.email && formik.touched.email && (
                      <div className='input-feedback'>{formik.errors.email}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='g-2'>
                <Col md>
                  <div className='form-floating mb-3'>
                    <Form.Control
                      id='password'
                      name='password'
                      type='password'
                      placeholder='Password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.errors.password && formik.touched.password && 'error'}
                    />
                    <Form.Label htmlFor='password'>Password</Form.Label>
                    {formik.errors.password && formik.touched.password && (
                      <div className='input-feedback'>{formik.errors.password}</div>
                    )}
                  </div>
                </Col>
                <Col md>
                  <div className='form-floating mb-3'>
                    <Form.Control
                      id='passwordTwo'
                      name='passwordTwo'
                      type='password'
                      placeholder='Please repeat Password'
                      value={formik.values.passwordTwo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={formik.errors.passwordTwo && formik.touched.passwordTwo && 'error'}
                    />
                    <Form.Label htmlFor='password'>Password</Form.Label>
                    {formik.errors.password && formik.touched.password && (
                      <div className='input-feedback'>{formik.errors.password}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='g-2'>
                <Col md={6}>
                  <div className='form-floating mb-3'>
                    <IMaskInput
                      mask={'+00-000-0000-0000'}
                      id='mobilePhone'
                      name='mobilePhone'
                      type='mobilePhone'
                      placeholder='Mobile Phone'
                      value={formik.values.mobilePhone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${formik.errors.mobilePhone && formik.touched.mobilePhone && 'error'}`}
                    />
                    <Form.Label htmlFor='mobilePhone'>Mobile Phone</Form.Label>
                    {formik.errors.mobilePhone && formik.touched.mobilePhone && (
                      <div className='input-feedback'>{formik.errors.mobilePhone}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-3 mt-3'>
                <Col md={2}>
                  <p className='fw-bold'>What next:</p>
                </Col>
                <Col md={7}>
                  <p className='text-muted'>
                    Following completion of the fields above and proceeding you will be requested to{' '}
                    <span className='fw-bold'> log in</span> as next step using the{' '}
                    <span className='fw-bold'> E-Mail </span> and the <span className='fw-bold'> Password </span>{' '}
                    defined above
                  </p>
                </Col>
                <Col>
                  <div className='float-end'>
                    <Button variant='primary' size='lg' type='submit' disabled={formik.isSubmitting}>
                      Continue
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
          <Card.Footer className='px-lg-5 py-lg-4'>
            <div className='text-sm text-muted'>
              Already have an account?{' '}
              <Button variant='secondary' size='sm' onClick={cancelExecution}>
                Login
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Row>
    </Container>
  );
};

export default SignupStart;
