// import defaultUser from '../utils/default-user';
import authService from '../services/AuthService';
import tokenService from '../services/TokenService';

export async function signIn(email, password) {
  try {
    // Send request
    // console.log('-0-', email, password);

    const tokenResponse = await authService.login(email, password);
    console.log('-1-', tokenResponse);

    if (tokenResponse.data.accessToken && tokenResponse.data.refreshToken) {
      tokenService.setAccessAndRefreshToken(tokenResponse.data.accessToken, tokenResponse.data.refreshToken);

      const currentUser = await authService.getAuthUser();

      console.log('-2-', currentUser);

      const tokenExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      currentUser.expiration = tokenExpirationDate.toISOString();

      return {
        isOk: true,
        user: currentUser,
      };
    }

    if (tokenResponse.data.hash) {
      return {
        isOk: true,
        auth2: tokenResponse.data,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export function logOut() {
  tokenService.removeAll();
}

export async function getUser() {
  // const noClientId = new UUID(0, 0)
  try {
    const currentUser = await authService.getAuthUser();

    console.log(566, currentUser);
    // console.log(56677, ' = true');
    return {
      isOk: true,
      data: currentUser,
      user: currentUser,
    };
  } catch {
    //  console.log(56688, 'OK = false');
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to reset password',
    };
  }
}
