import { Container } from "react-bootstrap";
import CustomButton from "../../components/ui/CustomButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const CookiesPopup = () => {
  const navigate = useNavigate();

  const [capitavioCookiesBannerState, setCapitavioCookiesBannerState] = useState(false);

  useEffect(() => {
    const capitavioCookiesBannerShow = localStorage.getItem('capitavioCookiesBannerState');

    setCapitavioCookiesBannerState(!capitavioCookiesBannerShow);
  }, []);

  const handleCloseCookiesBanner = () => {
    localStorage.setItem('capitavioCookiesBannerState', true);
    setCapitavioCookiesBannerState(false);
  }

  const redirectToPolicy = () => {
    navigate('/policy');
  }

  return capitavioCookiesBannerState && (
    <div
      className="cookies-popup-wrapper position-fixed bottom-0 zindex-fixed"
    >
      <Container
        className="cookies-popup d-flex align-items-lg-center align-items-md-start align-items-center flex-column flex-md-row"
      >
        <img src="/img/cookies.svg" className="my-md-0 my-2" />

        <div
          className="d-flex flex-wrap text-md-start text-center justify-content-md-start justify-content-center mb-md-0 mb-4"
          style={{
            marginLeft: '17px',
            marginRight: '17px',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              lineHeight: '29px',
              color: '#171616',
            }}
            className="mt-2 mb-3 mt-lg-0 mb-lg-0"
          >
            We use cookies
          </div>
          <div
            className="d-flex align-items-center ms-0 ms-xl-3"
            style={{
              fontSize: '18px',
              lineHeight: '111.2%',
              color: '#98A1BE'
            }}
          >
            This website uses cookies to enhance your browsing experience.
          </div>
          <a
            className="d-none d-md-block d-lg-none me-md-0 mt-md-3 me-3 mt-0 cursor-pointer"
            style={{
              cursor: 'pointer',
              color: '#171616',
              textDecoration: 'underline'
            }}
            onClick={redirectToPolicy}
          >
            Read Policy
          </a>
        </div>

        <div
          className="d-flex align-items-center mt-lg-0 mt-md-2 mt-0 ms-md-auto"
        >
          <a
            className="d-block d-md-none d-lg-block me-3 cursor-pointer text-nowrap"
            style={{
              cursor: 'pointer',
              color: '#171616',
              textDecoration: 'underline'
            }}
            onClick={redirectToPolicy}
          >
            Read Policy
          </a>

          <CustomButton
            variant="main-light"
            size="normal"
            onClick={handleCloseCookiesBanner}
          >
            Accept
          </CustomButton>
        </div>
      </Container>
    </div>
  );
}

export default CookiesPopup;