import React from "react"
import { Container } from "react-bootstrap"

export default function Footer() {
  return (
    <footer className="footer shadow align-self-end py-3 px-xl-5 w-100">
      <Container fluid>
        <p className="mb-2 mb-md-0 text-md-center">COPYRIGHT &copy; {new Date().getFullYear()} FinBuilder – ALL RIGHTS RESERVED</p>
      </Container>
    </footer>
  )
}
